import { GatsbyImage as Image } from 'gatsby-plugin-image';
import React from 'react';
import PropTypes from '../util/PropTypes';
import BasicSlider from './BasicSlider.tsx';
import DatoImage from './DatoImage';
import Panel from './Panel';
import Typography from './Typography';

const propTypes = {
    children: PropTypes.node,
    image: PropTypes.gatsbyImageObject,
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string,
    }),
};

const defaultProps = {
    children: null,
    image: null,
    backgroundColor: null,
};

const StorySlider = ({ children, image, backgroundColor }) => {
    const slides = React.Children.toArray(children);

    return (
        <BasicSlider>
            <div className="flex items-center justify-center text-center">
                <BasicSlider.SliderLeftButton />
                <Typography variant="heading5" as="div" className="w-[300px] relative">
                    <BasicSlider.Slides transition="0.5s" slidesPerView={1} className="flex items-center justify-center min-h-[48px] text-center">
                        {slides.map(slide => (
                            <div key={slide.props.title} className="whitespace-nowrap w-[fit-content] flex items-center justify-center px-4 mx-auto">
                                <div>
                                    {slide.props.title}
                                </div>
                            </div>
                        ))}
                    </BasicSlider.Slides>
                </Typography>
                <BasicSlider.SliderRightButton />
            </div>
            {image && (
                <Panel
                    className="relative mt-2 h-full"
                    variant={backgroundColor?.hex ? 'custom' : 'light'}
                    customColor={backgroundColor?.hex}
                >
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            {image.gatsbyImageData && (
                                <Image
                                    image={image.gatsbyImageData}
                                    className="h-full"
                                />
                            )}
                            {!image.gatsbyImageData && (
                                <DatoImage
                                    image={image}
                                    className="h-full"
                                />
                            )}
                        </div>
                        <div className="py-10 px-8 w-full lg:px-20 lg:w-1/2">
                            <div className="relative">
                                <BasicSlider.Slides transition="0.7s" animation="fade">
                                    {slides.map(slide => (
                                        <div key={slide.props.title}>
                                            {slide}
                                        </div>
                                    ))}
                                </BasicSlider.Slides>
                            </div>
                        </div>
                    </div>
                </Panel>
            )}
            {!image && (
                <div className="relative mt-2">
                    <div className="flex flex-col lg:flex-row">
                        <BasicSlider.Slides transition="0.7s" animation="fade">
                            {slides.map(slide => (
                                <Panel
                                    className="h-full"
                                    variant={backgroundColor?.hex ? 'custom' : 'light'}
                                    customColor={backgroundColor?.hex}
                                >
                                    <div className="flex flex-col lg:flex-row">
                                        <div className="w-full lg:w-1/2">
                                            {slide.props.image.gatsbyImageData && (
                                                <Image
                                                    image={slide.props.image.gatsbyImageData}
                                                    className="h-full"
                                                />
                                            )}
                                            {!slide.props.image.gatsbyImageData && (
                                                <DatoImage
                                                    image={slide.props.image}
                                                    className="h-full"
                                                />
                                            )}
                                        </div>
                                        <div className="py-10 px-8 w-full lg:px-20 lg:w-1/2">
                                            {slide}
                                        </div>
                                    </div>
                                </Panel>
                            ))}
                        </BasicSlider.Slides>
                    </div>
                </div>
            )}
            <div className="mt-6 mx-auto w-[260px]">
                <BasicSlider.Progress
                    count={slides.length}
                />
            </div>
        </BasicSlider>
    );
};

StorySlider.propTypes = propTypes;
StorySlider.defaultProps = defaultProps;

const StorySlide = ({ children }) => children;

StorySlide.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    image: PropTypes.oneOf([PropTypes.gatsbyImageObject, PropTypes.datoImage]),
};

StorySlide.defaultProps = {
    children: null,
    title: null,
    image: null,
};

StorySlider.Slide = StorySlide;

export default StorySlider;
