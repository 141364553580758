import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    shouldChange: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: '',
};

const contentInnerVariants = {
    enter: () => ({
        opacity: 0,
    }),
    idle: () => ({
        opacity: 1,
        transition: { duration: 1 },
    }),
    exit: () => ({
        opacity: 0,
        transition: { duration: 0 },
    }),
};

const FadeTransition = ({ children, shouldChange, className }) => (
    <AnimatePresence mode="wait" initial={false}>
        <motion.div
            key={shouldChange}
            variants={contentInnerVariants}
            exit="exit"
            animate="idle"
            initial="enter"
            className={className}
        >
            {children}
        </motion.div>
    </AnimatePresence>
);

FadeTransition.propTypes = propTypes;
FadeTransition.defaultProps = defaultProps;

export default FadeTransition;
