import classnames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import List from '../List';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
const CustomList = ({ className, blockParent, isPage, children, ...props }) => {
    const renderDepth = React.useContext(BlockRendererContext);
    if (renderDepth <= 0 && isPage && !blockParent) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <List
                            type={classnames({
                                check: blockParent === 'TickBulletPoint',
                            })}
                            className={classnames('my-8 first:mt-0', className)}
                            {...props}
                        >
                            {children}
                        </List>
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <List
            type={classnames({
                check: blockParent === 'TickBulletPoint',
            })}
            className={classnames('my-8 first:mt-0', className)}
            {...props}
        >
            {children}
        </List>
    );
};
CustomList.propTypes = {
    className: PropTypes.string,
};

CustomList.defaultProps = {
    className: '',
};

CustomList.Item = ({ children, ...props }) => (
    <List.Item {...props}>
        <Typography>
            {children}
        </Typography>
    </List.Item>
);

CustomList.Item.propTypes = {
    children: PropTypes.node,
};

CustomList.Item.defaultProps = {
    children: null,
};

export const CustomListBlock = ({ blockParent, bulletPoints, isPage }) => (
    <CustomStructuredTextRenderer blockParent={blockParent} data={bulletPoints} isPage={isPage} />
);

CustomListBlock.shouldRenderHalfMargin = () => true;

CustomListBlock.propTypes = {
    bulletPoints: PropTypes.shape({}),
    blockParent: PropTypes.string,
};

CustomListBlock.defaultProps = {
    bulletPoints: null,
    blockParent: '',
};

export const query = graphql`
    fragment TickBulletPointProps on DatoCmsTickBulletPoint {
        originalId
        bulletPoints {
            blocks {
                __typename
                ...ButtonProps
            }
            links
            value
        }
    }
`;

export default CustomList;
