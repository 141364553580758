import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import { useLanguage } from '../language/LanguageProvider.tsx';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

const SingleCaseStudy = ({ title, richText, caseStudy }) => {
    const { language } = useLanguage();

    const buttonText = {
        en: 'View the Case Study',
        de: 'Zur Case Study',
    }[language];

    return (
        <div>
            <Container className="py-12 lg:pt-0 lg:pb-16">
                <Row xs={12} cols={12}>
                    <Col cols={12} xs={12} lg={{ start: 3, span: 8 }} xl={{ start: 4, span: 6 }} className="text-center">
                        <Typography variant="heading2" className="mb-9">
                            {title}
                        </Typography>
                        <Typography variant="intro" className="mt-4">
                            <CustomStructuredTextRenderer data={richText} />
                        </Typography>
                    </Col>
                </Row>
            </Container>
            <div className="bg-gray-50 py-12 lg:py-32 lg:mt-28">
                <Container>
                    <Row cols={{ sm: 1, md: 2, lg: 2 }} className="gap-y-12">
                        <Col
                            className="flex flex-col justify-between lg:-mt-60 lg:h-[calc(100%_+_18rem)] md:relative"
                        >
                            <div className="w-full md:absolute h-full">
                                <DatoImage
                                    image={caseStudy.bigThumbnail}
                                    objectFit="contain"
                                    className="w-full h-full max-w-md md:max-w-lg mx-auto"
                                    objectPosition="center center"
                                />
                            </div>
                        </Col>
                        <Col>
                            <Typography variant="heading5" as="span">
                                {caseStudy.client}
                            </Typography>
                            <Typography variant="heading3" paragraph>
                                {caseStudy.teaserTitle}
                            </Typography>
                            {caseStudy.teaserText && (
                                <Typography>
                                    {caseStudy.teaserText}
                                </Typography>
                            )}
                            <Button to={caseStudy.permalink} variant="secondary" className="mt-8">
                                {buttonText}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

SingleCaseStudy.getBlockClassnames = () => 'remove-bottom';

SingleCaseStudy.propTypes = {
    title: PropTypes.string.isRequired,
    richText: PropTypes.shape({}).isRequired,
    caseStudy: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.string,
        teaserTitle: PropTypes.string,
        teaserText: PropTypes.string,
        bigThumbnail: PropTypes.datoImage,
        permalink: PropTypes.string,
    })),
};

SingleCaseStudy.defaultProps = {
    caseStudy: [],
};

export const query = graphql`
    fragment SingleCaseStudyProps on DatoCmsSingleCaseStudy {
        originalId
        title
        richText: text {
            blocks
            links
            value
        }
        caseStudy: items {
            client
            teaserTitle
            bigThumbnail {
                gatsbyImageData
                url
                alt
                smartTags
            }
            teaserText
            permalink
        }
    }
`;

export default SingleCaseStudy;
