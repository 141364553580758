/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import {
    isParagraph, isHeading, isList, isListItem, isLink, isBlockquote,
} from 'datocms-structured-text-utils';
import React from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';
import PropTypes from '../../util/PropTypes';
import BlockRenderer from './BlockRenderer';
import CustomList from './CustomList';
import {
    Heading1, Heading2, Heading3, Heading4, Heading5, Heading6,
} from './Headings';
import InlineBlockquote from './InlineBlockquote';
import InlineLink from './InlineLink';
import Paragraph from './Paragraph';

const propTypes = {
    data: PropTypes.shape({}),
    className: PropTypes.string,
    blockParent: PropTypes.string,
    isPage: PropTypes.bool,
};

const defaultProps = {
    data: null,
    className: '',
    blockParent: '',
    isPage: false,
};

const createParagraphRenderer = (className, blockParent) => ({ children, key, ancestors }) => {
    // If the paragraph is inside a list item, simply output the text
    if ((ancestors[0] && isListItem(ancestors[0])) || (ancestors[0] && isBlockquote(ancestors[0])) || blockParent === 'IntroText') {
        return children;
    }
    return (
        <Paragraph className={className} key={key}>{children}</Paragraph>
    );
};

const createLinkRenderer = className => ({ node, children, key }) => (
    <InlineLink className={className} href={node.url} key={key}>{children}</InlineLink>
);

const createListRenderer = (className, blockParent, isPage) => ({ children, key }) => (
    <CustomList className={className} blockParent={blockParent} isPage={isPage} key={key}>{children}</CustomList>
);

const createListItemRenderer = (className, blockParent) => ({ children, key }) => (
    <CustomList.Item className={className} blockParent={blockParent} key={key}>{children}</CustomList.Item>
);

const createBlockquoteRenderer = className => ({ node, children, key }) => (
    <InlineBlockquote
        className={className}
        attribution={node.attribution}
        key={key}
    >
        {children}
    </InlineBlockquote>
);

const createHeadingRenderer = (className, isPage) => ({ node, children, key }) => {
    if (node.level === 1) {
        return (
            <Heading1 isPage={isPage} className={className} key={key}>{children}</Heading1>
        );
    }
    if (node.level === 2) {
        return (
            <Heading2 isPage={isPage} className={className} key={key}>{children}</Heading2>
        );
    }
    if (node.level === 3) {
        return (
            <Heading3 isPage={isPage} className={className} key={key}>{children}</Heading3>
        );
    }
    if (node.level === 4) {
        return (
            <Heading4 isPage={isPage} className={className} key={key}>{children}</Heading4>
        );
    }
    if (node.level === 5) {
        return (
            <Heading5 isPage={isPage} className={className} key={key}>{children}</Heading5>
        );
    }
    return (
        <Heading6 isPage={isPage} className={className} key={key}>{children}</Heading6>
    );
};

const CustomStructuredTextRenderer = ({ data, className, blockParent, isPage }) => {
    if (!data) return null;
    if (!data?.value) return null;
    const updatedData = {
        ...data,
        blocks: (data.blocks || []).map(block => ({
            ...block,
            id: block.originalId,
        })),
    };
    return (
        <StructuredText
            data={updatedData}
            renderBlock={({ record }) => (
                <BlockRenderer data={[record]} isPage={isPage} blockParent={blockParent} />
            )}
            customNodeRules={[
                renderNodeRule(
                    isParagraph,
                    createParagraphRenderer(className, blockParent),
                ),
                renderNodeRule(
                    isLink,
                    createLinkRenderer(className),
                ),
                renderNodeRule(
                    isList,
                    createListRenderer(className, blockParent, isPage),
                ),
                renderNodeRule(
                    isListItem,
                    createListItemRenderer(className),
                ),
                renderNodeRule(
                    isBlockquote,
                    createBlockquoteRenderer(className),
                ),
                renderNodeRule(
                    isHeading,
                    createHeadingRenderer(className, isPage),
                ),
            ]}
        />
    );
};

CustomStructuredTextRenderer.propTypes = propTypes;
CustomStructuredTextRenderer.defaultProps = defaultProps;

export default CustomStructuredTextRenderer;
