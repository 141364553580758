import * as classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import useCalendlyModal from '../../hooks/useCalendlyModal';
import hasNoBackgroundColor from '../../util/hasNoBackgroundColor';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import CompanyContactWrapper from '../CompanyContactWrapper';
import Container from '../Container';
import DatoImage from '../DatoImage';
import EmailLink from '../EmailLink';
import Highlight from '../Highlight';
import Panel from '../Panel';
import Row from '../Row';
import Typography from '../Typography';

const CtaWithMultipleImages = ({ title, text, items, backgroundColor, showCta }) => {
    const openCallbackModal = useCalendlyModal();

    const colsAmtLg = (() => {
        // 3 columns if multiple of 3 or 5
        if (items.length % 5 === 0 || items.length % 3 === 0) return 4;
        // Divide the items up to max of 4 items per row otherwise
        return Math.max(3, Math.round(12 / (items.length)));
    })();

    const colsAmtMd = (() => {
        // 2 columns if multiple of 2
        if (items.length % 2 === 0) return 6;
        // Divide the items up to max of 3 items per row
        return Math.max(4, Math.round(12 / (items.length)));
    })();

    const noBgColor = hasNoBackgroundColor(backgroundColor);

    return (
        <>
            <Panel
                className={classNames(
                    {
                        'my-20': noBgColor,
                        'py-10 sm:py-24': !noBgColor,
                    },
                )}
                variant={backgroundColor?.hex ? 'custom' : 'light'}
                customColor={backgroundColor?.hex}
            >
                <Container>
                    <Row cols={{ sm: 1, md: 1, lg: 12 }} className="text-center">
                        <Col lg={{ span: 8, start: 3 }}>
                            <Typography variant="heading2" paragraph>
                                {title}
                            </Typography>
                            <Typography variant="blockquote">
                                {text}
                            </Typography>
                        </Col>
                    </Row>
                    <Row cols={{ sm: 1, md: 12, lg: 12 }}>
                        {items.map(item => (
                            <Col md={{ span: colsAmtMd }} lg={{ span: colsAmtLg }} className="mt-16">
                                <DatoImage
                                    image={item.image}
                                    objectFit="contain"
                                    className="h-36 w-36"
                                />
                                <Typography variant="heading5" as="h3" className="mt-4" paragraph>
                                    <Highlight color="lightBlue">
                                        {item.title}
                                    </Highlight>
                                </Typography>
                                <Typography>
                                    {item.text}
                                </Typography>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Panel>
            {showCta && (
                <Panel
                    className={classNames(
                        {
                            'mb-20': noBgColor,
                            'py-10 sm:py-24 mt-0.5': !noBgColor,
                        },
                    )}
                    variant={backgroundColor?.hex ? 'custom' : 'light'}
                    customColor={backgroundColor?.hex}
                >
                    <CompanyContactWrapper>
                        {({
                            salesPerson,
                            serviceContactTitle,
                            serviceContactText,
                            salesMail,
                            serviceContactButton,
                        }) => (
                            <Container>
                                <Row cols={{ sm: 1 }} className="items-center">
                                    <Col md={{ span: 4, order: 2 }} lg={{ span: 7, order: 2 }}>
                                        <Typography variant="heading4" as="h2" paragraph>
                                            {serviceContactTitle}
                                        </Typography>
                                        <Typography>
                                            {serviceContactText}
                                        </Typography>
                                        <div className="mt-8 hidden lg:block">
                                            <Button variant="pill-dark" onClick={openCallbackModal}>
                                                {serviceContactButton}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md={{ span: 2, order: 1 }} lg={{ start: 2, span: 3 }} className="text-center mt-4 md:mt-0">
                                        <div className="w-40 mx-auto">
                                            <DatoImage
                                                className="rounded-full"
                                                image={salesPerson?.personCardPhoto}
                                            />
                                        </div>
                                        <Typography variant="small" className="font-bold mt-1" as="p">
                                            <span className="font-bold">
                                                {salesPerson.name}
                                            </span>
                                        </Typography>
                                        <Typography variant="small" as="p" className="mt-1">
                                            {salesPerson.jobPosition}
                                        </Typography>
                                        <Typography variant="small" as="div" className="mt-1">
                                            <EmailLink hideIcon className="font-bold">
                                                {salesMail}
                                            </EmailLink>
                                        </Typography>
                                        <div className="mt-8 lg:hidden">
                                            <Button variant="pill-dark" onClick={openCallbackModal}>
                                                {serviceContactButton}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </CompanyContactWrapper>
                </Panel>
            )}
        </>
    );
};

CtaWithMultipleImages.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    showCta: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.datoImage,
    })),
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string,
    }),
};

CtaWithMultipleImages.defaultProps = {
    title: '',
    text: '',
    showCta: false,
    items: [],
    backgroundColor: null,
};

export const query = graphql`
    fragment MultipleImageCtaProps on DatoCmsMultipleImageCta {
        originalId
        backgroundColor {
            hex
        }
        title
        text
        showCta
        items {
            title
            text
            image {
                gatsbyImageData(imgixParams: { auto: "format" })
                alt
                url
                smartTags
            }
        }
    }
`;

export default CtaWithMultipleImages;
