import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Col from '../components/Col';
import Container from '../components/Container';
import DatoImage from '../components/DatoImage';
import FadeTransition from '../components/FadeTransition';
import AngleDownIcon from '../components/icons/AngleDownIcon';
import { useLanguage } from '../components/language/LanguageProvider.tsx';
import Link, { getLink } from '../components/Link';
import NavItem from '../components/NavigationItem';
import NavItemText from '../components/NavigationItemText';
import Row from '../components/Row';
import Typography from '../components/Typography';
import BrainbitsLogo from '../components/visuals/BrainbitsLogo';
import hierarchicalMenu from '../util/hierarchical-menu.ts';
import PropTypes from '../util/PropTypes';

const HeaderNavigationContainer = () => {
    const data = useStaticQuery(graphql`
        query {
            de: allDatoCmsNavigation(locale: "de") {
                nodes {
                    id
                    position
                    treeParent {
                        id
                    }
                    title
                    text
                    icon {
                        url
                    }
                    iconHover {
                        url
                    }
                    hideForLocale
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            en: allDatoCmsNavigation(locale: "en") {
                nodes {
                    id
                    position
                    treeParent {
                        id
                    }
                    title
                    text
                    icon {
                        url
                    }
                    iconHover {
                        url
                    }
                    hideForLocale
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
        }
    `);

    const { language } = useLanguage();

    const menuItems = hierarchicalMenu(data[language].nodes);

    return (
        <div className="h-20 flex items-center justify-between z-10 bg-white font-display">
            <FadeTransition shouldChange={language} className="w-full">
                <NavigationMenu.Root className="relative z-[1] w-full" delayDuration={100}>
                    <Container outerClassName="bg-white">
                        <NavigationMenu.List className="center flex list-none justify-between items-stretch lg:gap-6 xl:gap-12">
                            <NavigationMenu.Item className="w-full max-w-[33.3333%] relative z-10 mr-5 xl:mr-12">
                                <div className="h-20 flex">
                                    <Link
                                        to="/"
                                        className="self-center no-underline"
                                        aria-label="brainbits"
                                    >
                                        <BrainbitsLogo className="h-6" />
                                    </Link>
                                </div>
                            </NavigationMenu.Item>
                            {menuItems.childMenu.map((item, idx) => {
                                if (idx === menuItems.childMenu.length - 1) {
                                    return (
                                        <NavigationMenu.Item key={item.id} className="flex items-center">
                                            <Button to={getLink(item.link).link} variant="pill-dark">
                                                {item.title}
                                            </Button>
                                        </NavigationMenu.Item>
                                    );
                                }
                                return (
                                    <NavigationMenu.Item key={item.id}>
                                        <MenuItem item={item} />
                                    </NavigationMenu.Item>
                                );
                            })}
                            <NavigationMenu.Indicator className="z-[-1] data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full transition-[width,transform_250ms_ease] !transform-none">
                                <div className="fixed w-screen h-screen bg-gray-900 opacity-50 top-0 left-0" />
                            </NavigationMenu.Indicator>
                        </NavigationMenu.List>
                    </Container>
                    <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
                        <NavigationMenu.Viewport className="bg-white lg:shadow-menu data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut relative h-[var(--radix-navigation-menu-viewport-height)] origin-[top_center] overflow-hidden transition-[width,_height] duration-500 w-full" />
                    </div>
                </NavigationMenu.Root>
            </FadeTransition>
        </div>
    );
};

export const MenuItem = ({ item }) => {
    const { childMenu, link, title } = item;

    if (!(childMenu.length > 0)) {
        return (
            <NavItem
                to={getLink(link).link}
                className="h-full flex items-center"
            >
                <NavItemText small bold uppercase>
                    {title}
                </NavItemText>
            </NavItem>
        );
    }

    return (
        <>
            <NavigationMenu.Trigger className="group flex select-none items-center justify-between h-full">
                <NavItem>
                    <NavItemText small bold uppercase>
                        {title}
                    </NavItemText>
                    <AngleDownIcon className="w-3 h-3 ml-2" color="currentColor" />
                </NavItem>
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full">
                <div className="m-0 grid list-none w-full">
                    <div className="bg-gray-50 mx-8 mb-8 py-12">
                        <Container canStack>
                            <Row cols={12}>
                                <Col lg={{
                                    span: (childMenu.length % 2 === 0 && childMenu.length < 6) ? 8 : 12,
                                    start: (childMenu.length % 2 === 0 && childMenu.length < 6) ? 3 : 0,
                                }}
                                >
                                    <ul className="flex flex-wrap w-full">
                                        {childMenu.map(node => (
                                            <div
                                                className={classNames({
                                                    'w-1/3': (childMenu.length % 2 !== 0 && childMenu.length > 1) || childMenu.length >= 6,
                                                    'w-1/2': (childMenu.length % 2 === 0 && childMenu.length < 6) || childMenu.length === 1,
                                                })}
                                                key={node.title}
                                            >
                                                <ChildMenuItem
                                                    item={node}
                                                />
                                            </div>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </NavigationMenu.Content>
        </>
    );
};

MenuItem.propTypes = {
    item: PropTypes.menuItem.isRequired,
};

export const ChildMenuItem = ({ item, onClick }) => {
    const { title, link, text, icon, iconHover } = item;

    return (
        <React.Fragment key={title}>
            <Link
                to={getLink(link).link}
                className="h-full block py-5 pr-4 rounded no-underline duration-200 outline-none focus:outline-none group"
                onClick={onClick}
            >
                <div className="flex gap-x-8">
                    <div className="flex-shrink-0 relative">
                        <div className="h-20 w-20">
                            <DatoImage image={icon} />
                        </div>
                        {iconHover?.url && (
                            <div className="h-20 w-20 absolute left-0 top-0 opacity-0 group-hover:opacity-100 transition duration-300">
                                <DatoImage image={iconHover} />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-sm tracking-widest font-medium uppercase break-all transition duration-200 group-hover:text-lightBlue-500 group-focus:text-lightBlue-500 group-active:text-lightBlue-500">
                            {title}
                        </p>
                        {text && (
                            <Typography variant="small" as="p" className="mt-1">
                                {text}
                            </Typography>
                        )}
                    </div>
                </div>
            </Link>
        </React.Fragment>
    );
};

ChildMenuItem.propTypes = {
    item: PropTypes.menuItem.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default HeaderNavigationContainer;
