import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const gridClasses = {
    xs: {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        5: 'grid-cols-5',
        6: 'grid-cols-6',
        12: 'grid-cols-12',
    },
    sm: {
        1: 'sm:grid-cols-1',
        2: 'sm:grid-cols-2',
        3: 'sm:grid-cols-3',
        4: 'sm:grid-cols-4',
        5: 'sm:grid-cols-5',
        6: 'sm:grid-cols-6',
        8: 'sm:grid-cols-8',
        10: 'sm:grid-cols-10',
        12: 'sm:grid-cols-12',
    },
    md: {
        1: 'md:grid-cols-1',
        2: 'md:grid-cols-2',
        3: 'md:grid-cols-3',
        4: 'md:grid-cols-4',
        5: 'md:grid-cols-5',
        6: 'md:grid-cols-6',
        8: 'md:grid-cols-8',
        10: 'md:grid-cols-10',
        12: 'md:grid-cols-12',
    },
    lg: {
        1: 'lg:grid-cols-1',
        2: 'lg:grid-cols-2',
        3: 'lg:grid-cols-3',
        4: 'lg:grid-cols-4',
        5: 'lg:grid-cols-5',
        6: 'lg:grid-cols-6',
        8: 'lg:grid-cols-8',
        10: 'lg:grid-cols-10',
        12: 'lg:grid-cols-12',
    },
    xl: {
        1: 'xl:grid-cols-1',
        2: 'xl:grid-cols-2',
        3: 'xl:grid-cols-3',
        4: 'xl:grid-cols-4',
        5: 'xl:grid-cols-5',
        6: 'xl:grid-cols-6',
        8: 'xl:grid-cols-8',
        10: 'xl:grid-cols-10',
        12: 'xl:grid-cols-12',
    },
};

const getColsClassNames = (cols) => {
    if (typeof cols === 'number') {
        return gridClasses.xs[cols];
    }

    return [
        gridClasses.xs[cols?.xs || 1],
        gridClasses.sm[cols?.sm || 2],
        gridClasses.md[cols?.md || 6],
        gridClasses.lg[cols?.lg || 12],
        gridClasses.xl[cols?.xl || cols?.lg || 12],
    ];
};

const Row = ({
    children, cols, className, style, noGap, id,
}) => (
    <div
        className={classnames(
            className,
            'grid',
            {
                'gap-x-8 lg:gap-x-12': !noGap,
            },
            getColsClassNames(cols),
        )}
        style={style}
        id={id}
    >
        {children}
    </div>
);

const breakpointShape = {
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
};

Row.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    cols: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
    style: PropTypes.shape({}),
    id: PropTypes.string,
    noGap: PropTypes.bool,
};

Row.defaultProps = {
    children: null,
    className: '',
    cols: null,
    style: null,
    id: null,
    noGap: false,
};

export default Row;
