import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Panel from '../Panel';
import Row from '../Row';
import Typography from '../Typography';

const ProductPartnerSet = ({ items }) => (
    <>
        {items.map(({ name, description, image, link, linkTitle }, idx) => {
            const isRight = idx % 2 === 1;

            return (
                <Container>
                    <Row
                        className={classNames('mt-16 md:mt-20 lg:mt-40', {
                            'mb-20 lg:mb-40 ': idx !== 0,
                        })}
                        cols={{ sm: 1, md: 1, lg: 12 }}
                    >
                        <Col lg={isRight ? { span: 6, start: 7, order: 2 } : 6}>
                            <Panel className="py-24 px-4 text-center">
                                <DatoImage
                                    image={image}
                                    style={{ maxHeight: '80px', maxWidth: '60%' }}
                                    objectFit="contain"
                                    className="mx-auto"
                                />
                            </Panel>
                        </Col>
                        <Col lg={isRight ? { span: 5, order: 1 } : { span: 5, start: 8 }} className="mt-8 lg:mt-0">
                            <Typography variant="heading3" as="h2" paragraph>
                                {name}
                            </Typography>
                            <Typography paragraph>
                                {description}
                            </Typography>
                            {linkTitle && link && (
                                <Button to={link} variant="secondary">
                                    {linkTitle}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            );
        })}
    </>
);

ProductPartnerSet.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        linkTitle: PropTypes.string,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        Image: PropTypes.datoImage.isRequired,
    })),
};

ProductPartnerSet.defaultProps = {
    items: [],
};

export const query = graphql`
    fragment ProductPartnerSetProps on DatoCmsProductPartnerSet {
        originalId
        items {
            link
            linkTitle
            name
            description
            image {
                gatsbyImageData(imgixParams: { auto: "format" })
                alt
                url
                smartTags
            }
        }
    }
`;

export default ProductPartnerSet;
