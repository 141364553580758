import React from 'react';
import { IGatsbyPage } from 'gatsby/internal';
import { useDebug } from './DebugProvider';
import DebugPill from './DebugPill';

type PageDebugProps = {
    pageResources: {
        page: IGatsbyPage
    }
};

const PageDebug = ({ pageResources }: PageDebugProps): JSX.Element | null => {
    const { showTemplateNames } = useDebug();

    if (showTemplateNames) {
        return (
            <DebugPill>
                Template:
                {' '}
                {pageResources.page.componentChunkName}
            </DebugPill>
        );
    }

    return null;
};

export default PageDebug;
