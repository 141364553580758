import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const spanClasses = {
    xs: {
        1: 'col-span-1',
        2: 'col-span-2',
        3: 'col-span-3',
        4: 'col-span-4',
        5: 'col-span-5',
        6: 'col-span-6',
        7: 'col-span-7',
        8: 'col-span-8',
        9: 'col-span-9',
        10: 'col-span-10',
        11: 'col-span-11',
        12: 'col-span-12',
    },
    sm: {
        1: 'sm:col-span-1',
        2: 'sm:col-span-2',
        3: 'sm:col-span-3',
        4: 'sm:col-span-4',
        5: 'sm:col-span-5',
        6: 'sm:col-span-6',
        7: 'sm:col-span-7',
        8: 'sm:col-span-8',
        9: 'sm:col-span-9',
        10: 'sm:col-span-10',
        11: 'sm:col-span-11',
        12: 'sm:col-span-12',
    },
    md: {
        1: 'md:col-span-1',
        2: 'md:col-span-2',
        3: 'md:col-span-3',
        4: 'md:col-span-4',
        5: 'md:col-span-5',
        6: 'md:col-span-6',
        7: 'md:col-span-7',
        8: 'md:col-span-8',
        9: 'md:col-span-9',
        10: 'md:col-span-10',
        11: 'md:col-span-11',
        12: 'md:col-span-12',
    },
    lg: {
        1: 'lg:col-span-1',
        2: 'lg:col-span-2',
        3: 'lg:col-span-3',
        4: 'lg:col-span-4',
        5: 'lg:col-span-5',
        6: 'lg:col-span-6',
        7: 'lg:col-span-7',
        8: 'lg:col-span-8',
        9: 'lg:col-span-9',
        10: 'lg:col-span-10',
        11: 'lg:col-span-11',
        12: 'lg:col-span-12',
    },
    xl: {
        1: 'xl:col-span-1',
        2: 'xl:col-span-2',
        3: 'xl:col-span-3',
        4: 'xl:col-span-4',
        5: 'xl:col-span-5',
        6: 'xl:col-span-6',
        7: 'xl:col-span-7',
        8: 'xl:col-span-8',
        9: 'xl:col-span-9',
        10: 'xl:col-span-10',
        11: 'xl:col-span-11',
        12: 'xl:col-span-12',
    },
};

const startClasses = {
    xs: {
        1: 'col-start-1',
        2: 'col-start-2',
        3: 'col-start-3',
        4: 'col-start-4',
        5: 'col-start-5',
        6: 'col-start-6',
        7: 'col-start-7',
        8: 'col-start-8',
        9: 'col-start-9',
        10: 'col-start-10',
        11: 'col-start-11',
        12: 'col-start-12',
    },
    sm: {
        1: 'sm:col-start-1',
        2: 'sm:col-start-2',
        3: 'sm:col-start-3',
        4: 'sm:col-start-4',
        5: 'sm:col-start-5',
        6: 'sm:col-start-6',
        7: 'sm:col-start-7',
        8: 'sm:col-start-8',
        9: 'sm:col-start-9',
        10: 'sm:col-start-10',
        11: 'sm:col-start-11',
        12: 'sm:col-start-12',
    },
    md: {
        1: 'md:col-start-1',
        2: 'md:col-start-2',
        3: 'md:col-start-3',
        4: 'md:col-start-4',
        5: 'md:col-start-5',
        6: 'md:col-start-6',
        7: 'md:col-start-7',
        8: 'md:col-start-8',
        9: 'md:col-start-9',
        10: 'md:col-start-10',
        11: 'md:col-start-11',
        12: 'md:col-start-12',
    },
    lg: {
        1: 'lg:col-start-1',
        2: 'lg:col-start-2',
        3: 'lg:col-start-3',
        4: 'lg:col-start-4',
        5: 'lg:col-start-5',
        6: 'lg:col-start-6',
        7: 'lg:col-start-7',
        8: 'lg:col-start-8',
        9: 'lg:col-start-9',
        10: 'lg:col-start-10',
        11: 'lg:col-start-11',
        12: 'lg:col-start-12',
    },
    xl: {
        1: 'xl:col-start-1',
        2: 'xl:col-start-2',
        3: 'xl:col-start-3',
        4: 'xl:col-start-4',
        5: 'xl:col-start-5',
        6: 'xl:col-start-6',
        7: 'xl:col-start-7',
        8: 'xl:col-start-8',
        9: 'xl:col-start-9',
        10: 'xl:col-start-10',
        11: 'xl:col-start-11',
        12: 'xl:col-start-12',
    },
};

const endClasses = {
    xs: {
        1: 'col-end-1',
        2: 'col-end-2',
        3: 'col-end-3',
        4: 'col-end-4',
        5: 'col-end-5',
        6: 'col-end-6',
        7: 'col-end-7',
        8: 'col-end-8',
        9: 'col-end-9',
        10: 'col-end-10',
        11: 'col-end-11',
        12: 'col-end-12',
    },
    sm: {
        1: 'sm:col-end-1',
        2: 'sm:col-end-2',
        3: 'sm:col-end-3',
        4: 'sm:col-end-4',
        5: 'sm:col-end-5',
        6: 'sm:col-end-6',
        7: 'sm:col-end-7',
        8: 'sm:col-end-8',
        9: 'sm:col-end-9',
        10: 'sm:col-end-10',
        11: 'sm:col-end-11',
        12: 'sm:col-end-12',
    },
    md: {
        1: 'md:col-end-1',
        2: 'md:col-end-2',
        3: 'md:col-end-3',
        4: 'md:col-end-4',
        5: 'md:col-end-5',
        6: 'md:col-end-6',
        7: 'md:col-end-7',
        8: 'md:col-end-8',
        9: 'md:col-end-9',
        10: 'md:col-end-10',
        11: 'md:col-end-11',
        12: 'md:col-end-12',
    },
    lg: {
        1: 'lg:col-end-1',
        2: 'lg:col-end-2',
        3: 'lg:col-end-3',
        4: 'lg:col-end-4',
        5: 'lg:col-end-5',
        6: 'lg:col-end-6',
        7: 'lg:col-end-7',
        8: 'lg:col-end-8',
        9: 'lg:col-end-9',
        10: 'lg:col-end-10',
        11: 'lg:col-end-11',
        12: 'lg:col-end-12',
    },
    xl: {
        1: 'xl:col-end-1',
        2: 'xl:col-end-2',
        3: 'xl:col-end-3',
        4: 'xl:col-end-4',
        5: 'xl:col-end-5',
        6: 'xl:col-end-6',
        7: 'xl:col-end-7',
        8: 'xl:col-end-8',
        9: 'xl:col-end-9',
        10: 'xl:col-end-10',
        11: 'xl:col-end-11',
        12: 'xl:col-end-12',
    },
};

const orderClasses = {
    xs: {
        1: 'order-1',
        2: 'order-2',
        3: 'order-3',
        4: 'order-4',
        5: 'order-5',
        6: 'order-6',
        7: 'order-7',
        8: 'order-8',
        9: 'order-9',
        10: 'order-10',
        11: 'order-11',
        12: 'order-12',
    },
    sm: {
        1: 'sm:order-1',
        2: 'sm:order-2',
        3: 'sm:order-3',
        4: 'sm:order-4',
        5: 'sm:order-5',
        6: 'sm:order-6',
        7: 'sm:order-7',
        8: 'sm:order-8',
        9: 'sm:order-9',
        10: 'sm:order-10',
        11: 'sm:order-11',
        12: 'sm:order-12',
    },
    md: {
        1: 'md:order-1',
        2: 'md:order-2',
        3: 'md:order-3',
        4: 'md:order-4',
        5: 'md:order-5',
        6: 'md:order-6',
        7: 'md:order-7',
        8: 'md:order-8',
        9: 'md:order-9',
        10: 'md:order-10',
        11: 'md:order-11',
        12: 'md:order-12',
    },
    lg: {
        1: 'lg:order-1',
        2: 'lg:order-2',
        3: 'lg:order-3',
        4: 'lg:order-4',
        5: 'lg:order-5',
        6: 'lg:order-6',
        7: 'lg:order-7',
        8: 'lg:order-8',
        9: 'lg:order-9',
        10: 'lg:order-10',
        11: 'lg:order-11',
        12: 'lg:order-12',
    },
    xl: {
        1: 'xl:order-1',
        2: 'xl:order-2',
        3: 'xl:order-3',
        4: 'xl:order-4',
        5: 'xl:order-5',
        6: 'xl:order-6',
        7: 'xl:order-7',
        8: 'xl:order-8',
        9: 'xl:order-9',
        10: 'xl:order-10',
        11: 'xl:order-11',
        12: 'xl:order-12',
    },
};

const getClassNamesForBreakpoint = (prefix, flags) => {
    if (!prefix || !flags) {
        return [];
    }

    if (typeof flags === 'number') {
        return spanClasses[prefix][flags];
    }

    const classNames = [];

    if (flags.span) {
        classNames.push(spanClasses[prefix][flags.span]);
    }

    if (flags.start) {
        classNames.push(startClasses[prefix][flags.start]);
    }

    if (flags.end) {
        classNames.push(endClasses[prefix][flags.end]);
    }

    if (flags.order) {
        classNames.push(orderClasses[prefix][flags.order]);
    }

    return classNames;
};

const Col = ({
    children, xs, sm, md, lg, xl, className, style,
}) => (
    <div
        className={
            classnames(
                className,
                getClassNamesForBreakpoint('xs', xs),
                getClassNamesForBreakpoint('sm', sm),
                getClassNamesForBreakpoint('md', md),
                getClassNamesForBreakpoint('lg', lg),
                getClassNamesForBreakpoint('xl', xl),
            )
        }
        style={style}
    >
        {children}
    </div>
);

const breakpointShape = {
    span: PropTypes.number,
    start: PropTypes.number,
    end: PropTypes.number,
    order: PropTypes.number,
};

Col.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
};

Col.defaultProps = {
    children: null,
    xs: 1,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    className: '',
    style: {},
};

export default Col;
