import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import DatoImage from '../DatoImage';
import Link from '../Link';
import Typography from '../Typography';

const ColorCta = ({ image, title, buttonText, buttonLink }) => (
    <div className="bg-lightBlue-100 text-gray-900">
        <div className="flex flex-wrap items-center py-9 px-4 lg:px-16">
            <div className="order-1 md:order-2 mb-10 pr-6 pl-6 lg:pl-4 lg:pr-0 md:mb-0 w-full md:w-2/5">
                <DatoImage className="mx-auto" image={image} />
            </div>
            <div className="order-2 md:order-1 w-full md:w-3/5">
                <div>
                    <Typography variant="heading2">
                        {title}
                    </Typography>
                </div>
                <div className="mt-6 md:mt-12">
                    <Link to={buttonLink} className="border border-gray-900 bg-gray-900 no-underline uppercase font-bold font-display py-2 px-6 rounded-full tracking-widest inline-block text-white hover:bg-transparent hover:text-gray-900 duration-300">
                        {buttonText}
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

ColorCta.propTypes = {
    image: PropTypes.datoImage.isRequired,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
};

export const query = graphql`
    fragment ColorCtaProps on DatoCmsColorCta {
        originalId
        title
        buttonText
        buttonLink
        image {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
    }
`;

export default ColorCta;
