import React from 'react';
import { useLayoutActions, useLayoutState } from '../layout/Context';
import Dialog from './Dialog';

const CalendlyModal = () => {
    const { calendlyModalOpen, calendlyUrl } = useLayoutState();
    const { closeCalendlyModal } = useLayoutActions();

    const url = `${calendlyUrl}?embed_type=PopupText&embed_domain=brainbits.net`;

    if (!calendlyUrl) {
        return null;
    }

    return (
        <Dialog
            className="w-full max-w-[480px] h-full max-h-[750px]"
            open={calendlyModalOpen}
            onClose={closeCalendlyModal}
        >
            <iframe
                className="w-full h-full"
                title={url}
                src={url}
                frameBorder="0"
            />
        </Dialog>
    );
};

export default CalendlyModal;
