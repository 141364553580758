import React from 'react';
import PropTypes from '../util/PropTypes';
import Typography from './Typography';

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {
    children: null,
};

const CallToActionBoxText = ({ children }) => (
    <Typography className="mt-4 mb-6">
        {children}
    </Typography>
);

CallToActionBoxText.propTypes = propTypes;
CallToActionBoxText.defaultProps = defaultProps;

export default CallToActionBoxText;
