import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    small: PropTypes.bool,
};

const defaultProps = {
    children: null,
    small: false,
};

const NavigationItemIcon = ({ children, small }) => (
    <div className="w-5 first:mr-2 last:ml-2">
        {React.cloneElement(children, {
            className: small ? 'w-3 h-3' : 'w-4 h-4',
            color: 'currentColor',
        })}
    </div>
);

NavigationItemIcon.propTypes = propTypes;
NavigationItemIcon.defaultProps = defaultProps;

export default NavigationItemIcon;
