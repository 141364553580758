import classnames from 'classnames';
import React, { forwardRef } from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dentTopLeft: PropTypes.bool,
    dentTopRight: PropTypes.bool,
    dentBottomLeft: PropTypes.bool,
    dentBottomRight: PropTypes.bool,
    variant: PropTypes.oneOf(['light', 'dark', 'custom']),
    overflow: PropTypes.oneOf(['hidden', 'unset']),
    customColor: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: '',
    dentTopLeft: false,
    dentTopRight: false,
    dentBottomLeft: false,
    dentBottomRight: false,
    variant: 'light',
    customColor: '',
    overflow: 'hidden',
};

const Panel = forwardRef((props, ref) => {
    const {
        children,
        className,
        dentTopLeft,
        dentTopRight,
        dentBottomLeft,
        dentBottomRight,
        variant,
        customColor,
        overflow,
    } = props;

    return (
        <div
            ref={ref}
            className={classnames(
                'w-full',
                {
                    'rounded-tl-[30px]': dentTopLeft,
                    'rounded-tr-[30px]': dentTopRight,
                    'rounded-bl-[30px]': dentBottomLeft,
                    'rounded-br-[30px]': dentBottomRight,
                    'bg-gray-900 text-white': variant === 'dark',
                    'bg-gray-50': variant === 'light',
                    'overflow-hidden': overflow === 'hidden',
                },
                className,
            )}
            style={{
                backgroundColor: variant === 'custom' && customColor,
            }}
        >
            {children}
        </div>
    );
});

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
