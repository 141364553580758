import classnames from 'classnames';
import React from 'react';
import formatTelephoneLink from '../util/formatTelephoneLink';
import PropTypes from '../util/PropTypes';
import TelephoneIcon from './icons/TelephoneIcon';

const TelephoneLink = ({ children, className, hideIcon }) => (
    <a
        href={formatTelephoneLink(children)}
        className={classnames(className, 'no-underline inline-flex items-center')}
    >
        {!hideIcon && <TelephoneIcon className="h-6 w-6 mr-1" />}
        {children}
    </a>
);

TelephoneLink.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    hideIcon: PropTypes.bool,
};

TelephoneLink.defaultProps = {
    className: '',
    hideIcon: false,
};

export default TelephoneLink;
