import classnames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import hasNoBackgroundColor from '../util/hasNoBackgroundColor';
import PropTypes from '../util/PropTypes';
import BasicSlider from './BasicSlider.tsx';
import Button from './Button';
import ClientsLogos from './ClientsLogos';
import Col from './Col';
import Container from './Container';
import Row from './Row';
import Typography from './Typography';

const TestimonialPanel = ({
    data,
}) => {
    const {
        quote, author, jobPosition, company, caseStudy,
    } = data;

    return (
        <div>
            <blockquote>
                <Typography variant="heading3" className="font-normal" as="p" paragraph>
                    <span>„</span>
                    {quote}
                    <span>“</span>
                </Typography>
            </blockquote>
            <div className="mt-6">
                <Typography className="inline bg-lightBlue-100 uppercase tracking-widest p-0.5">
                    <Typography variant="intro" as="strong">
                        {author}
                    </Typography>
                    {', '}
                    {`${(jobPosition) ? `${jobPosition}, ` : ''}${company}`}
                </Typography>
            </div>
            {!!caseStudy && (
                <div className="mt-14">
                    <Button to={caseStudy.permalink} variant="secondary">
                        Case Study ansehen
                    </Button>
                </div>
            )}
        </div>
    );
};

TestimonialPanel.propTypes = {
    data: PropTypes.shape({
        quote: PropTypes.string,
        author: PropTypes.string,
        jobPosition: PropTypes.string,
        company: PropTypes.string,
        caseStudy: PropTypes.shape({
            permalink: PropTypes.string,
        }),
    }).isRequired,
};

const TestimonialSlider = ({ headline, backgroundColor, testimonials }) => {
    if (!testimonials || testimonials.length < 1) return null;

    const noBgColor = hasNoBackgroundColor(backgroundColor);

    return (
        <div
            className={classnames(
                'px-4',
                {
                    'py-10 sm:py-24': !noBgColor,
                },
            )}
            style={{
                backgroundColor: backgroundColor?.hex || '',
            }}
        >
            {headline && (
                <Container>
                    <Row cols={{ sm: 1, md: 1 }} className="mb-16">
                        <Col lg={{ span: 8, start: 3 }}>
                            <Typography variant="heading3" as="h2">
                                {headline}
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            )}
            <Container>
                <Row cols={{ sm: 1, md: 1 }}>
                    <Col lg={{ span: 8, start: 3 }}>
                        {testimonials.length > 1 && (
                            <BasicSlider>
                                <div className="flex items-center text-center min-h-[48px]">
                                    <BasicSlider.SliderLeftButton className="text-black" />
                                    <div className="flex mx-4 items-center">
                                        <Typography variant="heading4" as="div" className="inline-block relative align-middle mr-1">
                                            <BasicSlider.Slides transition="0.5s" slidesPerView={1} animation="fade" className="w-3.5 text-center">
                                                {testimonials.map((slide, index) => (
                                                    <div className="w-3.5" key={slide.company}>
                                                        {index + 1}
                                                    </div>
                                                ))}
                                            </BasicSlider.Slides>
                                        </Typography>
                                        <Typography variant="heading5" className="mt-1.5">
                                            {`/ ${testimonials.length}`}
                                        </Typography>
                                    </div>
                                    <BasicSlider.SliderRightButton className="text-black" />
                                </div>
                                <div className="relative mt-6">
                                    <div className="flex flex-col lg:flex-row">
                                        <BasicSlider.Slides transition="0.3s" animation="fade">
                                            {testimonials.map(data => (
                                                <TestimonialPanel data={data} />
                                            ))}
                                        </BasicSlider.Slides>
                                    </div>
                                </div>
                            </BasicSlider>
                        )}
                        {testimonials.length === 1 && (
                            <TestimonialPanel data={testimonials[0]} />
                        )}
                    </Col>
                </Row>
                <Row cols={{ sm: 1, md: 1 }} className="mt-16">
                    <Col lg={{ span: 8, start: 3 }}>
                        <ClientsLogos />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

TestimonialSlider.propTypes = {
    headline: PropTypes.string,
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string,
    }),
    testimonials: PropTypes.arrayOf(TestimonialPanel.propTypes.data),
};

TestimonialSlider.defaultProps = {
    headline: '',
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string,
    }),
    testimonials: [],
};

/*
* This component is used exclusively for Hardcoded Testimonial->Dato connections
* and should be removed once that functionality is removed.
* */
export const OldTestimonialSlider = ({ testimonials }) => {
    const flatTestimonials = testimonials.flatMap(x => x.testimonials);

    if (!testimonials || testimonials.length < 1 || flatTestimonials.length < 1) return null;
    const { headline } = testimonials[0];

    return <TestimonialSlider testimonials={flatTestimonials} headline={headline} />;
};

OldTestimonialSlider.propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.shape({
        headline: PropTypes.string,
        testimonials: PropTypes.arrayOf(TestimonialPanel.propTypes.data),
    })),
};

OldTestimonialSlider.defaultProps = {
    testimonials: [],
};

export const query = graphql`
    fragment TestimonialsData on DatoCmsPage {
        testimonials {
            headline
            testimonials {
                quote
                author
                jobPosition
                company
                caseStudy {
                    id
                    title
                    permalink
                }
            }
        }
    }
    fragment TestimonialClientProps on DatoCmsTestimonialClient {
        originalId
        headline
        backgroundColor {
            hex
        }
        testimonials {
            company
            jobPosition
            quote
            author
            caseStudy {
                id
                title
                permalink
            }
        }
    }
`;

export default TestimonialSlider;
