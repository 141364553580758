import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import CallToActionBox from '../CallToActionBox';
import CallToActionBoxLink from '../CallToActionBoxLink';
import CallToActionBoxText from '../CallToActionBoxText';
import CallToActionBoxTitle from '../CallToActionBoxTitle';
import Col from '../Col';
import Container from '../Container';
import DownloadIcon from '../icons/DownloadIcon';
import Row from '../Row';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';

const DownloadBox = ({
    image, title, text, items, blockParent, isPage,
}) => {
    const renderDepth = React.useContext(BlockRendererContext);
    if (renderDepth <= 0 && !blockParent && isPage) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <CallToActionBox thumb={image}>
                            <CallToActionBoxTitle>
                                {title}
                            </CallToActionBoxTitle>
                            <CallToActionBoxText>
                                {text}
                            </CallToActionBoxText>
                            {items.map(({ title: itemTitle, downloadType, download, link }) => (
                                <CallToActionBoxLink
                                    url={download?.url || link}
                                    label={itemTitle}
                                    prependIcon={downloadType === 'download' ? <DownloadIcon /> : undefined}
                                    primary={downloadType === 'link'}
                                />
                            ))}
                        </CallToActionBox>
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <CallToActionBox thumb={image}>
            <CallToActionBoxTitle>
                {title}
            </CallToActionBoxTitle>
            <CallToActionBoxText>
                {text}
            </CallToActionBoxText>
            {items.map(({ title: itemTitle, downloadType, download, link }) => (
                <CallToActionBoxLink
                    url={download?.url || link}
                    label={itemTitle}
                    prependIcon={downloadType === 'download' ? <DownloadIcon /> : undefined}
                    primary={downloadType === 'link'}
                />
            ))}
        </CallToActionBox>
    );
};

DownloadBox.propTypes = {
    image: PropTypes.datoImage,
    title: PropTypes.string,
    text: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        download: PropTypes.datoDownload,
        downloadType: PropTypes.oneOf(['download', 'link']).isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string,
    })).isRequired,
    isPage: PropTypes.bool,
    blockParent: PropTypes.string,
};

DownloadBox.defaultProps = {
    image: null,
    title: '',
    text: '',
    isPage: false,
    blockParent: null,
};

export const query = graphql`
    fragment DownloadBoxProps on DatoCmsDownloadBox {
        originalId
        items {
            title
            downloadType
            download {
                url
            }
            link
        }
        text
        title
        image {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
    }
`;

export default DownloadBox;
