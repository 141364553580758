import React from 'react';
import PropTypes from '../../util/PropTypes';
import SimpleQuote from '../SimpleQuote';

const InlineBlockquote = ({ attribution, children }) => (
    <div>
        <SimpleQuote>
            <SimpleQuote.Blockquote>
                {React.Children.map(children, child => React.cloneElement(child, { className: 'inline' }))}
            </SimpleQuote.Blockquote>
            {attribution && (
                <SimpleQuote.Quoter>
                    {attribution}
                </SimpleQuote.Quoter>
            )}
        </SimpleQuote>
    </div>
);

InlineBlockquote.propTypes = {
    attribution: PropTypes.string,
    children: PropTypes.node,
};

InlineBlockquote.defaultProps = {
    attribution: '',
    children: null,
};

export default InlineBlockquote;
