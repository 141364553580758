/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';
import { FieldValues } from 'react-hook-form';
import slugify from 'slugify';
import { StructuredText } from 'datocms-structured-text-utils';
import FormProvider, { useFormProvider } from './FormProvider';
import { useLanguage } from '../language/LanguageProvider';

export enum FormFieldType {
    select = 'select',
    input = 'input',
    email = 'email',
    tel = 'tel',
    number = 'number',
    textarea = 'textarea',
    checkbox = 'checkbox',
    radio = 'radio',
    text = 'text',
}

interface RenderFieldArgs {
    index: number
    id: string
    ref: React.Ref<any>
    type: FormFieldType
    label: string
    placeholder?: string
    autoComplete?: string
    options: { label: string, value: string }[]
    required: boolean
    invalid: boolean
    hasErrors: boolean
    errors: string[]
    disabled: boolean
    touched: boolean
    description?: StructuredText
}

interface SubmitButtonArgs {
    type: 'submit'
    children: string | JSX.Element
    disabled: boolean
}

interface RenderApiErrorArgs {
    errors: string[]
}

interface Field {
    id: string
    __typename: string
    label: string
    placeholder: string
    inputType: string
    required?: boolean
    autocomplete?: string
    display?: string
    options?: { option: string }[]
    description?: StructuredText
}

export interface FormSchema {
    originalId: string
    formFields: Field[]
    submitButtonText?: string
}

interface FormProps {
    schema: FormSchema
    id?: string
    values?: Partial<FieldValues>
    onPreSubmit?: (props: FieldValues) => FieldValues
    onSubmitting?: () => void
    renderForm: ({ children }: PropsWithChildren) => JSX.Element
    renderField: (props: RenderFieldArgs) => JSX.Element
    renderApiErrors: (props: RenderApiErrorArgs) => JSX.Element
    renderSubmitButton: (props: SubmitButtonArgs) => JSX.Element
    renderConfirmationMessage: () => JSX.Element
    renderLegalMessage: () => JSX.Element
    renderLoadingSpinner: () => JSX.Element
}

const typeRules = {
    password: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    tel: /[0-9 \-()]+/g,
    number: /^-?\d+\.?\d*$/,
    text: undefined,
};

interface FieldRendererProps {
    field: Field
    index: number
    loading: boolean
    renderField: (props: any) => JSX.Element
    schema: FormSchema
}

const FieldRenderer = ({ field, renderField, schema, loading, index }: FieldRendererProps): JSX.Element => {
    const provider = useFormProvider();
    const { language } = useLanguage();

    const { __typename: orignalTypename } = field;

    const typename = orignalTypename.replace('DatoCms', '').replace('Record', '');

    const sluggedLabel = slugify(field.label.replaceAll('.', '').slice(0, 20), { lower: true });

    return renderField({
        index,
        id: `form-${schema.originalId}-${sluggedLabel}`,
        label: field.label,
        placeholder: field.placeholder,
        disabled: loading,
        description: field.description,
        hasErrors: !!(provider.errors && provider.errors[sluggedLabel]),
        errors: (provider.errors && provider.errors[sluggedLabel]) ? [
            provider.errors[sluggedLabel],
        ].map((x) => {
            if (x && x.type === 'required') {
                return {
                    en: 'This is a required field.',
                    de: 'Dies ist ein Pflichtfeld.',
                };
            }
            if (x && x.type === 'pattern') {
                if (field.inputType === 'email') {
                    return {
                        en: 'This is not a valid email.',
                        de: 'Dies ist keine gültige E-Mail.',
                    };
                }
                if (field.inputType === 'tel') {
                    return {
                        en: 'This is not a valid phone number',
                        de: 'Dies ist keine gültige Telefonnummer.',
                    };
                }
                return {
                    en: 'This is not valid.',
                    de: 'Diese Eingabe ist nicht gültig',
                };
            }
            if (x && x.type === 'maxLength') {
                return {
                    en: 'Maximum length exceeded.',
                    de: 'Maximale Länge überschritten.',
                };
            }
            if (x && x.type === 'minLength') {
                return {
                    en: 'This is not long enough.',
                    de: 'Dies ist nicht lang genug.',
                };
            }
            return {
                en: 'Error',
                de: 'Fehler',
            };
        }).map(x => x[language as 'en'|'de']) : [],
        touched: !!provider.touched[sluggedLabel],
        invalid: !!(provider.errors && provider.errors[sluggedLabel]),
        options: (field.options || []).map(x => ({ label: x.option, value: x.option, id: `field-${schema.originalId}-${index}-${slugify(x.option, { lower: true })}` })),
        required: field.required,
        type: (() => {
            if (typename === 'Input') {
                return field.inputType.toLowerCase();
            }
            if (typename === 'Select' && field?.display) {
                return field.display.toLowerCase();
            }
            return typename.toLowerCase();
        })(),
        autoComplete: field.autocomplete,
        ...provider.register(sluggedLabel, {
            required: field.required,
            pattern: (() => {
                if (typename === 'Input') {
                    if (field.inputType === 'email') {
                        return typeRules.email;
                    }
                    if (field.inputType === 'tel') {
                        return typeRules.tel;
                    }
                }
                return undefined;
            })(),
        }),
    });
};

const FormRenderer = ({
    schema,
    id,
    values,
    renderForm,
    renderField,
    renderSubmitButton,
    renderLoadingSpinner,
    renderConfirmationMessage,
    renderApiErrors,
    renderLegalMessage,
    onPreSubmit,
    onSubmitting,
}: FormProps): JSX.Element => {
    const { language } = useLanguage();
    const [success, setSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [apiErrors, setApiErrors] = React.useState<string[]>([]);

    if (!schema.originalId) {
        throw new Error('Missing id on Form');
    }

    if (!renderField) {
        throw new Error('renderField must be defined on Form');
    }

    if (!renderSubmitButton) {
        throw new Error('renderSubmitButton must be defined on Form');
    }

    if (!renderApiErrors) {
        throw new Error('renderApiErrors must be defined on Form');
    }

    if (!renderLoadingSpinner) {
        throw new Error('renderLoadingSpinner must be defined on Form');
    }

    const resolveSubmission = async (res: Response): Promise<Record<string, unknown>> => {
        const e = await res.text();
        if (!res.ok) {
            if (e.includes('{')) {
                const json = JSON.parse(e);
                throw new Error(json.message[language as 'en'|'de']);
            } else {
                const unknownString = {
                    en: 'An unknown error occurred.',
                    de: 'Ein unbekannter Fehler ist aufgetreten. Bitte sende uns eine E-Mail oder rufe uns an. Wir entschuldigen uns für die Unannehmlichkeiten!',
                }[language as 'en'|'de'];
                throw new Error(unknownString);
            }
        } else {
            setSuccess(true);
            return JSON.parse(e);
        }
    };

    const onSubmit = (rawData: FieldValues): Promise<Record<string, unknown>|null> => {
        setLoading(true);
        setApiErrors([]);
        if (onSubmitting) {
            onSubmitting();
        }

        const formData = onPreSubmit ? onPreSubmit(rawData) : rawData;

        return fetch(`/api/submissions?id=${schema.originalId}&lang=${language}`, {
            method: 'POST',
            body: JSON.stringify({
                referrer: window.location.href,
                data: formData,
            }),
        })
            .then(res => resolveSubmission(res))
            .catch((err) => {
                setApiErrors([err.message]);
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <FormProvider
            id={id || schema.originalId}
            values={values}
            disabled={loading}
            onSubmit={onSubmit}
        >
            {renderForm({
                children: (
                    <>
                        {schema.formFields.map((field, idx) => (
                            <FieldRenderer
                                key={field.id}
                                field={field}
                                renderField={renderField}
                                schema={schema}
                                loading={loading}
                                index={idx}
                            />
                        ))}
                    </>
                ),
            })}
            {renderLegalMessage()}
            {renderSubmitButton({
                type: 'submit',
                children: loading ? renderLoadingSpinner() : (schema.submitButtonText || 'Submit'),
                disabled: loading || success,
            })}
            {apiErrors && apiErrors.length > 0 && renderApiErrors({
                errors: apiErrors,
            })}
            {success && renderConfirmationMessage()}
        </FormProvider>
    );
};

export default FormRenderer;
