import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    size: '24',
    color: '#3a3a3a',
};

const DownloadIcon = ({ color, size, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 20"
        width={size}
        height={size}
        fill="none"
    >
        <path d="M13.247 10.9415L7.72073 16.4677L2.09409 10.8411" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M7.72075 1.38037V16.4679" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M15.2142 18.806H0.126678" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
);

DownloadIcon.propTypes = propTypes;
DownloadIcon.defaultProps = defaultProps;

export default React.memo(DownloadIcon);
