import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';
import Link from './Link';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    small: PropTypes.bool,
    to: PropTypes.string,
    border: PropTypes.bool,
};

const defaultProps = {
    children: null,
    className: null,
    small: false,
    to: '',
    border: false,
};

const NavigationItem = ({
    children,
    className,
    small,
    to,
    border,
}) => {
    let element;

    if (to) {
        element = (
            <Link
                className={classnames(
                    'no-underline transition duration-300 flex items-center w-full text-left whitespace-nowrap outline-none focus:outline-none group-hover:text-lightBlue-500 group-active:text-lightBlue-500 hover:text-lightBlue-500 focus:text-lightBlue-500 active:text-lightBlue-500',
                    {
                        'mx-8 sm:py-1 lg:py-3 sm:mx-12 lg:mx-6 xl:mx-12 hover:font-medium focus:font-medium': small,
                        'border-t': border,
                    },
                )}
                activeClassName={small ? 'bg-transparent text-lightBlue-500 font-medium' : 'outline-none'}
                to={to}
            >
                {children}
            </Link>
        );
    } else {
        element = (
            <div
                className={classnames(
                    'bg-none transition duration-300 flex items-center w-full text-left whitespace-nowrap font-normal outline-none focus:outline-none group-hover:text-lightBlue-500 group-active:text-lightBlue-500 hover:text-lightBlue-500 focus:text-lightBlue-500 active:text-lightBlue-500',
                    {
                        'lg:py-3 mx-8 sm:py-1 sm:mx-12 lg:mx-6 xl:mx-12': small,
                        'border-t': border,
                    },
                )}
            >
                {children}
            </div>
        );
    }

    return (
        <div className={className}>
            {element}
        </div>
    );
};

NavigationItem.propTypes = propTypes;
NavigationItem.defaultProps = defaultProps;

export default NavigationItem;
