import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const MailIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 24"
    >
        <path
            d="M16 8.3v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 17.3v-9a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 8.3Zm-14.5 0v1.2L5.7 13c.5.4 1.6 1.4 2.3 1.4s1.8-1 2.3-1.4l4.2-3.4V8.3Zm13 9v-5.8L11.2 14c-.7.6-1.9 1.8-3.2 1.8S5.5 14.6 4.8 14l-3.3-2.5v5.8Z"
            fill="currentColor"
        />
    </svg>
);

MailIcon.propTypes = propTypes;
MailIcon.defaultProps = defaultProps;

export default React.memo(MailIcon);
