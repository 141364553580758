import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const colorClassNames = {
    lightBlue: 'bg-lightBlue-100',
};

const Highlight = ({ children, className, color, as: Component }) => (
    <Component className={classnames('inline-block relative', className)}>
        <span className={classnames(
            'absolute inset-x-0 top-[60%] bottom-[10%]',
            colorClassNames[color],
        )}
        />
        <span className="relative">
            {children}
        </span>
    </Component>
);

Highlight.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['lightBlue']),
    as: PropTypes.headingTag,
};

Highlight.defaultProps = {
    children: null,
    className: '',
    color: 'lightBlue',
    as: 'span',
};

export default Highlight;
