import React from 'react';
import PropTypes from '../util/PropTypes';
import Button from './Button';
import Typography from './Typography';

const CaseStudyContent = ({ caseStudy, ctaTitle }) => {
    if (!caseStudy) {
        return null;
    }

    return (
        <>
            <Typography variant="heading5" as="span" className="block mb-1.5">
                {caseStudy.client}
            </Typography>
            {!!caseStudy.teaserTitle && (
                <Typography variant="heading3" paragraph>
                    {caseStudy.teaserTitle}
                </Typography>
            )}
            {!!caseStudy.teaserText && (
                <Typography paragraph>
                    {caseStudy.teaserText}
                </Typography>
            )}
            {!!ctaTitle && !!caseStudy.permalink && (
                <Button to={caseStudy.permalink} variant="pill-dark">
                    {ctaTitle}
                </Button>
            )}
        </>
    );
};

CaseStudyContent.propTypes = {
    caseStudy: PropTypes.shape({
        client: PropTypes.string,
        teaserTitle: PropTypes.string,
        teaserText: PropTypes.string,
        permalink: PropTypes.string,
    }).isRequired,
    ctaTitle: PropTypes.string,
};

CaseStudyContent.defaultProps = {
    ctaTitle: '',
};

export default CaseStudyContent;
