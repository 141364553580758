import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: 'w-6 h-6',
};

const CancelIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="currentColor" d="m1 1 17 17.2m-17 0L18 1" strokeWidth="2" stroke="currentColor" />
    </svg>
);

CancelIcon.propTypes = propTypes;
CancelIcon.defaultProps = defaultProps;

export default React.memo(CancelIcon);
