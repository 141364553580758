import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';
import Typography from './Typography';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

const SimpleQuote = ({ children, className }) => (
    <div className={classnames('flex items-center flex-wrap', className)}>
        {children}
    </div>
);

SimpleQuote.propTypes = propTypes;
SimpleQuote.defaultProps = defaultProps;

const Blockquote = ({ children }) => (
    <blockquote className="border-l-4 border-lightBlue-500 py-4 px-5 md:px-8">
        <Typography variant="quote" align="left">
            <span>“</span>
            {children}
            <span>”</span>
        </Typography>
    </blockquote>
);

Blockquote.propTypes = {
    children: PropTypes.node,
};

Blockquote.defaultProps = {
    children: null,
};

const Quoter = ({ children }) => (
    <div className="border-l-4 border-lightBlue-500 pb-4 px-5 md:px-8">
        <Typography align="left">
            –
            {' '}
            {children}
        </Typography>
    </div>
);

Quoter.propTypes = {
    children: PropTypes.node,
};

Quoter.defaultProps = {
    children: null,
};

SimpleQuote.Blockquote = Blockquote;
SimpleQuote.Quoter = Quoter;

export default SimpleQuote;
