import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import Link from '../components/Link';
import MenuButton from '../components/MenuButton';
import BrainbitsLogo from '../components/visuals/BrainbitsLogo';
import useOutsideClick from '../hooks/useOutsideClick';
import { useLayoutActions, useLayoutState } from './Context';
import SidebarNavigationContainer from './SidebarNavigationContainer';

const MENU_TRANSITION_OPEN = { type: 'spring', stiffness: 120, damping: 20 };
const MENU_TRANSITION_CLOSE = { type: 'spring', stiffness: 120, damping: 15 };

const sidebarVariants = {
    menuOpen: () => ({
        x: '0%',
        transition: MENU_TRANSITION_OPEN,
    }),
    menuClosed: () => ({
        x: '100%',
        transition: MENU_TRANSITION_CLOSE,
    }),
};

const NavigationSidebar = () => {
    const ref = useRef();
    const { menuOpen, isMediumScreen } = useLayoutState();
    const { closeMenu } = useLayoutActions();

    useOutsideClick(ref, closeMenu, isMediumScreen);

    return (
        <motion.div
            className={classnames(
                'bg-white fixed flex flex-col w-full sm:w-112 top-0 right-0 h-screen z-sidebar',
                {
                    'shadow-sidebar': menuOpen,
                },
            )}
            variants={sidebarVariants}
            animate={menuOpen ? 'menuOpen' : 'menuClosed'}
            initial={false}
            ref={ref}
        >
            <div className="flex items-center py-6 px-8 sm:px-12">
                <Link
                    to="/"
                    onClick={() => closeMenu()}
                    className="flex-1 block no-underline mr-auto"
                    aria-label="brainbits"
                >
                    <BrainbitsLogo className="w-36 max-w-full" />
                </Link>
                <MenuButton
                    className="ml-4"
                    close
                    variant="dark"
                    onClick={() => closeMenu()}
                />
            </div>
            <SidebarNavigationContainer />
        </motion.div>
    );
};

export default NavigationSidebar;
