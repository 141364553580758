import classNames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const ContainerContext = React.createContext(false);

const Container = ({
    children,
    outerClassName,
    className,
    outerStyle,
    style,
    canStack,
}) => {
    const inContainer = React.useContext(ContainerContext);
    const shouldBuildStyles = (!inContainer || (inContainer && canStack));

    return (
        <ContainerContext.Provider value>
            <div
                className={classNames(
                    {
                        'w-container': shouldBuildStyles,
                    },
                    outerClassName,
                )}
                style={outerStyle}
            >
                <div
                    className={classNames(
                        {
                            'w-container-in': shouldBuildStyles,
                        },
                        className,
                    )}
                    style={style}
                >
                    {children}
                </div>
            </div>
        </ContainerContext.Provider>
    );
};

Container.propTypes = {
    children: PropTypes.node,
    style: PropTypes.shape({}),
    outerStyle: PropTypes.shape({}),
    outerClassName: PropTypes.string,
    className: PropTypes.string,
    canStack: PropTypes.boolean,
};

Container.defaultProps = {
    children: null,
    style: null,
    outerStyle: null,
    outerClassName: null,
    className: null,
    canStack: false,
};

export default Container;
