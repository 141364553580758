/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import useDatoRouter from '../hooks/useDatoRouter';
import isInternalLink from '../util/isInternalLink';
import PropTypes from '../util/PropTypes';
import AnchorLink from './AnchorLink';
import { useLanguage } from './language/LanguageProvider.tsx';

const propTypes = {
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    children: PropTypes.node,
    partiallyActive: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

const defaultProps = {
    activeClassName: null,
    children: null,
    className: null,
    partiallyActive: false,
};

const FILE_LINK_PATTERN = /\.[0-9a-z]+$/i;
const UNDERLINE_CLASS_PATTERN = /(^|\s)underline-\S+/;
const DECORATION_CLASS_PATTERN = /(^|\s)decoration-((?!(slice|clone))\S)+/;

export const getLink = (props) => {
    if (props?.to && typeof props.to === 'string') {
        return {
            link: props.to,
            children: '',
        };
    }

    if (props && props?.link) {
        const externalLink = props?.link?.[0]?.externalLink;

        const internalLink = props?.link?.[0]?.internalLink?.permalink;

        return {
            link: externalLink || internalLink,
            children: props?.title || '',
        };
    }

    if (props?.[0] && props?.[0]?.link) {
        const externalLink = props?.[0]?.link?.[0]?.externalLink;

        const internalLink = props?.[0]?.link?.[0]?.internalLink?.permalink;

        return {
            link: externalLink || internalLink,
            children: props?.[0]?.title || '',
        };
    }

    return {
        link: '',
        children: 'Invalid Link',
    };
};

const Link = ({
    activeClassName,
    className,
    partiallyActive,
    to: declaredTo,
    children: declaredChildren,
    ...rest
}) => {
    const {
        link: to,
        children: altChildren,
    } = getLink({ to: declaredTo, ...rest });

    const children = declaredChildren || altChildren;

    const { language } = useLanguage();
    const classNames = classnames(
        className,
        {
            underline: !(className && className.includes('no-underline')),
            'decoration-font': !DECORATION_CLASS_PATTERN.test(className),
            'underline-widest': !UNDERLINE_CLASS_PATTERN.test(className),
        },
    );

    const linkWithoutHost = to.includes('brainbits.net') ? to.split('brainbits.net')[1] : to;

    const getRoute = useDatoRouter();

    if (!isInternalLink(to)) {
        return (
            <a href={to} target="_blank" rel="noopener noreferrer" className={classNames} {...rest}>
                {children}
            </a>
        );
    }

    if (FILE_LINK_PATTERN.test(to)) {
        return (
            <a href={to} className={classNames} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <AnchorLink
            to={getRoute(linkWithoutHost, language)}
            activeClassName={activeClassName}
            className={classNames}
            partiallyActive={partiallyActive}
            {...rest}
        >
            {children}
        </AnchorLink>
    );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;

export const query = graphql`
    fragment LinkInnerProps on DatoCmsInternalLink {
        internalLink: link {
            ... on DatoCmsPage {
                permalink
            }
            ... on DatoCmsBlogpost {
                permalink
            }
            ... on DatoCmsTrainingspage {
                permalink
            }
            ... on DatoCmsTrainingcategory {
                permalink
            }
            ... on DatoCmsBlogIndex {
                permalink
            }
            ... on DatoCmsTrainingIndex {
                permalink
            }
            ... on DatoCmsCaseStudy {
                permalink
            }
            ... on DatoCmsContactPage {
                permalink
            }
        }
    }

    fragment LinkWithTitleProps on DatoCmsLinkWithTitle {
        title
        link {
            ... on DatoCmsExternalLink {
                externalLink: link
            }
            ... on DatoCmsInternalLink {
                ...LinkInnerProps
            }
        }
    }

    fragment LinkWithoutTitleProps on DatoCmsLinkWithoutTitle {
        link {
            ... on DatoCmsExternalLink {
                externalLink: link
            }
            ... on DatoCmsInternalLink {
                ...LinkInnerProps
            }
        }
    }
`;
