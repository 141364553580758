import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';
import BulletIcon from './icons/BulletIcon';
import CheckIcon from './icons/CheckmarkIcon';

// eslint-disable-next-line react/prop-types
const ListIcon = ({ type, className }) => {
    switch (type) {
        case 'check':
            return <CheckIcon className={className} />;
        case 'bullet':
        default:
            return <BulletIcon className={className} />;
    }
};

const List = ({ children, className, color, type }) => (
    <ul className={className}>
        {React.Children.map(children, (item) => {
            if (React.isValidElement(item)) {
                return (
                    React.cloneElement(item, {
                        color: item.props.color || color,
                        type: item.props.type || type,
                    })
                );
            }
            return null;
        })}
    </ul>
);

List.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.oneOf(['check', 'bullet']),
    color: PropTypes.oneOf(['blue', 'white']),
};

List.defaultProps = {
    children: null,
    className: '',
    type: null,
    color: 'blue',
};

const ListItem = ({ children, className, color, type }) => (
    <li className={classnames(className, 'flex mt-4 first:mt-0')}>
        <ListIcon
            type={type}
            className={classnames(
                'mr-2.5 mt-1 h-6 w-6',
                {
                    'text-lightBlue-500': color === 'blue',
                    'text-white': color === 'white',
                },
            )}
        />
        <span className="flex-1">
            {children}
        </span>
    </li>
);

ListItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'white']),
    type: PropTypes.oneOf(['check', 'bullet']),
};

ListItem.defaultProps = {
    children: null,
    className: null,
    color: null,
    type: null,
};

List.Item = ListItem;

export default List;
