import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import DatoImage from '../DatoImage';
import Row from '../Row';

const ImageCollage = ({ image1, image2, image3 }) => (
    <Row cols={{ xs: 1, sm: 6, md: 6, lg: 6 }} noGap>
        <Col sm={{ span: 4, start: 2 }} className="sm:row-start-1 z-10">
            <DatoImage
                image={image1}
                className="h-64"
            />
        </Col>
        <Col sm={{ span: 3, start: 4 }} className="sm:row-start-1 sm:pt-32 sm:-mb-16 z-20 hidden md:block">
            <DatoImage
                image={image2}
                className="h-48"
            />
        </Col>
        <Col sm={{ span: 5, start: 1 }}>
            <DatoImage
                image={image3}
                className="h-64"
            />
        </Col>
    </Row>
);

ImageCollage.propTypes = {
    image1: PropTypes.datoImage,
    image2: PropTypes.datoImage,
    image3: PropTypes.datoImage,
};

ImageCollage.defaultProps = {
    image1: null,
    image2: null,
    image3: null,
};

export const query = graphql`
    fragment ImageCollageProps on DatoCmsImageCollage {
        originalId
        image1 {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
        image2 {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
        image3 {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
    }
`;

export default ImageCollage;
