import { useStaticQuery, graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import Button from '../components/Button';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';
import ChevronRightIcon from '../components/icons/ChevronRightIcon';
import { useLanguage } from '../components/language/LanguageProvider.tsx';
import { getLink } from '../components/Link';
import NavGroup from '../components/NavigationGroup';
import NavItemIcon from '../components/NavigationItemIcon';
import NavItemText from '../components/NavigationItemText';
import NavTab from '../components/NavigationTab';
import hierarchicalMenu, { hierarchicalMenuSelectChild } from '../util/hierarchical-menu.ts';
import PropTypes from '../util/PropTypes';
import { useLayoutActions, useLayoutState } from './Context';

const SidebarNavigationContainer = () => {
    const { menuOpen } = useLayoutState();
    const containerRef = useRef();
    const { language } = useLanguage();
    const { closeMenu } = useLayoutActions();

    const data = useStaticQuery(graphql`
        query {
            de: allDatoCmsNavigation(locale: "de") {
                nodes {
                    id
                    position
                    treeParent {
                        id
                    }
                    title
                    text
                    hideForLocale
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            en: allDatoCmsNavigation(locale: "en") {
                nodes {
                    id
                    position
                    treeParent {
                        id
                    }
                    title
                    text
                    hideForLocale
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
        }
    `);

    const [menuIdentifiers, setMenuIdentifiers] = useState(null);

    const menuLanguageSource = data[language].nodes;

    const activeMenu = menuIdentifiers?.id
        ? hierarchicalMenuSelectChild(menuLanguageSource, menuIdentifiers.id)
        : hierarchicalMenu(menuLanguageSource);

    const setActiveMenu = (title, id) => {
        setMenuIdentifiers({ title, id });
    };

    const pageUpMenu = () => {
        if (activeMenu.parent?.id) {
            const foundItem = hierarchicalMenuSelectChild(menuLanguageSource, activeMenu.parent?.id);
            if (foundItem) {
                setMenuIdentifiers({ title: foundItem.title, id: foundItem.id });
            }
            return;
        }
        setMenuIdentifiers(null);
    };

    React.useEffect(() => {
        if (!menuOpen) {
            setMenuIdentifiers(null);
        }
        containerRef.current.scrollTop = 0;
    }, [menuOpen, menuIdentifiers]);

    return (
        <nav className="flex-1 overflow-y-auto z-sidebar" ref={containerRef}>
            <div className="flex flex-col flex-grow justify-between h-full">
                <NavGroup as="ul" className="list-none flex flex-col items-center bg-white font-display">
                    {menuIdentifiers && (
                        <NavTab
                            key={menuIdentifiers?.title}
                            className="w-full"
                            onClick={pageUpMenu}
                            fadeInOut
                        >
                            <NavItemIcon>
                                <ChevronLeftIcon />
                            </NavItemIcon>
                            <NavItemText bold>
                                {menuIdentifiers?.title}
                            </NavItemText>
                        </NavTab>
                    )}
                    {activeMenu.childMenu.map((item, idx) => {
                        if (idx === activeMenu.childMenu.length - 1 && !menuIdentifiers) {
                            return (
                                <li className="py-6 px-8 sm:px-12 lg:px-5 xl:px-12 w-full">
                                    <Button onClick={closeMenu} to={getLink(item.link).link} variant="pill-dark">
                                        {item.title}
                                    </Button>
                                </li>
                            );
                        }
                        return (
                            <MenuItem
                                key={item.id}
                                item={item}
                                setActiveMenu={setActiveMenu}
                            />
                        );
                    })}
                </NavGroup>
            </div>
        </nav>
    );
};

export const MenuItem = ({ item, setActiveMenu }) => {
    const { closeMenu } = useLayoutActions();

    const { id, childMenu, title, text } = item;

    return (
        <React.Fragment key={id}>
            {childMenu.length > 0 ? (
                <NavTab
                    className="w-full"
                    fadeInOut
                    onClick={() => {
                        setActiveMenu(item.title, item?.id);
                    }}
                >
                    <div className="whitespace-normal w-full">
                        <NavItemText bold>
                            {title}
                        </NavItemText>
                        {text && (
                            <NavItemText className="mt-1 leading-7" small>
                                {text}
                            </NavItemText>
                        )}
                    </div>
                    <NavItemIcon>
                        <ChevronRightIcon />
                    </NavItemIcon>
                </NavTab>
            ) : (
                <NavTab
                    fadeInOut
                    className="w-full"
                    to={getLink(item.link).link}
                    onClick={() => {
                        closeMenu();
                    }}
                >
                    <div className="whitespace-normal w-full">
                        <NavItemText bold>
                            {title}
                        </NavItemText>
                        {text && (
                            <NavItemText className="mt-1 leading-7" small>
                                {text}
                            </NavItemText>
                        )}
                    </div>
                </NavTab>
            )}
        </React.Fragment>
    );
};

MenuItem.propTypes = {
    item: PropTypes.menuItem.isRequired,
    setActiveMenu: PropTypes.func.isRequired,
};

export default SidebarNavigationContainer;
