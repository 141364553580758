/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import FormRenderer from './FormRenderer';
import type { FormSchema } from './FormRenderer';
import CancelIcon from '../icons/CancelIcon';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import Button from '../Button';
import Link from '../Link';
import Spinner from '../Spinner';
import Select from '../Select';
import { useLanguage } from '../language/LanguageProvider';
import Row from '../Row';
import Col from '../Col';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from '../structured-text/CustomStructuredTextRenderer';

const DynamicForm = ({ schema }: { schema: FormSchema }): JSX.Element => {
    const { language } = useLanguage();

    const confirmationString = {
        en: 'Your message has been sent successfully, we\'ll be in touch soon.',
        de: 'Deine Nachricht wurde erfolgreich gesendet, wir melden uns umgehend.',
    }[language];

    const loadingString = {
        en: 'Your message is being sent',
        de: 'Deine Nachricht wird gesendet',
    }[language];

    return (
        <FormRenderer
            id={schema.originalId}
            schema={schema}
            renderForm={({ children }) => (
                <Row cols={2} noGap className="gap-x-4">
                    {children}
                </Row>
            )}
            renderField={({ description, ...field }) => {
                if (field.type === 'text' || field.type === 'email' || field.type === 'tel') {
                    return (
                        <Col xs={{ span: 2 }} lg={1}>
                            <div className="mb-4 relative">
                                <label htmlFor={field.id} className="sr-only">
                                    {field.label}
                                </label>
                                <input
                                    {...field}
                                    autoComplete={field.autoComplete}
                                    placeholder={field.placeholder}
                                    aria-required={field.required}
                                    aria-errormessage={field.hasErrors ? field.errors.join(', ') : undefined}
                                    aria-invalid={field?.invalid}
                                    className={classnames(
                                        'text-base font-semibold placeholder:font-normal tracking-wider placeholder:text-gray-900 outline-none h-16 py-4 pl-4 pr-12 border w-full transition-all',
                                        {
                                            'border-gray-200 focus:shadow-input focus:border-lightBlue-500': !field.hasErrors && !field.touched,
                                            'border-red-600 focus:shadow-inputInvalid': field.hasErrors,
                                            'border-gray-200 focus:shadow-inputValid focus:border-green-500': !field.hasErrors && field.touched,
                                        },
                                    )}
                                />
                                {field.touched && !field.hasErrors && (
                                    <CheckmarkIcon className="text-lightBlue-500 h-7 w-7 absolute mx-3 my-5 top-0 right-0" />
                                )}
                                {field.touched && field.hasErrors && (
                                    <CancelIcon className="text-red-600 h-6 w-6 absolute mx-3 my-5 top-0 right-0" />
                                )}
                                {field.hasErrors && (
                                    <div className="my-4 rounded border px-5 py-3 text-red-900 bg-red-100 border-red-200" role="alert">{field.errors.join(', ')}</div>
                                )}
                            </div>
                        </Col>
                    );
                }
                if (field.type === 'textarea') {
                    return (
                        <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                            <div className="mb-4 relative">
                                <label htmlFor={field.id} className="sr-only">
                                    {field.label}
                                </label>
                                <textarea
                                    placeholder={field.placeholder}
                                    aria-required={field.required}
                                    aria-errormessage={field.hasErrors ? field.errors.join(', ') : undefined}
                                    aria-invalid={field?.invalid}
                                    {...field}
                                    className={classnames(
                                        'text-base min-h-[170px] font-semibold placeholder:font-normal tracking-wider placeholder:text-gray-900 outline-none py-4 pl-4 pr-12 border w-full transition-all',
                                        {
                                            'border-gray-200 focus:shadow-input focus:border-lightBlue-500': !field.hasErrors && !field.touched,
                                            'border-red-600 focus:shadow-inputInvalid': field.hasErrors && field.touched,
                                            'border-gray-200 focus:shadow-inputValid focus:border-green-500': field.touched && !field.hasErrors,
                                        },
                                    )}
                                />
                                {field.touched && !field.hasErrors && (
                                    <CheckmarkIcon className="text-lightBlue-500 h-7 w-7 absolute mx-3 my-5 top-0 right-0" />
                                )}
                                {field.hasErrors && field.touched && (
                                    <CancelIcon className="text-red-600 h-6 w-6 absolute mx-3 my-5 top-0 right-0" />
                                )}
                                {field.hasErrors && (
                                    <div className="my-4 rounded border px-5 py-3 text-red-900 bg-red-100 border-red-200" role="alert">{field.errors.join(', ')}</div>
                                )}
                            </div>
                        </Col>
                    );
                }
                if (field.type === 'select') {
                    return (
                        <Col xs={{ span: 2 }} lg={1}>
                            <div className="mb-4 relative">
                                <label htmlFor={field.id} className="sr-only">
                                    {field.label}
                                </label>
                                <Select
                                    {...field}
                                    aria-required={field.required}
                                    aria-errormessage={field.hasErrors ? field.errors.join(', ') : undefined}
                                    aria-invalid={field?.invalid}
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    options={field.options}
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    label={field.placeholder}
                                />
                                {field.hasErrors && (
                                    <div className="my-4 rounded border px-5 py-3 text-red-900 bg-red-100 border-red-200" role="alert">{field.errors.join(', ')}</div>
                                )}
                            </div>
                        </Col>
                    );
                }

                if (field.type === 'checkbox') {
                    return (
                        <Col xs={{ span: 2 }} lg={1}>
                            <div className="mb-4">
                                <div className="relative pl-9">
                                    <label
                                        htmlFor={field.id}
                                        className={classnames(
                                            'text-sm font-normal tracking-wider',
                                            {
                                                'text-gray-900': !field.hasErrors,
                                                'text-red-600': field.hasErrors,
                                            },
                                        )}
                                    >
                                        <input
                                            {...field}
                                            aria-required={field.required}
                                            aria-errormessage={field.hasErrors ? field.errors.join(', ') : undefined}
                                            aria-invalid={field?.invalid}
                                            className="cursor-pointer opacity-0 absolute top-0 left-0 m-0 z-10 w-7 h-7 peer"
                                        />
                                        <div
                                            className={classnames(
                                                'bg-white border h-6 w-6 absolute left-0 top-1 visible peer-checked:invisible',
                                                {
                                                    'border-gray-100': !field.hasErrors,
                                                    'border-red-600': field.hasErrors,
                                                },
                                            )}
                                        />
                                        <div className="bg-white border border-gray-100 h-6 w-6 absolute top-1 left-0 invisible peer-checked:visible">
                                            <CheckmarkIcon className="h-4 w-4 absolute top-1 left-0.5 text-lightBlue-900" />
                                        </div>
                                        <div>
                                            <CustomStructuredTextRenderer data={description} />
                                        </div>
                                    </label>
                                    {field.hasErrors && (
                                        <div className="my-4 rounded border px-5 py-3 text-red-900 bg-red-100 border-red-200" role="alert">{field.errors.join(', ')}</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    );
                }
                return (
                    <div>Missing Field</div>
                );
            }}
            renderApiErrors={({ errors }) => (
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                    <div className="my-4 rounded border px-5 py-3 text-red-900 bg-red-100 border-red-200" role="alert">{errors.join(', ')}</div>
                </Col>
            )}
            renderSubmitButton={({ children, disabled }) => (
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                    <Button variant="cta" submit disabled={disabled}>
                        {children}
                    </Button>
                </Col>
            )}
            renderLoadingSpinner={() => (
                <div className="inline-flex items-center gap-x-2 px-2">
                    {loadingString}
                    {' '}
                    <Spinner alt="" />
                </div>
            )}
            renderConfirmationMessage={() => (
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                    <div className="my-4 rounded border px-5 py-3 text-green-900 bg-green-100 border-green-200" role="alert">
                        {confirmationString}
                    </div>
                </Col>
            )}
            renderLegalMessage={() => (
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                    {language === 'en' && (
                        <p className="mb-4 text-sm opacity-70">
                            The fields marked with an (*) are mandatory and must be completed. Under
                            {' '}
                            <Link to="/en/datenschutz/">
                                data protection
                            </Link>
                            {' '}
                            you will find our information in accordance with Art. 13 GDPR on data protection in
                            connection with our contact form.
                        </p>
                    )}
                    {language === 'de' && (
                        <p className="mb-4 text-sm opacity-70">
                            Die mit einem (*) gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.
                            Unter
                            {' '}
                            <Link to="/datenschutz/">
                                Datenschutz
                            </Link>
                            {' '}
                            findest Du unsere Informationen gemäß Art. 13 DSGVO über den Datenschutz im Zusammenhang mit
                            unserem Kontaktformular.
                        </p>
                    )}
                </Col>
            )}
        />
    );
};

export const query = graphql`
    fragment DynamicForm on DatoCmsDynamicForm {
        id
        originalId
        title
        submitButtonText
        formFields {
            __typename
            ... on DatoCmsInput {
                id
                autocomplete
                inputType
                label
                placeholder
                required
            }
            ... on DatoCmsSelect {
                id
                required
                placeholder
                label
                display
                options {
                    option
                }
            }
            ... on DatoCmsCheckbox {
                id
                required
                label
                description {
                    blocks
                    links
                    value
                }
            }
            ... on DatoCmsTextarea {
                id
                required
                label
                placeholder
            }
        }
    }
`;

export default DynamicForm;
