import classnames from 'classnames';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Typography from '../Typography';

const Paragraph = ({ children, className }) => (
    <Typography variant="body" className={classnames('my-4 first:mt-0', className)}>
        {children}
    </Typography>
);

Paragraph.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Paragraph.defaultProps = {
    children: null,
    className: '',
};

export default Paragraph;
