import classnames from 'classnames';
import React, { forwardRef } from 'react';
import PropTypes from '../util/PropTypes';
import AngleDownIcon from './icons/AngleDownIcon';
import CancelIcon from './icons/CancelIcon';

const propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    label: PropTypes.string.isRequired,
    large: PropTypes.bool,
    name: PropTypes.string,
    noBorder: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
    required: PropTypes.bool,
    touched: PropTypes.bool,
    value: PropTypes.string,
};

const defaultProps = {
    disabled: false,
    error: false,
    large: false,
    name: null,
    noBorder: false,
    onBlur: null,
    onChange: null,
    required: false,
    touched: false,
    value: null,
};

const Select = forwardRef(({
    disabled,
    error,
    label,
    large,
    name,
    noBorder,
    onBlur,
    onChange,
    options,
    required,
    touched,
    value,
}, ref) => (
    <div className="relative">
        <select
            ref={ref}
            aria-invalid={error}
            aria-label={label}
            aria-required={required}
            disabled={disabled}
            name={name}
            className={classnames(
                'block appearance-none text-base py-4 pl-4 pr-12 w-full tracking-wider outline-none focus:shadow-input cursor-pointer',
                {
                    'border-0': noBorder,
                    'h-16': !large,
                    'h-20': large,
                },
            )}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        >
            <option value="" disabled>{label}</option>
            {options.map(option => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
        {!error && (
            <AngleDownIcon
                className={classnames('absolute h-6 w-6 right-4', {
                    'top-7': large,
                    'top-5': !large,
                })}
            />
        )}
        {touched && error && (
            <CancelIcon
                className={classnames('text-red-600 absolute h-6 w-6 right-4', {
                    'top-7': large,
                    'top-5': !large,
                })}
            />
        )}
    </div>
));

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
