import React from 'react';
import { useDebug } from './DebugProvider';
import Row from '../Row';
import Col from '../Col';
import Container from '../Container';

const GridLinesDebug = (): JSX.Element | null => {
    const { showGridLines } = useDebug();

    if (showGridLines) {
        return (
            <div className="fixed pointer-events-none h-screen top-0 left-0 z-[100] flex page-container">
                <Container className="pb-4 md:pb-6 lg:pb-8 flex items-center justify-center">
                    <Row cols={{ xs: 4, md: 12 }} className="h-screen w-full">
                        {[...Array.from(new Array(12))].map(() => (
                            <Col className="bg-gray-100 opacity-30" xs={{ span: 1 }} md={{ span: 1 }} />
                        ))}
                    </Row>
                </Container>
            </div>
        );
    }

    return null;
};

export default GridLinesDebug;
