import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
import Typography from '../Typography';

const ComparisonTable = ({
    title,
    text,
    backgroundColor,
    items,
    conclusion,
    linkTitle,
    link,
}) => (
    <div style={{ backgroundColor: backgroundColor?.hex || '#FFFFFF' }} className="w-full">
        <Container>
            <div className="py-12 lg:py-20 text-center w-full">
                {(title || text) && (
                    <div className="max-w-4xl mx-auto">
                        {title && (
                            <Typography variant="heading2" className="pb-8">
                                {title}
                            </Typography>
                        )}
                        {text && (
                            <Typography variant="body" className="pb-8 lg:pb-20">
                                {text}
                            </Typography>
                        )}
                    </div>
                )}
                <Row cols={{ xs: 12, lg: 12 }} className="space-y-6 lg:space-y-0">
                    {items && items.map(item => (
                        <Col xs={12} lg={4} className="border-gray-100 bg-white border pt-14 pb-2">
                            <div className="flex flex-col items-center justify-center px-6">
                                <div className="relative inline-block px-2">
                                    <div className="absolute w-full h-4 bottom-0 left-0" style={{ backgroundColor: item.underlineColor?.hex || '#FFFFFF' }} />
                                    <Typography variant="heading3" className="relative">{item.title}</Typography>
                                </div>
                                {item.price && (
                                    <Typography variant="heading4" className="pt-9">
                                        {item.price}
                                    </Typography>
                                )}
                                {item.priceDetails && (
                                    <ul className="pt-4">
                                        {item.priceDetails.map(detail => (
                                            <Typography variant="small" as="li">
                                                {detail.text}
                                            </Typography>
                                        ))}
                                    </ul>
                                )}
                                {item.featureHeading && (
                                    <Typography variant="small" className="pt-10">
                                        {item.featureHeading}
                                    </Typography>
                                )}
                                {item.features && (
                                    <ul className="pt-6 w-full">
                                        {item.features.map(feature => (
                                            <li className="border-t border-gray-100 py-5 w-full">
                                                {feature.title && (
                                                    <Typography variant="introBold">
                                                        {feature.title}
                                                    </Typography>
                                                )}
                                                {feature.text && (
                                                    <Typography>
                                                        {feature.text}
                                                    </Typography>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
                {conclusion && (
                    <Typography variant="intro" className="pt-10 lg:pt-16">
                        {conclusion}
                    </Typography>
                )}
                {(linkTitle && link) && (
                    <Button className="mt-8" to={link} variant="cta">
                        {linkTitle}
                    </Button>
                )}
            </div>
        </Container>
    </div>
);

ComparisonTable.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }),
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        underlineColor: PropTypes.shape({ hex: PropTypes.string }),
        price: PropTypes.string,
        priceDetails: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
        })),
        featureHeading: PropTypes.string,
        features: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
        })),
    })),
    conclusion: PropTypes.string,
    linkTitle: PropTypes.string,
    link: PropTypes.string,
};

ComparisonTable.defaultProps = {
    title: null,
    text: null,
    backgroundColor: null,
    items: [],
    conclusion: null,
    linkTitle: null,
    link: null,
};

export const query = graphql`
    fragment ComparisonTableProps on DatoCmsComparisonTable {
        originalId
        title
        text
        linkTitle
        link
        backgroundColor {
            hex
        }
        items {
            title
            underlineColor {
                hex
            }
            price
            priceDetails {
                text
            }
            featureHeading
            features {
                title
                text
            }
        }
        conclusion
    }
`;

export default ComparisonTable;
