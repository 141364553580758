import React, { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { PageContext } from 'gatsby/internal';
import useDatoRouter from '../../hooks/useDatoRouter';

type LanguageMenuItem = { langKey: string, selected: boolean, link: string, isRealPath?: boolean };

interface LanguageContext {
    language: string
    languageMenu: LanguageMenuItem[]
    setChosenLanguage: (language: string) => void
}

const LanguageContext = createContext<LanguageContext>({} as never);

interface LanguageProvider {
    location: Location
    children: React.ReactNode
    pageContext: PageContext
}

const LanguageProvider = ({ location, children, pageContext }: LanguageProvider): JSX.Element => {
    const getRoute = useDatoRouter();
    const {
        site,
    } = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    languages {
                        defaultLangKey
                        langs
                    }
                }
            }
        }
    `);

    const initialLanguage = pageContext.lang || (location.pathname.startsWith('/en') ? 'en' : 'de');

    /*
    * Save the entry point language to state, which can be updated later by the user manually.
    * This will allow us to keep the language switcher showing their preferred language, even if
    * they access untranslated pages, and they can manually switch/bounce back to their preferred
    * language when visiting other (translated) pages.
    * */
    const [language, setLanguage] = React.useState(initialLanguage);
    const { langs } = site.siteMetadata.languages;
    const languageMenu: LanguageMenuItem[] = langs.map((item: string) => {
        const foundRoute = getRoute(
            location.pathname.replace(`/${initialLanguage === 'de' ? '' : initialLanguage as string}`, ''),
            item,
            true,
        );
        return {
            langKey: item,
            selected: language === item,
            link: foundRoute || `/${item === 'de' ? '' : item}`,
            isRealPath: !!foundRoute,
        };
    });

    const setChosenLanguage = (language: string): void => {
        try {
            localStorage.setItem('lang', language);
            // eslint-disable-next-line no-empty
        } catch (e) {
            // ignore non-working localstorage
        }
        setLanguage(language);
    };

    const value = useMemo(() => ({
        languageMenu,
        language,
        setChosenLanguage,
    }), [language, languageMenu]);

    return (
        <LanguageContext.Provider value={value}>
            <Helmet>
                <link
                    rel="alternate"
                    hrefLang={initialLanguage}
                    href={`https://www.brainbits.net${location.pathname}`}
                />
                {languageMenu.filter(x => x.isRealPath).map(alternate => (
                    <link
                        rel="alternate"
                        hrefLang={alternate.langKey}
                        href={`https://www.brainbits.net${alternate.link}`}
                    />
                ))}
                {languageMenu.filter(x => x.isRealPath && x.langKey === 'de').map(alternate => (
                    <link
                        rel="alternate"
                        hrefLang="x-default"
                        href={`https://www.brainbits.net${alternate.link}`}
                    />
                ))}
            </Helmet>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;

export const useLanguage = (): LanguageContext => {
    const ctx = useContext(LanguageContext);
    if (!ctx) {
        throw new Error('useLanguage is missing parent LanguageProvider.');
    }
    return ctx;
};
