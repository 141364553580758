import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Box from '../Box';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';

const TeaserItemSet = ({
    title,
    text,
    items,
}) => (
    <div>
        <div className="text-center">
            <Typography variant="heading2" paragraph>
                {title}
            </Typography>
            <Typography variant="intro" paragraph>
                {text}
            </Typography>
        </div>
        <div className="pt-20">
            <Container outerClassName="bg-gray-50" className="pb-10 sm:pb-20 mt-36">
                <Row cols={{ sm: 1, md: 1, lg: 2 }} className="gap-y-20 lg:gap-y-8">
                    {items.map((item, idx) => (
                        <Col className={classNames({
                            '-mt-36': idx === 0,
                            'lg:-mt-36': idx === 1,
                        })}
                        >
                            <Box className={classNames(
                                'py-8 px-4 lg:px-10 text-center max-w-[430px] mx-auto flex flex-col justify-between h-full',
                                {
                                    'lg:mr-0': idx % 2 === 0,
                                    'lg:ml-0': idx % 2 === 1,
                                },
                            )}
                            >
                                <div>
                                    <DatoImage image={item.image} className="h-36 mx-auto" />
                                    <Typography variant="heading4" as="h3" className="mt-4" paragraph>
                                        {item.title}
                                    </Typography>
                                    <Typography paragraph>
                                        {item.text}
                                    </Typography>
                                </div>
                                <div>
                                    <Button to={item.link}>
                                        {item.linkTitle}
                                    </Button>
                                </div>
                            </Box>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    </div>
);

TeaserItemSet.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.datoImage.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        linkTitle: PropTypes.string.isRequired,
    })),

};

TeaserItemSet.defaultProps = {
    items: [],
};

export const query = graphql`
    fragment TeaserItemSetProps on DatoCmsTeaserItemSet {
        originalId
        title
        text
        items {
            image {
                gatsbyImageData
                alt
                url
                smartTags
            }
            title
            text
            link
            linkTitle
        }
    }
`;

export default TeaserItemSet;
