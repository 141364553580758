import React from 'react';
import PropTypes from '../../util/PropTypes';
import Link from '../Link';

/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
const InlineLink = ({ href, ...props }) => (
    <Link to={href} {...props} />
);

InlineLink.propTypes = {
    href: PropTypes.string,
};

InlineLink.defaultProps = {
    href: '',
};

InlineLink.shouldRenderHalfMargin = () => true;

export default InlineLink;
