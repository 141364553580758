import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const sizeClassNames = {
    regular: 'text-xl',
    large: 'text-xl md:text-4xl lg:text-6xl',
};

const colorClassNames = {
    black: 'bg-gray-900',
    blue: 'bg-lightBlue-500',
};

const HighlightLabel = ({ children, className, size, color, as: Component }) => (
    <Component className="flex flex-col items-start">
        {React.Children.map(children, (node, i) => (
            node.type !== 'br' && (
                <span className={classnames(
                    'block py-1 shadow-2xl pr-2.5 pl-2 font-display font-medium leading-normal sm:leading-normal md:leading-normal lg:leading-normal text-white tracking-[0.5px] uppercase origin-top-left skew-y-[-9deg]',
                    className,
                    sizeClassNames[size],
                    colorClassNames[color],
                    {
                        '-mt-px': i !== 0,
                    },
                )}
                >
                    {node}
                </span>
            )
        ))}
    </Component>
);

HighlightLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.oneOf(['regular', 'large']),
    color: PropTypes.oneOf(['black', 'blue']),
    as: PropTypes.headingTag,
};

HighlightLabel.defaultProps = {
    children: null,
    className: '',
    size: 'regular',
    color: 'black',
    as: 'h2',
};

export default HighlightLabel;
