import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Container from '../Container';
import PersonCard from '../PersonCard';

const InlinePersonCard = ({ contactPerson }) => (
    <Container>
        <PersonCard data={contactPerson} />
    </Container>
);

InlinePersonCard.propTypes = {
    contactPerson: PropTypes.personCardData,
};

InlinePersonCard.defaultProps = {
    contactPerson: null,
};

export const query = graphql`
    fragment PersonCardProps on DatoCmsPersonCard {
        originalId
        contactPerson {
            ...PersonCardData
        }
    }
`;

export default InlinePersonCard;
