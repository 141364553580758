import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';
import warning from '../util/warning';
import ArrowRightIcon from './icons/ArrowRightIcon';
import ArrowRightLongIcon from './icons/ArrowRightLongIcon';
import ArrowRightShortIcon from './icons/ArrowRightShortIcon';
import Link, { getLink } from './Link';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'cta', 'link', 'pill', 'pill-dark', 'icon']),
    submit: PropTypes.bool,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    to: PropTypes.string,
    disabled: PropTypes.bool,
};

const defaultProps = {
    children: null,
    className: null,
    variant: 'primary',
    submit: false,
    onClick: null,
    active: false,
    to: null,
    disabled: false,
};

const Button = ({
    className,
    onClick,
    submit,
    variant,
    active,
    to: declaredTo,
    children: declaredChildren,
    disabled,
    ...rest
}) => {
    warning(
        !(submit && onClick),
        'You provide onClick to a submit button. Please remove onClick handler or submit attribute',
    );

    const {
        link: to,
        children: altChildren,
    } = getLink({ to: declaredTo, ...rest });

    const children = declaredChildren || altChildren;

    const buttonClassName = classnames(
        'group select-none border cursor-pointer inline-block font-display leading-6 font-bold tracking-[2px] align-middle uppercase',
        {
            'text-base': (variant !== 'pill' || variant !== 'pill-dark'),
            'text-sm': (variant === 'pill' || variant === 'pill-dark'),
            'relative no-underline z-10 outline-none focus:outline-none hover:outline-none active:outline-none px-2 py-[3px] border-transparent': variant === 'primary',
            'flex items-center border-transparent': variant === 'secondary',
            'bg-yellow-300 rounded-full relative transition-all duration-100 ease-linear py-2 px-4 hover:pr-9 border-transparent': variant === 'cta',
            'border border-gray-900 text-inherit inline font-inherit tracking-inherit m-0 pb-[2px] no-underline focus:outline-none': variant === 'link',
            'relative uppercase px-4 transition duration-200 w-full text-center lg:w-auto rounded-sm py-3': (variant === 'pill' || variant === 'pill-dark'),
            'hover:bg-lightBlue-500 hover:border-lightBlue-500 hover:text-white': variant === 'pill-dark',
            'hover:border-lightBlue-500 hover:text-lightBlue-500': variant === 'pill',
            'p-0 border-transparent': variant === 'icon',
            'bg-lightBlue-500 text-white border-lightBlue-500': active && variant === 'pill',
            'border-gray-900 active:bg-lightBlue-500 active:border-lightBlue-500 active:text-white': !active && variant === 'pill',
            'bg-gray-900 text-white border-gray-900 active:bg-lightBlue-500 active:border-lightBlue-500 active:text-white': !active && variant === 'pill-dark',
        },
        className,
    );

    const buttonChildren = (
        <>
            {variant === 'primary' && (
                <span className="block transition-transform bg-yellow-300 rounded-[5px/50%] scale-y-50 origin-[50%_100%] absolute bottom-0 left-0 right-0 h-full z-[-1] group-hover:scale-y-100" />
            )}
            {children}
            {variant === 'cta' && (
                <ArrowRightShortIcon className="text-gray-900 h-6 w-6 absolute top-[50%] transition-opacity duration-100 ease-linear opacity-0 group-hover:opacity-100 right-2 -mt-3" />
            )}
            {variant === 'secondary' && (
                <>
                    <ArrowRightIcon className="text-gray-900 w-[18px] ml-[6px] inline-block group-hover:hidden" />
                    <ArrowRightLongIcon className="text-gray-900 w-[22px] ml-[6px] hidden group-hover:inline-block " />
                </>
            )}
        </>
    );

    if (submit) {
        return (
            <button type="submit" disabled={disabled} className={buttonClassName}>
                {buttonChildren}
            </button>
        );
    }

    if (to) {
        return (
            <Link to={to} onClick={onClick} className={classnames('no-underline', buttonClassName)} {...rest}>
                {buttonChildren}
            </Link>
        );
    }

    return (
        <button type="button" onClick={onClick} disabled={disabled} className={buttonClassName}>
            {buttonChildren}
        </button>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
