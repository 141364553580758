import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import DatoImage from '../DatoImage';
import EmailLink from '../EmailLink';
import { useLanguage } from '../language/LanguageProvider.tsx';
import TelephoneLink from '../TelephoneLink';
import Typography from '../Typography';

const propTypes = {
    contactPerson: PropTypes.personCardData,
};

const defaultProps = {
    contactPerson: null,
};

const ContactPersonCard = ({ contactPerson }) => {
    const { language } = useLanguage();
    const { datoCmsCompanyContact: { salesPhone, salesMail } } = useStaticQuery(graphql`
        {
            datoCmsCompanyContact {
                salesPhone
                salesMail
            }
        }
    `);

    const title = {
        de: 'Deine Ansprechperson',
        en: 'Your Contact Person',
    }[language];

    const personData = contactPerson || {};

    return (
        <div className="w-full">
            <DatoImage
                image={personData.squarePhoto}
            />
            <Typography className="mt-4">
                {title}
            </Typography>
            <Typography variant="body" paragraph>
                {`${personData.name}, ${personData.jobPosition}`}
            </Typography>
            <Typography variant="heading5" as="p">
                <TelephoneLink>
                    {salesPhone}
                </TelephoneLink>
            </Typography>
            <Typography variant="heading5" as="p" paragraph className="flex items-center">
                <EmailLink>
                    {salesMail}
                </EmailLink>
            </Typography>
        </div>
    );
};

ContactPersonCard.propTypes = propTypes;
ContactPersonCard.defaultProps = defaultProps;

export const query = graphql`
    fragment ContactPersonCardProps on DatoCmsContactPersonCard {
        originalId
        contactPerson {
            name
            jobPosition
            originalId
            squarePhoto: photo {
                gatsbyImageData(imgixParams: {
                    ar: "43:33",
                    fit: "crop",
                    auto: "format",
                    q: 100,
                    crop: "focalpoint",
                    fpX: 0.5,
                    fpY: 0.5
                })
                url
                alt
                smartTags
            }
        }
    }
`;

export default ContactPersonCard;
