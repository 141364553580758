import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import { useLanguage } from '../language/LanguageProvider.tsx';
import Link from '../Link';
import Row from '../Row';
import Typography from '../Typography';

const TrainingSet = ({ logo, title, trainings }) => {
    const { language } = useLanguage();

    if (!trainings || trainings.length === 0) {
        return null;
    }

    const formatLabels = {
        de: {
            training_package: 'Trainingspaket',
            training_with_exercises: 'Training mit Übungen',
            training: 'Training',
            presentation: 'Präsentation',
        },
        en: {
            training_package: 'Training Package',
            training_with_exercises: 'Training with Exercises',
            training: 'Training',
            presentation: 'Presentation',
        },
    }[language];

    const targetGroupLabels = {
        de: {
            beginner: 'Einsteigende',
            advanced: 'Fortgeschrittene',
            beginner_and_advanced: 'Einsteigende & Fortgeschrittene',
        },
        en: {
            beginner: 'Beginner',
            advanced: 'Advanced',
            beginner_and_advanced: 'Beginner & Advanced',
        },
    }[language];

    return (
        <Container>
            <DatoImage image={logo} className="h-12 lg:hidden" objectFit="contain" objectPosition="left center" />
            <Row cols={{ sm: 1, md: 1, lg: 12 }} className="mt-8">
                <Col lg={{ span: 5, start: 2 }}>
                    <Typography variant="heading3" as="h2">
                        {title}
                    </Typography>
                </Col>
                <Col lg={{ span: 5, start: 7 }} className="hidden lg:flex justify-end">
                    <DatoImage image={logo} className="h-12" objectFit="contain" objectPosition="left center" />
                </Col>
            </Row>
            <Row cols={{ sm: 1, md: 1, lg: 12 }} className="mt-6">
                <Col lg={{ span: 10, start: 2 }} className="mb-6">
                    {trainings.map(training => (
                        <Link to={`${training.slug}`} className="flex flex-col sm:flex-row sm:items-center leading-none border-t-2 no-underline hover:no-underline hover:bg-gray-50 transition-background duration-300 focus:outline-none focus:text-lightBlue-500 py-4 group">
                            <div className="flex-1 group-hover:translate-x-3 transition-transform duration-300">
                                <Typography variant="small" className="uppercase">
                                    {formatLabels[training.format] || training.format}
                                </Typography>
                                <Typography variant="intro" className="my-1">
                                    {training.title}
                                </Typography>
                                <Typography variant="small">
                                    {targetGroupLabels[training.level] || training.level}
                                    {' '}
                                    |
                                    {' '}
                                    {training.duration}
                                    {' '}
                                    |  remote & inhouse
                                </Typography>
                            </div>
                            <div className="mt-4 sm:mt-0">
                                <span className="inline-flex pr-4 items-center">
                                    <Typography variant="heading5" as="span">Details</Typography>
                                    <ArrowRightIcon className="text-gray-900 w-[18px] ml-2 inline-block" />
                                </span>
                            </div>
                        </Link>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

TrainingSet.propTypes = {
    logo: PropTypes.datoImage,
    title: PropTypes.string,
    trainings: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        duration: PropTypes.string,
        format: PropTypes.shape({}),
        targetGroup: PropTypes.shape({}),
        slug: PropTypes.string,
    })),
};

TrainingSet.defaultProps = {
    logo: null,
    title: '',
    trainings: [],
};

export const query = graphql`
    fragment TrainingSetProps on DatoCmsTrainingSet {
        originalId
        title
        logo {
            gatsbyImageData(
                imgixParams: { fit: "crop", auto: "format" }
            )
            alt
            url
            smartTags
        }
        trainings {
            title
            duration
            format
            targetGroup
            level
            slug
        }
    }
`;

export default TrainingSet;
