import { graphql, useStaticQuery } from 'gatsby';

const useDatoRouter = () => {
    const data = useStaticQuery(graphql`
        {
            contact: allDatoCmsContactPage {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            index: allDatoCmsIndex {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            blogIndex: allDatoCmsBlogIndex {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            trainingIndex: allDatoCmsTrainingIndex {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            blogPosts: allDatoCmsBlogpost {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            pages: allDatoCmsPage {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            caseStudies: allDatoCmsCaseStudy {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            trainings: allDatoCmsTrainingspage {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
            trainingCategories: allDatoCmsTrainingcategory {
                nodes {
                    permalink
                    locales
                    originalId
                }
            }
        }
    `);

    const allAvailableRoutes = Object.fromEntries(Object.values(data).reduce((acc, val) => [
        ...acc,
        ...val.nodes.map(x => [x.permalink.split('/').filter(y => !!y).join('/'), { permalink: x.permalink, locales: x.locales }]),
        ...val.nodes.map(x => [x.originalId, { permalink: x.permalink, locales: x.locales }]),
    ], []));

    const getRoute = (linkOrId, language, prescriptive = false) => {
        const matchedRoute = allAvailableRoutes[linkOrId.replace(`/${language === 'de' ? '' : language}`, '').split('/').filter(x => !!x).join('/')];

        if (matchedRoute) {
            const foundWithLocale = matchedRoute.locales.includes(language);
            if (foundWithLocale) {
                return `${language === 'de' ? '' : `/${language}`}${matchedRoute.permalink === '/' ? '' : matchedRoute.permalink}` || '/';
            }
            if (!prescriptive) {
                return matchedRoute.permalink;
            }
        }
        if (prescriptive) {
            return null;
        }
        return linkOrId;
    };

    return getRoute;
};

export default useDatoRouter;
