import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
// eslint-disable-next-line import/no-cycle
import DynamicForm from '../dynamic-forms/index.tsx';

const InlineForm = ({ form }) => {
    if (!form) {
        return null;
    }
    return (
        <DynamicForm id="kontakt" schema={form} />
    );
};

InlineForm.propTypes = {
    form: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        submitButtonText: PropTypes.string.isRequired,
        formFields: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            autocomplete: PropTypes.string,
            inputType: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            required: PropTypes.boolean,
            display: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.shape({
                option: PropTypes.string.isRequired,
            })),
        }),
    }).isRequired,
};

InlineForm.defaultProps = {};

export const query = graphql`
    fragment InlineFormProps on DatoCmsForm {
        originalId
        form {
            ...DynamicForm
        }
    }
`;

export default InlineForm;
