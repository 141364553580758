import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import preventDefault from '../util/preventDefault';
import PropTypes from '../util/PropTypes';
import whenKey from '../util/whenKey';
import PlusIcon from './icons/PlusIcon';

const propTypes = {
    defaultExpanded: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    defaultExpanded: false,
    children: null,
};

const ExpansionContext = createContext();

const ExpansionPanel = ({ children, defaultExpanded }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded, setExpanded]);

    const hasDetailComponent = React.Children.map(children, child => child).filter(x => !!x).length >= 2;

    const value = useMemo(() => ({
        expanded,
        toggleExpanded,
        hasDetailComponent,
    }), [
        expanded,
        toggleExpanded,
        hasDetailComponent,
    ]);

    return (
        <div className="overflow-hidden">
            <ExpansionContext.Provider value={value}>
                {children}
            </ExpansionContext.Provider>
        </div>
    );
};

ExpansionPanel.propTypes = propTypes;
ExpansionPanel.defaultProps = defaultProps;

const ExpansionPanelSummary = ({ children }) => {
    const { expanded, toggleExpanded, hasDetailComponent } = useContext(ExpansionContext);

    return (
        <div
            className={classnames(
                'flex items-center border-t border-gray-100',
                {
                    'cursor-pointer focus:outline-none': hasDetailComponent,
                },
            )}
            onClick={toggleExpanded}
            onKeyDown={whenKey(' ', preventDefault(toggleExpanded))}
            role="tab"
            tabIndex="0"
        >
            <div className="flex-1 py-6 pr-6">
                {children}
            </div>
            {hasDetailComponent && (
                <button
                    type="button"
                    className={classnames(
                        'h-6 w-6 m-5 focus:outline-none transition-all',
                        {
                            'rotate-[-135deg]': expanded,
                        },
                    )}
                >
                    <PlusIcon color="#3A3A3A" />
                    <span className="sr-only">
                        {expanded ? 'zuklappen' : 'aufklappen'}
                    </span>
                </button>
            )}
        </div>
    );
};

ExpansionPanelSummary.propTypes = {
    children: PropTypes.node,
};

ExpansionPanelSummary.defaultProps = {
    children: null,
};

const ExpansionPanelDetail = ({ children }) => {
    const { expanded } = useContext(ExpansionContext);

    return (
        <AnimatePresence initial={false}>
            { expanded && (
                <motion.div
                    transition={{
                        type: 'spring',
                        damping: 30,
                        stiffness: 200,
                    }}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: 'auto' },
                        collapsed: { opacity: 0, height: 0 },
                    }}
                >
                    <div role="tabpanel" className="pr-6 pb-6 pt-4">{children}</div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

ExpansionPanelDetail.propTypes = {
    children: PropTypes.node,
};

ExpansionPanelDetail.defaultProps = {
    children: null,
};

const ExpansionPanelGroup = ({ children }) => (
    <div role="tablist" className="border-b border-gray-100">
        {React.Children.map(children, child => (
            <div>
                {child}
            </div>
        ))}
    </div>
);

ExpansionPanelGroup.propTypes = {
    children: PropTypes.node,
};

ExpansionPanelGroup.defaultProps = {
    children: null,
};

ExpansionPanel.Summary = ExpansionPanelSummary;
ExpansionPanel.Detail = ExpansionPanelDetail;
ExpansionPanel.Group = ExpansionPanelGroup;

export default ExpansionPanel;
