import classnames from 'classnames';
import React from 'react';
import FourWheelsLogo from '../images/logos/4wheels-logo-grey-600.svg';
import BigLogo from '../images/logos/big-logo-grey-600.svg';
import CrLogo from '../images/logos/cr-logo-grey-600.svg';
import TecsafeLogo from '../images/logos/tecsafe-logo-grey-600.svg';
import PropTypes from '../util/PropTypes';
import Typography from './Typography';

const ClientsLogos = ({ className, title }) => (
    <div className={classnames(className, {
        'text-left': !className.includes('text-'),
    })}
    >
        {title && (
            <Typography variant="heading5" as="p" className="mb-6">
                {title}
            </Typography>
        )}
        <div className="flex flex-wrap sm:flex-nowrap gap-y-8 sm:gap-8 justify-between items-center">
            <div className="w-1/3 sm:w-auto">
                <img src={BigLogo} className="h-14 sm:h-16 lg:mx-auto" alt="BIG direkt gesund Logo" />
            </div>
            <div className="w-2/3 sm:w-auto">
                <img src={CrLogo} className="h-9 lg:mx-auto" alt="CommerzReal Logo" />
            </div>
            <div className="w-1/2 sm:w-auto">
                <img src={FourWheelsLogo} className="h-9 sm:h-12 lg:mx-auto" alt="4wheels Logo" />
            </div>
            <div className="w-1/2 sm:w-auto">
                <img src={TecsafeLogo} className="h-9 sm:h-12 lg:mx-auto" alt="Tecsafe Logo" />
            </div>
        </div>
    </div>
);

ClientsLogos.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

ClientsLogos.defaultProps = {
    className: '',
    title: '',
};

export default ClientsLogos;
