import React from 'react';
import classNames from 'classnames';

const DebugPill = ({ children, className }:
{ children: string|string[]|React.ReactNode, className?: string }): JSX.Element => (
    <div className={classNames(
        'bg-lightBlue-500 rounded-full px-3 py-1 m-2 text-xs z-10 relative shadow-lg text-white inline-block',
        className,
    )}
    >
        {children}
    </div>
);

export default DebugPill;
