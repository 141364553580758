import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    size: '24',
    color: '#0092DD',
};

const ChevronRightIcon = ({ color, size, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={size}
        width={size}
    >
        <path fill={color} d="M.53 2.98l9.23 9.3-9.23 9.3a1.46 1.46 0 00-.06 2l.07.07c.57.56 1.48.56 2.05 0l11.28-11.37-2.11-2.12L2.58.92a1.45 1.45 0 00-2.05 0c-.57.57-.57 1.5 0 2.06z" />
    </svg>
);

ChevronRightIcon.propTypes = propTypes;
ChevronRightIcon.defaultProps = defaultProps;

export default React.memo(ChevronRightIcon);
