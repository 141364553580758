/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import React from 'react';
import PropTypes from '../util/PropTypes';

/*
* Supports AspectRatio in the style of old GatsbyImage
*  */

const GatsbyImage = ({ image, className, ...rest }) => (
    <div className={className} style={{ position: 'relative', overflow: 'hidden' }}>
        {image?.aspectRatio && (
            <div aria-hidden="true" style={{ width: '100%', paddingBottom: `${100 / image.aspectRatio}%` }} />
        )}
        <Image
            className={classNames(
                className,
                '!absolute top-0',
            )}
            image={image}
            {...rest}
        />
    </div>
);

GatsbyImage.propTypes = {
    image: PropTypes.gatsbyImageObject,
    className: PropTypes.string,
};

GatsbyImage.defaultProps = {
    image: null,
    className: '',
};

export default GatsbyImage;
