import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark']),
};

const defaultProps = {
    children: null,
    className: null,
    variant: 'light',
};

const Box = ({ children, className, variant }) => (
    <div
        className={classnames(
            'bg-white',
            'shadow-[-20px_-20px_40px_0_rgba(246,246,248,0.46),_20px_20px_40px_0_rgba(94,118,132,0.15)]',
            className,
            {
                'bg-lightBlue-900 text-white': variant === 'dark',
            },
        )}
    >
        {children}
    </div>
);

Box.propTypes = propTypes;
Box.defaultProps = defaultProps;

export default Box;
