import classnames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    nested: PropTypes.bool,
    className: PropTypes.string,
    as: PropTypes.oneOf(['div', 'ul']),
};

const defaultProps = {
    children: null,
    nested: false,
    className: null,
    as: 'ul',
};

const variants = {
    enter: { opacity: 1 },
    idle: { opacity: 1, transition: { staggerChildren: 0.07 } },
    exit: { opacity: 0 },
};

// eslint-disable-next-line no-unused-vars
const NavigationGroup = ({ children, id, nested, className, as }) => {
    const Component = motion[as];

    return (
        <Component
            key="id"
            className={classnames('list-none p-0 m-0', className)}
            variants={variants}
            exit="exit"
            animate="idle"
            initial="enter"
        >
            {children}
        </Component>
    );
};

NavigationGroup.propTypes = propTypes;
NavigationGroup.defaultProps = defaultProps;

export default NavigationGroup;
