import React from 'react';
import { useDebug } from './DebugProvider';
import Container from '../Container';

const CenterLineDebug = (): JSX.Element | null => {
    const { showCenter } = useDebug();

    if (showCenter) {
        return (
            <div className="fixed pointer-events-none h-screen top-0 left-0 z-[100] flex page-container">
                <Container className="pb-4 md:pb-6 lg:pb-8 flex items-center justify-center">
                    <div className="bg-gray-100 h-screen opacity-50 w-8 mx-auto" />
                </Container>
            </div>
        );
    }

    return null;
};

export default CenterLineDebug;
