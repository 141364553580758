import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import useCalendlyModal from '../hooks/useCalendlyModal';
import PropTypes from '../util/PropTypes';
import Button from './Button';
import DatoImage from './DatoImage';
import EmailLink from './EmailLink';
import HighlightLabel from './HighlightLabel';
import LinkedInIcon from './icons/LinkedInIcon';
import XingIcon from './icons/XingIcon';
import { useLanguage } from './language/LanguageProvider.tsx';
import Link from './Link';
import TelephoneLink from './TelephoneLink';
import Typography from './Typography';

const propTypes = {
    data: PropTypes.personCardData,
    headingTag: PropTypes.string,
};

const defaultProps = {
    data: null,
    headingTag: 'h2',
};

const PersonCard = ({ data, headingTag }) => {
    const dato = useStaticQuery(graphql`
        {
            de: datoCmsContactPersonCardLocalization(locale: "de") {
                heading
                contactButtonText
            }
            en: datoCmsContactPersonCardLocalization(locale: "en") {
                heading
                contactButtonText
            }
            datoCmsCompanyContact {
                defaultContactPerson: salesPerson {
                    ...PersonCardData
                }
                salesPhone
                salesMail
            }
        }
    `);

    const { datoCmsCompanyContact: { defaultContactPerson, salesPhone, salesMail } } = dato;

    const personData = data || defaultContactPerson;

    const openCalendlyModal = useCalendlyModal();

    const { language } = useLanguage();
    const localized = dato[language];

    const hasSocials = personData.linkedinUrl || personData.xingUrl;

    return (
        <div className="shadow-xl rounded-md max-w-[900px] mx-auto">
            <div className="flex w-full justify-center mt-24">
                <HighlightLabel as={headingTag}>
                    {localized.heading}
                </HighlightLabel>
            </div>
            <div className="flex flex-col justify-center items-center lg:flex-row md:flex-wrap lg:flex-nowrap px-8 pb-8 pt-2">
                <div className="flex-1 text-center lg:text-right lg:pr-6">
                    <Typography variant="intro">
                        {personData.name}
                    </Typography>
                    <Typography variant="intro">
                        {personData.jobPosition}
                    </Typography>
                    {hasSocials && (
                        <div className="flex justify-center lg:justify-end">
                            {personData.linkedinUrl && (
                                <div className="mt-4">
                                    <Link
                                        to={personData.linkedinUrl}
                                        className="no-underline"
                                    >
                                        <LinkedInIcon size="27" />
                                        <span className="sr-only">{`${personData.name} LinkedIn Seite`}</span>
                                    </Link>
                                </div>
                            )}
                            {personData.xingUrl && (
                                <div className="pl-4 mt-4">
                                    <Link
                                        to={personData.xingUrl}
                                        className="no-underline"
                                    >
                                        <XingIcon size="27" />
                                        <span className="sr-only">{`${personData.name} Xing Seite`}</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex mt-6 lg:mt-0 w-40">
                    <DatoImage
                        className="rounded-full"
                        image={personData?.personCardPhoto}
                    />
                </div>
                <div className="flex-1 mt-6 lg:mt-0 lg:pl-6 text-center lg:text-left">
                    <Typography variant="intro">
                        <TelephoneLink>
                            {salesPhone}
                        </TelephoneLink>
                    </Typography>
                    <Typography variant="intro">
                        <EmailLink>
                            {salesMail}
                        </EmailLink>
                    </Typography>
                    {personData.originalId === '121055167' && (
                        <Button className="mt-6" variant="cta" onClick={openCalendlyModal}>
                            {localized.contactButtonText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

PersonCard.propTypes = propTypes;
PersonCard.defaultProps = defaultProps;

export default PersonCard;

export const query = graphql`
    fragment PersonCardData on DatoCmsPerson {
        name
        jobPosition
        originalId
        linkedinUrl
        xingUrl
        description
        mail
        phone
        personCardPhoto: photo {
            gatsbyImageData(imgixParams: {
                ar: "1", w: "600", facepad: 3.5, fit: "facearea",  auto: "format", q: 100
            })
            url
            alt
            smartTags
        }
    }
`;
