import React from 'react';
import PropTypes from '../util/PropTypes';
import CallToActionBoxLink from './CallToActionBoxLink';
import DatoImage from './DatoImage';

const propTypes = {
    children: PropTypes.node,
    thumb: PropTypes.oneOfType([PropTypes.gatsbyImageObject, PropTypes.datoImage]).isRequired,
};

const defaultProps = {
    children: null,
};

const CallToActionBox = ({ children, thumb }) => {
    const links = [];
    const rest = [];

    React.Children.forEach(children, (child) => {
        if (child.type === CallToActionBoxLink) {
            links.push(child);
        } else {
            rest.push(child);
        }
    });

    return (
        <div className="flex flex-wrap border-4 border-gray-50">
            <div className="w-full md:w-1/2">
                <DatoImage
                    className="h-full w-full"
                    image={thumb}
                />
            </div>
            <div className="w-full md:w-1/2 p-8">
                {rest}
                {links.map(link => <div className="mt-4">{link}</div>)}
            </div>
        </div>
    );
};

CallToActionBox.propTypes = propTypes;
CallToActionBox.defaultProps = defaultProps;

export default CallToActionBox;
