import React, { useRef } from 'react';
import Container from '../components/Container';
import LanguageSwitcher from '../components/language/LanguageSwitcher.tsx';
import Link from '../components/Link';
import MenuButton from '../components/MenuButton';
import BrainbitsLogo from '../components/visuals/BrainbitsLogo';
import { useLayoutActions, useLayoutState } from './Context';
import HeaderNavigationContainer from './HeaderNavigationContainer';

const NavigationHeader = () => {
    const ref = useRef();
    const { menuOpen } = useLayoutState();
    const { toggleMenu, closeMenu } = useLayoutActions();

    return (
        <div ref={ref}>
            <nav className="lg:hidden">
                <Container className="flex w-full mx-auto">
                    <div className="w-full max-w-[33.3333%] relative z-10">
                        <div className="h-20 flex">
                            <Link
                                to="/"
                                className="self-center no-underline"
                                onClick={closeMenu}
                                aria-label="brainbits"
                            >
                                <BrainbitsLogo className="h-6" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex ml-auto self-center lg:hidden items-center space-x-8">
                        <LanguageSwitcher />
                        <MenuButton close={menuOpen} type="button" onClick={toggleMenu} />
                    </div>
                </Container>
            </nav>
            <nav className="hidden lg:block">
                <HeaderNavigationContainer />
            </nav>
        </div>
    );
};

export default NavigationHeader;
