import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const CheckmarkIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="currentColor" d="M21.27 4.31a1 1 0 1 1 1.54 1.28l-.08.1-12.06 12.77-6.4-6.77a1 1 0 0 1 1.36-1.46l.1.08 4.94 5.23 10.6-11.23Z" />
    </svg>
);

CheckmarkIcon.propTypes = propTypes;
CheckmarkIcon.defaultProps = defaultProps;

export default React.memo(CheckmarkIcon);
