import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    alt: PropTypes.string,
};

const defaultProps = {
    alt: '',
};

const Spinner = ({ alt }) => (
    <div
        className="inline-block w-4 h-4 align-bottom border-4 border-[currentColor] border-r-transparent rounded-lg animate-spin"
        role="status"
    >
        {alt && (
            <span className="sr-only">{alt}</span>
        )}
    </div>
);

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
