import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';

const FeatureImageCenter = ({ title, image, items }) => {
    const breakpoint = Math.ceil(items.length / 2);
    const itemsLeft = items.slice(0, breakpoint);
    const itemsRight = items.slice(breakpoint);

    return (
        <Container>
            <Row cols={{ sm: 1, md: 2 }}>
                <Col md={2} lg={{ span: 6, order: 3 }} className="text-center mx-auto w-full md:px-14 flex flex-col self-center">
                    <Row cols={{ xs: 1, sm: 6, md: 6, lg: 6 }} noGap>
                        <Col sm={{ span: 6, start: 1 }} className="sm:row-start-1 z-10">
                            <DatoImage
                                image={image}
                                className="w-full"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} lg={{ span: 8, start: 3 }} className="md:text-center mt-16 lg:mt-0 md:mb-16">
                    <Typography variant="heading2">
                        {title}
                    </Typography>
                </Col>
                <Col lg={{ span: 3, order: 2 }} className="mt-6 flex flex-col self-center">
                    {itemsLeft.map((item, idx) => (
                        <React.Fragment key={item.title}>
                            <Typography variant="heading4" as="h3" className={idx > 0 && 'mt-8'}>
                                {item.title}
                            </Typography>
                            <Typography className="mt-2">
                                {item.text}
                            </Typography>
                        </React.Fragment>
                    ))}
                </Col>
                <Col lg={{ span: 3, order: 4 }} className="mt-6 flex flex-col self-center">
                    {itemsRight.map((item, idx) => (
                        <React.Fragment key={item.title}>
                            <Typography variant="heading4" as="h3" className={idx > 0 && 'mt-8'}>
                                {item.title}
                            </Typography>
                            <Typography className="mt-2">
                                {item.text}
                            </Typography>
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

FeatureImageCenter.propTypes = {
    title: PropTypes.string,
    image: PropTypes.datoImage,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    })),
};

FeatureImageCenter.defaultProps = {
    title: null,
    image: null,
    items: [],
};

export const query = graphql`
    fragment FeatureImageCenterProps on DatoCmsFeatureImageCenter {
        originalId
        title
        items {
            title
            text
        }
        image {
            gatsbyImageData(imgixParams: { auto: "format" })
            alt
            url
            smartTags
        }
    }
`;

export default FeatureImageCenter;
