/* eslint-disable react/no-array-index-key */
import React from 'react';

function prepareEmailAddressWithBreaks(email) {
    const segments = email.split(/(.*?[-_.@])/g);
    return segments.reduce(
        (prev, cur, idx) => [...prev, <wbr key={idx} />, cur],
        [],
    );
}

export default prepareEmailAddressWithBreaks;
