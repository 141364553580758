import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

const IntroText = ({ introText, isPage }) => {
    const renderDepth = React.useContext(BlockRendererContext);
    if (renderDepth <= 0 && isPage) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <Typography variant="intro" className="mt-4">
                            <CustomStructuredTextRenderer data={introText} blockParent="IntroText" />
                        </Typography>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Typography variant="intro" className="mt-4">
            <CustomStructuredTextRenderer data={introText} blockParent="IntroText" />
        </Typography>
    );
};

IntroText.shouldRenderHalfMargin = () => true;

IntroText.propTypes = {
    introText: PropTypes.shape({}),
    isPage: PropTypes.bool,
};

IntroText.defaultProps = {
    introText: null,
    isPage: false,
};

export const query = graphql`
    fragment IntroTextProps on DatoCmsIntroText {
        originalId
        introText {
            blocks
            links
            value
        }
    }
`;

export default IntroText;
