module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5C4PKZ2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layout/Layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/404/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/softwareentwicklung","crumbLabel":"Softwareentwicklung"},{"pathname":"/en/softwareentwicklung","crumbLabel":"Software Development"},{"pathname":"/produktpartner","crumbLabel":"Unsere Produktpartner"},{"pathname":"/en/produktpartner","crumbLabel":"Our Product Partners"},{"pathname":"/karriere","crumbLabel":"Karriere"},{"pathname":"/en/karriere","crumbLabel":"Careers"},{"pathname":"/referenzen","crumbLabel":"Referenzen"},{"pathname":"/en/referenzen","crumbLabel":"References"},{"pathname":"/technologien","crumbLabel":"Technologien"},{"pathname":"/trainings","crumbLabel":"Trainings & Workshops"},{"pathname":"/agile-methoden-trainings","crumbLabel":"Agile Methoden Trainings"},{"pathname":"/en/agile-methoden-trainings","crumbLabel":"Agile Methods Trainings"}],"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
