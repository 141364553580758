import classnames from 'classnames';
import * as React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
    as: PropTypes.elementType,
    children: PropTypes.node,
    className: PropTypes.string,
    paragraph: PropTypes.bool,
    id: PropTypes.string,
    variant: PropTypes.oneOf([
        'inherit',
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'heading5',
        'heading6',
        'heading7',
        'display1',
        'display4',
        'intro',
        'body',
        'blockquote',
        'quote',
        'cite',
        'small',
        'large',
        'handwritten',
        'caption',
    ]),
};

const defaultProps = {
    align: 'inherit',
    as: null,
    children: null,
    className: null,
    paragraph: false,
    variant: 'body',
    id: '',
};

const variantMapping = {
    heading1: 'h1',
    heading2: 'h2',
    heading3: 'h3',
    heading4: 'h4',
    heading5: 'h5',
    heading6: 'h6',
    display4: 'h4',
    intro: 'p',
    body: 'p',
    blockquote: 'p',
    quote: 'div',
    cite: 'cite',
    caption: 'figcaption',
};

const styleMapping = {
    heading1: 'font-display font-medium lg:text-7xl md:text-6xl text-4xl',
    heading2: 'font-display font-medium lg:text-5xl md:text-4xl text-2xl',
    heading3: 'font-display font-medium lg:text-3xl md:text-2xl text-xl',
    heading4: 'font-display font-medium md:text-xl text-lg tracking-wide',
    heading5: 'font-display font-bold text-base uppercase tracking-widest antialiased',
    heading6: 'font-display font-medium text-base',
    heading7: 'font-display font-medium text-xs uppercase tracking-widest antialiased',
    display1: 'font-display font-medium text-[36px] lg:text-[74px] leading-[48px] lg:leading-[82px]',
    display4: 'font-body font-light md:text-xl text-lg tracking-wide',
    intro: 'font-body font-semibold text-intro tracking-[0.5px]',
    introBold: 'font-body font-bold text-intro tracking-[0.5px]',
    body: 'font-body text-base tracking-wide',
    small: 'font-body text-sm font-normal tracking-wider',
    xsmall: 'font-body text-xs font-normal tracking-wider',
    large: 'font-display text-lg font-bold tracking-wide',
    blockquote: 'font-body text-intro leading-8 font-normal tracking-wide',
    quote: 'font-body text-xl leading-relaxed tracking-wider',
    caption: 'text-sm font-normal not-italic leading-normal text-gray-600 tracking-wider',
};

const Typography = React.forwardRef((props, ref) => {
    const {
        align,
        as,
        children,
        className,
        paragraph,
        variant,
        id,
    } = props;

    const Component = as || variantMapping[variant] || (paragraph ? 'p' : 'span');

    return (
        <Component
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...(id ? { id } : {})}
            className={classnames(
                {
                    'm-0': !paragraph,
                    'mb-4': paragraph,
                    [styleMapping[variant]]: variant !== 'inherit',
                    [`text-${align}`]: align !== 'inherit',
                },
                className,
            )}
            ref={ref}
        >
            {children}
        </Component>
    );
});

Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export default Typography;
