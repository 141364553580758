import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    size: '24',
    color: '#0092DD',
};

const ChevronLeftIcon = ({ color, size, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={size}
        width={size}
    >
        <path fill={color} d="M13.64 21.38l-9.23-9.3 9.23-9.3c.55-.55.57-1.42.06-2l-.06-.06a1.45 1.45 0 00-2.05 0L.3 12.08l2.1 2.13 9.18 9.24c.57.56 1.49.56 2.05 0 .57-.58.57-1.5 0-2.07z" />
    </svg>
);

ChevronLeftIcon.propTypes = propTypes;
ChevronLeftIcon.defaultProps = defaultProps;

export default React.memo(ChevronLeftIcon);
