import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import { useLanguage } from './LanguageProvider';

const LanguageSwitcher = (): JSX.Element => {
    const { languageMenu, setChosenLanguage } = useLanguage();

    return (
        <div className="space-x-0.5">
            {languageMenu.map(({ link, selected, langKey }, idx) => (
                <>
                    <Link
                        to={link}
                        onClick={() => {
                            setChosenLanguage(langKey);
                        }}
                        className={classNames({
                            'font-bold hover:text-lightBlue-500 focus:text-lightBlue-500 active:text-lightBlue-500': !selected,
                        }, 'uppercase text-xs')}
                    >
                        {langKey}
                    </Link>
                    {idx !== languageMenu.length - 1 && (
                        <span>
                            |
                        </span>
                    )}
                </>
            ))}
        </div>
    );
};

export default LanguageSwitcher;
