import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import PropTypes from '../util/PropTypes';
import { useLanguage } from './language/LanguageProvider.tsx';

const DatoImage = ({ image, style, className, objectFit, objectPosition }) => {
    const { language } = useLanguage();
    if (!image) {
        return null;
    }

    const altText = (() => {
        // Prefer smart tags for EN
        if (language === 'en') {
            if (image?.smartTags && image.smartTags[0]) {
                return `Image of ${image.smartTags[0]}`;
            }
            if (image?.alt) {
                return image.alt;
            }
        }
        if (image?.alt) {
            return image.alt;
        }
        if (image?.smartTags && image.smartTags[0]) {
            return `Image of ${image.smartTags[0]}`;
        }
        return null;
    })();

    if (!image.gatsbyImageData) {
        return <img alt={altText} style={style} className={classNames({ 'object-cover': objectFit === 'cover', 'object-contain': objectFit === 'contain' }, className)} src={image.url} />;
    }
    return (
        <GatsbyImage
            image={image.gatsbyImageData}
            alt={altText}
            className={className}
            objectPosition={image.focalPoint ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%` : objectPosition}
            style={style}
            objectFit={objectFit}
        />
    );
};

DatoImage.propTypes = {
    image: PropTypes.datoImage,
    className: PropTypes.string,
    objectFit: PropTypes.string,
    objectPosition: PropTypes.string,
    style: PropTypes.shape({}),
};

DatoImage.defaultProps = {
    image: null,
    className: '',
    objectFit: 'cover',
    objectPosition: 'center center',
    style: null,
};

export default DatoImage;
