import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Box from '../Box';
import CaseStudyContent from '../CaseStudyContent';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import { useLanguage } from '../language/LanguageProvider.tsx';
import Row from '../Row';
import Typography from '../Typography';

const CaseStudyFeature = ({ title, items }) => {
    const { language } = useLanguage();

    const buttonText = {
        en: 'Read the Case Study',
        de: 'zur Case Study',
    }[language];

    return (
        <>
            {title && (
                <Container>
                    <Typography variant="heading2" paragraph className="text-center">
                        {title}
                    </Typography>
                </Container>
            )}
            <Container
                className="px-0 md:px-6 lg:px-8"
                outerClassName={classNames('bg-gray-50', {
                    'mt-8 lg:mt-14': !!title,
                })}
            >
                <div className="bg-gray-50 pt-10 lg:pt-20">
                    {items.map((item, idx) => {
                        if (idx % 2 === 0) {
                            return (
                                <Row cols={{ sm: 1 }} className="mb-10 lg:mb-20 lg:grid-rows-1 relative">
                                    <Col
                                        md={6}
                                        lg={{ span: 9, start: 4 }}
                                        className="lg:row-start-1 lg:absolute lg:inset-0 max-h-96 lg:max-h-[none]"
                                    >
                                        <DatoImage
                                            image={item.featureThumbnail}
                                            objectPosition="right center"
                                            objectFit="cover"
                                            className="h-full w-full"
                                        />
                                    </Col>
                                    <Col
                                        md={5}
                                        lg={7}
                                        className="relative lg:row-start-1 lg:py-24 -mt-10 lg:mt-0 mx-6 lg:mx-0"
                                    >
                                        <Box className="px-4 py-8 md:px-8 lg:px-16 lg:py-14 md:rounded-md">
                                            <CaseStudyContent
                                                caseStudy={item}
                                                ctaTitle={buttonText}
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            );
                        }
                        return (
                            <Row cols={{ sm: 1 }} className="mb-10 lg:mb-20 lg:grid-rows-1 relative">
                                <Col
                                    md={6}
                                    lg={{ span: 9, start: 1 }}
                                    className="lg:row-start-1 lg:absolute lg:inset-0 max-h-96 lg:max-h-[none]"
                                >
                                    <DatoImage
                                        image={item.featureThumbnail}
                                        objectPosition="left center"
                                        objectFit="cover"
                                        className="h-full"
                                    />
                                </Col>
                                <Col
                                    md={{ span: 5, start: 2 }}
                                    lg={{ span: 7, start: 6 }}
                                    className="relative lg:row-start-1 lg:py-24 -mt-10 lg:mt-0 mx-6 lg:mx-0"
                                >
                                    <Box className="px-4 py-8 md:px-8 lg:px-16 lg:py-14 md:rounded-md">
                                        <CaseStudyContent
                                            caseStudy={item}
                                            ctaTitle={buttonText}
                                        />
                                    </Box>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </Container>
        </>
    );
};

CaseStudyFeature.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.string,
        title: PropTypes.string,
        featureThumbnail: PropTypes.datoImage,
        teaserText: PropTypes.string,
        permalink: PropTypes.string,
    })),
};

CaseStudyFeature.defaultProps = {
    title: '',
    items: [],
};

export const query = graphql`
    fragment CaseStudyFeatureProps on DatoCmsCaseStudyFeature {
        originalId
        title
        items {
            client
            teaserTitle
            featureThumbnail {
                gatsbyImageData(imgixParams: { ar: "10:5", fit: "clip", q: 100 })
                url
                alt
                smartTags
            }
            teaserText
            permalink
        }
    }
`;

export default CaseStudyFeature;
