import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    small: PropTypes.bool,
    bold: PropTypes.bool,
    uppercase: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    small: false,
    bold: false,
    uppercase: false,
    className: '',
};

const NavigationItemText = ({ children, small, bold, uppercase, className }) => (
    <div className={classnames(
        className,
        'flex-1 tracking-wide',
        {
            uppercase,
            'text-base': small,
            'font-medium': bold,
            'text-lg': bold && !small,
        },
    )}
    >
        {children}
    </div>
);

NavigationItemText.propTypes = propTypes;
NavigationItemText.defaultProps = defaultProps;

export default NavigationItemText;
