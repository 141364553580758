import classNames from 'classnames';
import { render } from 'datocms-structured-text-to-plain-text';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import ExpansionPanel from '../ExpansionPanel';
import JsonLd from '../JsonLd';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

const ExpansionRender = ({ headline, text, panels, isStructuredData }) => {
    const renderDepth = React.useContext(BlockRendererContext);
    return (
        <>
            {isStructuredData && (
                <JsonLd>
                    {{
                        '@context': 'https://schema.org/',
                        '@type': 'FAQPage',
                        mainEntity: panels.map(({
                            summary,
                            detail,
                        }) => ({
                            '@type': 'Question',
                            name: summary,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: render(detail),
                            },
                        })),
                    }}
                </JsonLd>
            )}
            {headline && (
                <Typography variant="heading2" paragraph>
                    {headline}
                </Typography>
            )}
            {text && (
                <Typography variant="intro">
                    {text}
                </Typography>
            )}
            <div className={classNames({
                'my-8': renderDepth === 0 || headline || text,
            })}
            >
                <ExpansionPanel.Group>
                    {panels.map(({ summary, detail }) => (
                        <ExpansionPanel key={summary}>
                            <ExpansionPanel.Summary>
                                <Typography variant="introBold" as="span">
                                    {summary}
                                </Typography>
                            </ExpansionPanel.Summary>
                            {render(detail) && (
                                <ExpansionPanel.Detail>
                                    <CustomStructuredTextRenderer data={detail} blockParent="ExpansionPanelGroup" />
                                </ExpansionPanel.Detail>
                            )}
                        </ExpansionPanel>
                    ))}
                </ExpansionPanel.Group>
            </div>
        </>
    );
};

const InlineExpansionPanels = ({ headline, text, panels, isStructuredData, isPage }) => {
    const renderDepth = React.useContext(BlockRendererContext);
    if (renderDepth <= 0 && isPage) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <ExpansionRender
                            headline={headline}
                            text={text}
                            panels={panels}
                            isStructuredData={isStructuredData}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <ExpansionRender
            headline={headline}
            text={text}
            panels={panels}
            isStructuredData={isStructuredData}
        />
    );
};

InlineExpansionPanels.shouldRenderHalfMargin = blockDepth => blockDepth === 0;

InlineExpansionPanels.propTypes = {
    headline: PropTypes.string,
    text: PropTypes.string,
    panels: PropTypes.arrayOf(PropTypes.shape({
        summary: PropTypes.string,
        detail: PropTypes.shape({}),
    })),
    isStructuredData: PropTypes.bool,
};

InlineExpansionPanels.defaultProps = {
    headline: '',
    text: '',
    panels: [],
    isStructuredData: false,
};

ExpansionRender.propTypes = InlineExpansionPanels.propTypes;
ExpansionRender.defaultProps = InlineExpansionPanels.defaultProps;

export const query = graphql`
    fragment ExpansionPanelGroupProps on DatoCmsExpansionPanelGroup {
        originalId
        headline
        text
        isStructuredData
        panels {
            summary
            detail {
                blocks
                value
                links
            }
        }
    }
`;

export default InlineExpansionPanels;
