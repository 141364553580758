import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import { useLanguage } from '../language/LanguageProvider.tsx';
import Panel from '../Panel';
import Row from '../Row';
import Typography from '../Typography';

const CaseStudyShowcase = ({ title, items }) => {
    const { language } = useLanguage();

    const buttonText = {
        en: 'Read the Case Study',
        de: 'Mehr Erfahren',
    }[language];

    return (
        <div className="flex flex-col gap-y-4 lg:gap-y-12">
            <Container>
                <Row cols={{ sm: 1, md: 1, lg: 12 }}>
                    <Col lg={{ span: 8, start: 3 }}>
                        <Typography variant="heading2" paragraph className="text-center">
                            {title}
                        </Typography>
                    </Col>
                </Row>
            </Container>
            <Panel
                className="py-12 lg:py-24 px-4 lg:mt-36"
                overflow="unset"
            >
                <Container className="lg:-mt-60">
                    <Row cols={{ sm: 1, md: 2, lg: 2 }} className="gap-y-12">
                        {items.map(item => (
                            <Col
                                className="flex flex-col h-full justify-between"
                            >
                                <DatoImage
                                    image={item.thumbnail}
                                    className="mb-6"
                                />
                                {!!item.teaserTitle && (
                                    <Typography variant="heading5" paragraph>
                                        {item.teaserTitle}
                                    </Typography>
                                )}
                                <Typography className="flex-1">
                                    {item.teaserText}
                                </Typography>
                                <Button to={item.permalink} variant="secondary" className="mt-8">
                                    {buttonText}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Panel>
        </div>
    );
};

CaseStudyShowcase.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        thumbnail: PropTypes.datoImage,
        teaserText: PropTypes.string,
        permalink: PropTypes.string,
    })),
};

CaseStudyShowcase.defaultProps = {
    title: '',
    items: [],
};

export const query = graphql`
    fragment CaseStudyShowcaseProps on DatoCmsCaseStudyShowcase {
        originalId
        title
        items {
            teaserTitle
            thumbnail {
                gatsbyImageData
                url
                alt
                smartTags
            }
            teaserText
            permalink
        }
    }
`;

export default CaseStudyShowcase;
