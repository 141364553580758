import { Transition, Dialog as HeadlessDialog } from '@headlessui/react';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import PropTypes from '../util/PropTypes';
import Button from './Button';
import PlusIcon from './icons/PlusIcon';

const WIDTH_PATTERN = /\b(w-|max-w-)/;

const noop = () => {};

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    onClose: null,
    children: null,
    className: null,
};

const Dialog = ({ className, open, onClose, children }) => (
    <Transition appear show={open} as={Fragment}>
        <HeadlessDialog onClose={onClose || noop}>
            <div className="fixed inset-0 flex items-center justify-center min-h-screen py-8 px-4 z-dialog">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-sm" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out transform transition duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in transform transition duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        className={classnames(
                            'relative overflow-hidden bg-white shadow-xl rounded',
                            {
                                'w-full max-w-screen-md': !(className && WIDTH_PATTERN.test(className)),
                            },
                            className,
                        )}
                    >
                        {onClose && (
                            <Button
                                onClick={onClose}
                                variant="icon"
                                className="absolute box-content top-4 right-4 h-6 w-6 p-2 rounded-full bg-white focus-visible:ring-2 ring-lightBlue-500 focus:outline-none"
                            >
                                <PlusIcon className="transform rotate-45" />
                            </Button>
                        )}
                        {children}
                    </div>
                </Transition.Child>
            </div>
        </HeadlessDialog>
    </Transition>
);

Dialog.propTypes = propTypes;
Dialog.defaultProps = defaultProps;

export default Dialog;
