import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from '../util/PropTypes';
import { useLanguage } from './language/LanguageProvider.tsx';

const CompanyContactWrapper = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            de: datoCmsCompanyContact(locale: "de") {
                serviceContactText
                serviceContactTitle
                serviceContactButton
                salesPerson {
                  ...PersonCardData
                }
                salesMail
            }
            en: datoCmsCompanyContact(locale: "en", fallbackLocales: ["de"]) {
                serviceContactText
                serviceContactTitle
                serviceContactButton
                salesPerson {
                  ...PersonCardData
                }
                salesMail
            }
        }
    `);

    const { language } = useLanguage();

    return children({ ...data[language] });
};

CompanyContactWrapper.propTypes = {
    children: PropTypes.node,
};

CompanyContactWrapper.defaultProps = {
    children: null,
};

export default CompanyContactWrapper;
