import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';

const InlineImage = ({ image, blockParent, isPage }) => {
    const renderDepth = React.useContext(BlockRendererContext);
    const isSvg = !image?.responsiveImage && image?.url?.endsWith('.svg');
    if (renderDepth <= 0 && !blockParent && isPage) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <figure className="my-8 first:my-0">
                            <DatoImage
                                image={image}
                                className={classNames({
                                    'mx-auto': (blockParent === 'GroupedContent'
                                        || blockParent === 'ColumnSet'
                                    ),
                                    'max-w-80 lg:max-w-none': isSvg,
                                })}
                            />
                            {image?.responsiveImage?.title && <Typography className="mt-3" paragraph variant="caption">{image.responsiveImage.title}</Typography>}
                        </figure>
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <figure className="my-8 first:my-0">
            <DatoImage
                image={image}
                className={classNames({
                    'mx-auto': (blockParent === 'GroupedContent'
                        || blockParent === 'ColumnSet'
                    ),
                    'max-w-80 lg:max-w-none': isSvg,
                })}
            />
            {image?.responsiveImage?.title && <Typography className="mt-3" paragraph variant="caption">{image.responsiveImage.title}</Typography>}
        </figure>
    );
};

InlineImage.shouldRenderHalfMargin = () => true;

InlineImage.propTypes = {
    image: PropTypes.datoImage,
    blockParent: PropTypes.string,
    isPage: PropTypes.bool,
};

InlineImage.defaultProps = {
    image: null,
    blockParent: '',
    isPage: false,
};

export const query = graphql`
    fragment ImageBlockProps on DatoCmsImage {
        originalId
        image {
            gatsbyImageData
            alt
            url
            smartTags
        }
    }
`;

export default InlineImage;
