import classnames from 'classnames';
import React from 'react';
import Typography from '../Typography';

/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
export const Heading1 = ({ isPage, className, ...props }) => (
    <Typography variant={isPage ? 'heading1' : 'heading3'} as="h1" className={classnames('mt-12 mb-4 first:mt-0', className)} {...props} />
);

export const Heading2 = ({ className, ...props }) => (
    <Typography variant="heading2" className={classnames('mt-16 mb-4 first:mt-0', className)} {...props} />
);

export const Heading3 = ({ isPage, className, ...props }) => (
    <Typography variant={isPage ? 'heading3' : 'heading4'} as="h3" className={classnames('mt-12 mb-4 first:mt-0', className)} {...props} />
);

export const Heading4 = ({ isPage, className, ...props }) => (
    <Typography variant={isPage ? 'heading4' : 'heading6'} as="h4" className={classnames('mt-8 mb-4 first:mt-0', className)} {...props} />
);

export const Heading5 = ({ isPage, className, ...props }) => (
    <Typography variant={isPage ? 'heading5' : 'heading6'} as="h5" className={classnames('mt-12 mb-4 first:mt-0', className)} {...props} />
);

export const Heading6 = ({ className, ...props }) => (
    <Typography variant="heading6" className={classnames('mt-12 mb-4 first:mt-0', className)} {...props} />
);
