import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';
import Button from './Button';

const propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    primary: PropTypes.boolean,
    prependIcon: PropTypes.node,
};

const defaultProps = {
    primary: false,
    prependIcon: null,
};

const CallToActionBoxLink = ({ url, primary, label, prependIcon }) => (
    <Button to={url} variant={primary ? 'secondary' : 'icon'} className="flex items-center flex-nowrap">
        {prependIcon
            && React.cloneElement(prependIcon, {
                className: classnames(prependIcon.props.className, 'mr-2'),
                size: '20',
            })}
        {label}
    </Button>
);

CallToActionBoxLink.propTypes = propTypes;
CallToActionBoxLink.defaultProps = defaultProps;

export default CallToActionBoxLink;
