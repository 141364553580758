import { MotionValue } from 'framer-motion';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import PropTypes from '../util/PropTypes';

const ScrollValueContext = createContext();
const LayoutStateContext = createContext();
export const LayoutDispatchContext = createContext();

function layoutReducer(state, action) {
    switch (action.type) {
        case 'openMenu': {
            return { ...state, menuOpen: true };
        }
        case 'closeMenu': {
            return { ...state, menuOpen: false };
        }
        case 'toggleMenu': {
            return { ...state, menuOpen: !state.menuOpen };
        }
        case 'setActiveNav': {
            return { ...state, activeNav: action.payload.activeNav };
        }
        case 'toggleActiveNav': {
            const activeNav = state.activeNav === action.payload.activeNav ? null : action.payload.activeNav;
            return { ...state, activeNav };
        }
        case 'resetActiveNav': {
            return { ...state, activeNav: null };
        }
        case 'changeScreenSize': {
            return {
                ...state,
                menuOpen: false,
                activeNav: null,
                isSmallScreen: action.payload.isSmallScreen,
                isMediumScreen: action.payload.isMediumScreen,
            };
        }
        case 'openCalendlyModal': {
            return {
                ...state,
                menuOpen: false,
                activeNav: null,
                calendlyModalOpen: true,
                calendlyUrl: action.payload.url,
            };
        }
        case 'closeCalendlyModal': {
            return { ...state, calendlyModalOpen: false, calendlyUrl: null };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const useLayoutState = () => {
    const state = useContext(LayoutStateContext);

    if (state === undefined) {
        throw new Error('useLayoutState must be used within a LayoutProvider');
    }

    return state;
};

const useLayoutActions = () => {
    const dispatch = useContext(LayoutDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useLayoutActions must be used within a LayoutProvider');
    }

    return {
        closeMenu: () => dispatch({ type: 'closeMenu' }),
        openMenu: () => dispatch({ type: 'openMenu' }),
        toggleMenu: () => dispatch({ type: 'toggleMenu' }),
        setActiveNav: activeNav => dispatch({ type: 'setActiveNav', payload: { activeNav } }),
        toggleActiveNav: activeNav => dispatch({ type: 'toggleActiveNav', payload: { activeNav } }),
        resetActiveNav: () => dispatch({ type: 'resetActiveNav' }),
        openCalendlyModal: url => dispatch({ type: 'openCalendlyModal', payload: { url } }),
        closeCalendlyModal: () => dispatch({ type: 'closeCalendlyModal' }),
    };
};

const useScrollValue = () => {
    const scrollValue = useContext(ScrollValueContext);

    return scrollValue;
};

const useScrollTo = (targetRef, scrollOffset = -100) => {
    const scrollTo = useCallback(() => {
        if (!targetRef.current) {
            return;
        }

        const element = targetRef.current;
        const top = element.getBoundingClientRect().top + window.scrollY + scrollOffset;

        window.scrollTo({ top, behavior: 'smooth' });
    }, [targetRef, scrollOffset]);

    return scrollTo;
};

const LayoutProvider = ({ scrollValue, children }) => {
    const [state, dispatch] = useReducer(layoutReducer, {
        menuOpen: false,
        activeNav: null,
        calendlyModalOpen: false,
        calendlyUrl: null,
        isSmallScreen: false,
        isMediumScreen: false,
    });
    const isSmallScreen = useMediaQuery('(max-width: 576px)');
    const isMediumScreen = useMediaQuery('(max-width: 1023px)');

    useEffect(() => {
        dispatch({ type: 'changeScreenSize', payload: { isSmallScreen, isMediumScreen } });
    }, [isSmallScreen, isMediumScreen]);

    return (
        <ScrollValueContext.Provider value={scrollValue}>
            <LayoutDispatchContext.Provider value={dispatch}>
                <LayoutStateContext.Provider value={state}>
                    {children}
                </LayoutStateContext.Provider>
            </LayoutDispatchContext.Provider>
        </ScrollValueContext.Provider>
    );
};

LayoutProvider.propTypes = {
    children: PropTypes.node,
    scrollValue: PropTypes.instanceOf(MotionValue).isRequired,
};

LayoutProvider.defaultProps = {
    children: null,
};

const LayoutConsumer = ({ children }) => {
    const state = useLayoutState();
    const actions = useLayoutActions();
    const scrollValue = useScrollValue();

    return children({ ...state, ...actions, scrollValue });
};

LayoutConsumer.propTypes = { children: PropTypes.func.isRequired };

export {
    LayoutConsumer,
    LayoutProvider,
    useLayoutActions,
    useLayoutState,
    useScrollTo,
    useScrollValue,
};
