import { graphql } from 'gatsby';
import React from 'react';
import { StructuredText } from 'react-datocms';
import PropTypes from '../../util/PropTypes';
import Table from '../Table';
import Typography from '../Typography';

const InlineTable = ({ table }) => {
    const buildRows = items => (
        <>
            {items.map(row => (
                <tr>
                    {row.map((cell) => {
                        if (cell[0] === 'th') {
                            return (
                                <td>
                                    <Typography variant="heading5" as="span" className="block my-6">
                                        {cell[1] && <StructuredText data={{ value: cell[1] }} />}
                                    </Typography>
                                </td>
                            );
                        }
                        return (
                            <td className="typo-body">
                                {cell[1] && <StructuredText data={{ value: cell[1] }} />}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </>
    );

    if (!table) {
        return null;
    }

    const parsedTable = JSON.parse(table);

    return (
        <div className="my-8 first:mt-0">
            <Table
                bordered
                responsive
                striped
                className="table-fixed"
            >
                {table.hasHeader && (
                    <thead>{buildRows(parsedTable.thead)}</thead>
                )}
                <tbody>{buildRows(parsedTable.tbody)}</tbody>
            </Table>
        </div>
    );
};

InlineTable.propTypes = {
    table: PropTypes.shape({
        hasHeader: PropTypes.boolean,
        thead: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.shape({})]))),
        tbody: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.shape({})]))),
    }),
};

InlineTable.defaultProps = {
    table: null,
};

export const query = graphql`
    fragment InlineTableProps on DatoCmsTable {
        originalId
        table
    }
`;

export default InlineTable;
