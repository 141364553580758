import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';

const FeatureCardSet = ({ title, text, cards }) => (
    <Container>
        {(title || text) && (
            <Row cols={{ sm: 1, md: 1, lg: 6 }} className="text-center">
                <Col lg={{ span: 4, start: 2 }}>
                    {title && (
                        <Typography variant="heading2" as="h2" paragraph>
                            {title}
                        </Typography>
                    )}
                    {text && (
                        <Typography>
                            {text}
                        </Typography>
                    )}
                </Col>
            </Row>
        )}
        <Row cols={{ sm: 1, md: 2, lg: 2 }} className="mt-12">
            {cards.map(card => (
                <Col className="mb-8">
                    <div className="border border-gray-10 p-6 sm:p-10 h-full">
                        <Row cols={5}>
                            <Col xs={2} lg={1}>
                                <DatoImage image={card.icon} className="w-full" />
                            </Col>
                            <Col xs={5} lg={4} className="mt-10 lg:mt-0">
                                {card.subtitle && (
                                    <Typography className="leading-none mb-4" variant="heading5" as="h3">
                                        {card.subtitle}
                                    </Typography>
                                )}
                                {card.title && (
                                    <Typography variant="heading4" paragraph>
                                        {card.title}
                                    </Typography>
                                )}
                                {card.text && (
                                    <Typography>
                                        {card.text}
                                    </Typography>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            ))}
        </Row>
    </Container>
);

FeatureCardSet.defaultProps = {
    title: '',
    text: '',
    cards: [],
};

FeatureCardSet.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.datoImage,
    })),
};

export const query = graphql`
    fragment FeatureCardSetProps on DatoCmsFeatureCardSet {
        originalId
        title
        text
        cards {
            subtitle
            title
            text
            icon {
                gatsbyImageData(imgixParams: { auto: "format" })
                alt
                url
                smartTags
            }
        }
    }
`;

export default FeatureCardSet;
