import React from 'react';
import PropTypes from '../util/PropTypes';
import Typography from './Typography';

const propTypes = {
    as: PropTypes.headingTag,
    children: PropTypes.node,
};

const defaultProps = {
    as: 'h2',
    children: null,
};

const CallToActionBoxTitle = ({ as, children }) => (
    <Typography variant="heading4" as={as}>
        {children}
    </Typography>
);

CallToActionBoxTitle.propTypes = propTypes;
CallToActionBoxTitle.defaultProps = defaultProps;

export default CallToActionBoxTitle;
