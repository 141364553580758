import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ArrowRightLongIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 14"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M16.195 0.531859C15.8596 0.25158 15.358 0.268067 15.0422 0.58132L14.9924 0.635176C14.7099 0.967837 14.7265 1.46548 15.0422 1.77873L19.4656 6.16666H2.06585L2.01118 6.16895C1.63215 6.20076 1.33331 6.56068 1.33331 7C1.33331 7.46023 1.66128 7.83333 2.06585 7.83333H19.3594L14.9366 12.2213L14.8868 12.2751C14.6042 12.6078 14.6209 13.1054 14.9366 13.4187C15.2699 13.7493 15.8103 13.7493 16.1436 13.4187L22.6666 6.94761L16.2493 0.58132L16.195 0.531859Z"
        />
    </svg>
);

ArrowRightLongIcon.propTypes = propTypes;
ArrowRightLongIcon.defaultProps = defaultProps;

export default React.memo(ArrowRightLongIcon);
