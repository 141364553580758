import classnames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import hasNoBackgroundColor from '../../util/hasNoBackgroundColor';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
// eslint-disable-next-line import/no-cycle
import BlockRenderer from './BlockRenderer';

const ColumnSet = ({ columns, blockParent, backgroundColor }) => {
    const noBgColor = hasNoBackgroundColor(backgroundColor);

    return (
        <Container
            outerStyle={{
                backgroundColor: backgroundColor?.hex || '',
            }}
        >
            <Row
                cols={{ sm: 1, md: 12 }}
                className={classnames({
                    'space-y-12 lg:space-y-0': !blockParent,
                    'py-6 lg:py-10 xl:py-24': !noBgColor,
                })}
            >
                {columns.map(column => (
                    <Col
                        md={12}
                        lg={{ span: column.width, start: column.start === 'none' ? '' : column.start }}
                        className={classnames({
                            'lg:self-stretch': column.align === 'stretch',
                            'lg:self-start': column.align === 'start',
                            'lg:self-end': column.align === 'end',
                            'lg:self-center': column.align === 'center',
                        })}
                    >
                        <BlockRenderer data={column.content} blockParent="ColumnSet" />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

ColumnSet.getBlockClassnames = (data) => {
    const noBgColor = hasNoBackgroundColor(data.backgroundColor);

    if (!noBgColor) {
        return 'remove-bottom remove-top';
    }
    return null;
};

ColumnSet.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        width: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
        start: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
        content: PropTypes.shape({}),
        align: PropTypes.oneOf(['stretch', 'start', 'center', 'end']),
    })),
    blockParent: PropTypes.string,
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string.isRequired,
    }),
};

ColumnSet.defaultProps = {
    columns: [],
    blockParent: '',
    backgroundColor: null,
};

export const query = graphql`
    fragment ColumnSetProps on DatoCmsColumnSet {
        originalId
        backgroundColor {
            hex
        }
        columns {
            width
            start
            align
            content {
                __typename
                ...ImageCollageProps
                ...InlineFormProps
                ...GroupedContentProps
                ...TickBulletPointProps
                ...ContactPersonCardProps
                ...ButtonProps
                ...IntroTextProps
                ...ImageBlockProps
                ...InlineTableProps
                ...ExpansionPanelGroupProps
                ...MultipleImageCtaProps
                ...ColorCtaProps
            }
        }
    }
`;

export default ColumnSet;
