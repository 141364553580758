import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
// eslint-disable-next-line import/no-cycle
import { BlockRendererContext } from './BlockRenderer';

const StandaloneLink = ({ text, url }) => {
    const renderDepth = React.useContext(BlockRendererContext);

    if (renderDepth <= 0) {
        return (
            <Container>
                <Row
                    cols={{ sm: 1, md: 1, lg: 6 }}
                >
                    <Col lg={{ span: 4, start: 2 }}>
                        <Button to={url}>
                            {text}
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Button to={url}>
            {text}
        </Button>
    );
};

StandaloneLink.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
};

StandaloneLink.defaultProps = {
    text: '',
    url: '',
};

StandaloneLink.shouldRenderHalfMargin = () => true;

export const query = graphql`
    fragment ButtonProps on DatoCmsButton {
        originalId
        text
        url
    }
`;

export default StandaloneLink;
