import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';

const BadgeSet = ({ items }) => (
    <Container>
        <Row cols={{ sm: 1, md: 1, lg: 12 }} className="lg:block hidden">
            <Col lg={{ span: 10, start: 2 }}>
                <Row cols={items.length || 1} noGap>
                    {items.map(({ icon, text }) => (
                        <Col className="px-8 text-center">
                            <DatoImage image={icon} className="w-16 mx-auto mb-4" />
                            <Typography variant="intro">
                                {text}
                            </Typography>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </Container>
);

BadgeSet.defaultProps = {
    items: [],
};

BadgeSet.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.datoImage,
    })),
};

export const query = graphql`
    fragment BadgeSetProps on DatoCmsBadgeSet {
        originalId
        items {
            icon {
                gatsbyImageData(imgixParams: { auto: "format" })
                alt
                url
                smartTags
            }
            text
        }
    }
`;

export default BadgeSet;
