import React from 'react';
import { Helmet } from 'react-helmet';
import ProptTypes from '../util/PropTypes';

const propTypes = {
    children: ProptTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const JsonLd = ({ children }) => (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(children)}
        </script>
    </Helmet>
);

JsonLd.propTypes = propTypes;

export default JsonLd;
