import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
};

const defaultProps = {
    className: null,
    size: '24',
};

const ArrowLeftIcon = ({ size, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={size}
        width={size}
    >
        <path
            fillRule="evenodd"
            transform="rotate(-180 11.6 12)"
            fill="currentColor"
            d="M14.296 4.238l.064.06 7.64 7.64-7.765 7.764a1.016 1.016 0 01-1.497-1.372l.06-.064L18.062 13H1.915c-.395 0-.715-.448-.715-1 0-.527.292-.96.662-.997L1.915 11H18.19l-5.267-5.266a1.016 1.016 0 01-.059-1.372l.06-.064a1.016 1.016 0 011.372-.06z"
        />
    </svg>
);

ArrowLeftIcon.propTypes = propTypes;
ArrowLeftIcon.defaultProps = defaultProps;

export default React.memo(ArrowLeftIcon);
