import { wrap } from '@popmotion/popcorn';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import {
    animate,
    motion, PanInfo, Transition, useMotionValue,
} from 'framer-motion';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import Button from './Button';
import ArrowLeftIcon from './icons/ArrowLeftIcon';
import ArrowRightIcon from './icons/ArrowRightIcon';
import ProgressBar from './ProgressBar';

interface SliderContext {
    page: number
    direction: number
    paginate: (newDirection: number) => void
    jumpTo: (index: number) => void
}

const SliderContext = React.createContext<SliderContext>(null as unknown as SliderContext);

interface BasicSlider {
    children: React.ReactNode|React.ReactNode[]
    autoPlay?: number
}

const BasicSlider = ({ children, autoPlay }: BasicSlider): JSX.Element => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = useCallback((newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    }, [page]);

    const jumpTo = useCallback((newPage: number) => {
        setPage([newPage, 1]);
    }, []);

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (autoPlay) {
            timer = setTimeout(() => {
                paginate(1);
            }, autoPlay * 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [autoPlay, paginate, page]);

    const value = useMemo(() => ({ page, direction, paginate, jumpTo }), [page, direction, paginate, jumpTo]);

    return (
        <SliderContext.Provider value={value}>
            {children}
        </SliderContext.Provider>
    );
};

const useSliderContext = (): SliderContext => {
    const ctx = React.useContext(SliderContext);
    if (!ctx) throw new Error('Must be used within a Slider component.');
    return ctx;
};

const SliderLeftButton = ({
    className = 'text-lightBlue-500',
}: {
    className: string
}): JSX.Element => {
    const { paginate } = useSliderContext();
    return (
        <Button
            variant="icon"
            className={className}
            onClick={() => paginate(-1)}
        >
            <ArrowLeftIcon />
        </Button>
    );
};

const SliderRightButton = ({
    className = 'text-lightBlue-500',
}: {
    className: string
}): JSX.Element => {
    const { paginate } = useSliderContext();
    return (
        <Button
            variant="icon"
            className={className}
            onClick={() => paginate(1)}
        >
            <ArrowRightIcon />
        </Button>
    );
};

const Progress = ({ count }: { count: number }): JSX.Element => {
    const { page } = useSliderContext();
    const slideIndex = wrap(0, count, page);

    return (
        <ProgressBar
            current={(slideIndex + 1)}
            total={count}
        />
    );
};

interface Slides {
    animation?: 'slide' | 'fade'
    children: React.ReactNode[]
    className?: string
    slidesPerView?: number
    centered?: boolean
}

const transition: Transition = {
    type: 'spring',
    bounce: 0,
};

const Slides = ({
    children,
    className,
    slidesPerView = 1,
    centered,
    animation = 'slide',
}: Slides): JSX.Element => {
    const { page, paginate } = useSliderContext();
    const [activated, setActivated] = React.useState(false);
    const [refWidth, setRefWidth] = React.useState(0);

    const slides = React.Children.toArray(children);

    const scrollRef = React.useRef<HTMLDivElement>(null);

    const [{ height: minHeight, width: minWidth }, setMaxDimensions] = useReducer((
        prev: { height: number, width: number, heightIndex: number, widthIndex: number, refWidth: number },
        action: { element: HTMLDivElement | null, index: number, refWidth: number },
    ) => {
        /*
        * Screen out 0 index here because it's the 'relative'
        * positioned one and thus the height will always be the
        * container's. The over-scanning gives us the actual height
        * of slide 0 - just rendered later in process.
        *
        * We check if the index is lesser than the last saved to
        * enable resize-observer functionality - this way if page
        * is resized, the slides call setMaxWidth in order and we
        * can work out whether this is a distinct 'event'.
        * */

        if (action.element && !activated) {
            const updates = {
                height: prev.height,
                heightIndex: prev.heightIndex,
                width: prev.width,
                widthIndex: prev.widthIndex,
                refWidth: action.refWidth,
            };

            const { height } = action.element.getBoundingClientRect();

            if (prev?.height < height || action.index <= prev.heightIndex || action.refWidth !== prev.refWidth) {
                updates.height = height;
                updates.heightIndex = action.index;
            }

            // Width needs to come from children, because the slide ref has width applied (unlike height)

            if (action.element.firstElementChild && action.element.firstElementChild?.firstElementChild) {
                const width = action.element.firstElementChild.firstElementChild.clientWidth;

                if (prev?.width < width || action.index <= prev.widthIndex || action.refWidth !== prev.refWidth) {
                    updates.width = width;
                    updates.widthIndex = action.index;
                }
            }

            return updates;
        }
        return prev;
    }, { height: 0, width: 0, heightIndex: 0, widthIndex: 0, refWidth: 0 });

    const x = useMotionValue(0);
    const containerRef = React.useRef<HTMLDivElement>(null);
    // Directly maps to page in context, is any number

    const newX = useCallback((): number => (
        -page * (containerRef.current?.clientWidth || 0)
    ) / slidesPerView, [page, slidesPerView]);

    const handleEndDrag = (e: Event, dragProps: PanInfo): void => {
        e.preventDefault();
        e.stopPropagation();
        if (containerRef.current) {
            containerRef.current.classList.remove('dragging');
        }
        if (dragProps) {
            const clientWidth = containerRef.current?.clientWidth || 0;

            const { offset, velocity } = dragProps;

            if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
                animate(x, newX(), transition);
                return;
            }

            if (offset.x > clientWidth / 4 / slidesPerView) {
                // console.log('was a', offset.x / (clientWidth / totalSlidesAmount) / 2);
                paginate(-1);
            } else if (offset.x < -clientWidth / 4 / slidesPerView) {
                // console.log('was b');
                paginate(1);
            } else {
                animate(x, newX(), transition);
            }
        }
    };

    React.useEffect(() => {
        const localRefWidth = document.body.clientWidth || 0;
        setRefWidth(localRefWidth);
    }, []);

    useResizeObserver<HTMLDivElement>(scrollRef, () => {
        const animation = animate(x, newX(), transition);
        animation.complete();
        const localRefWidth = document.body.clientWidth || 0;
        if (refWidth !== localRefWidth) {
            setActivated(false);
            setRefWidth(localRefWidth);
        }
    });

    const centerSlideOffset = centered ? Math.ceil(slidesPerView / 2) : 0;

    React.useEffect(() => {
        if (!activated) setActivated(true);
        const controls = animate(x, newX(), transition);
        return controls.stop;
        // eslint-disable-next-line
    }, [page]);

    const range = React.useMemo(() => [
        ...(new Array((slidesPerView * 2) + 4
            + (slidesPerView % 2 === 0 ? 0 : 1))),
    ].map((_, idx) => idx - (slidesPerView + 2)), [slidesPerView]);

    return (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <div
            ref={scrollRef}
            role="list"
            onClick={() => {
                if (!activated) setActivated(true);
            }}
            className={classNames('w-full relative flex overflow-hidden select-none', className)}
            style={{
                minHeight: activated ? `${minHeight}px` : undefined,
                minWidth: activated ? `${minWidth}px` : undefined,
            }}
        >
            <motion.div
                ref={containerRef}
                className="w-full flex flex-none"
                style={{
                    flexFlow: 'row nowrap',
                    transform: 'none!important',
                    minHeight: activated ? `${minHeight}px` : undefined,
                }}
            >
                {range.map((rangeValue, idx) => {
                    const index = rangeValue + page;
                    const modulo = index % slides.length;
                    const imageIndex = modulo < 0 ? slides.length + modulo : modulo;
                    const shouldRender = minHeight !== 0 && activated;
                    const active = centered ? rangeValue - centerSlideOffset + 1 === 0 : rangeValue === 0;
                    return (
                        <motion.div
                            key={`${index}-${refWidth}`}
                            ref={(e) => {
                                if (e) {
                                    setMaxDimensions({ element: e, index, refWidth });
                                }
                            }}
                            style={{
                                position: shouldRender ? 'absolute' : 'relative',
                                width: `${100 / slidesPerView}%`,
                                minWidth: `${100 / slidesPerView}%`,
                                maxWidth: `${100 / slidesPerView}%`,
                                height: '100%',
                                x,
                                left: shouldRender ? `${index * (100 / slidesPerView)}%` : undefined,
                                marginLeft: (!shouldRender && idx === 0) ? `${index * (100 / slidesPerView)}%` : undefined,
                                right: shouldRender ? `${index * (100 / slidesPerView)}%` : undefined,
                            }}
                            draggable
                            drag="x"
                            dragElastic={1}
                            onDragEnd={handleEndDrag}
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (containerRef?.current) {
                                    containerRef.current.classList.add('dragging');
                                }
                            }}
                        >
                            <span
                                style={{
                                    opacity: !active && animation === 'fade' ? 0 : 1,
                                    transition: '0.2s',
                                }}
                                data-active={active}
                            >
                                <div className="inline-block">
                                    {slides[
                                        wrap(
                                            0,
                                            slides.length,
                                            centered ? imageIndex - centerSlideOffset + 1 : imageIndex,
                                        )
                                    ]}
                                </div>
                            </span>
                        </motion.div>
                    );
                })}
            </motion.div>
        </div>
    );
};

BasicSlider.Slides = Slides;
BasicSlider.SliderLeftButton = SliderLeftButton;
BasicSlider.SliderRightButton = SliderRightButton;
BasicSlider.Progress = Progress;

export default BasicSlider;
