import { graphql, useStaticQuery } from 'gatsby';
import { useLanguage } from '../components/language/LanguageProvider.tsx';
import { useLayoutActions } from '../layout/Context';

const useCalendlyModal = (url = null) => {
    const contacts = useStaticQuery(graphql`
        query {
            de: datoCmsCompanyContact(locale: "de") {
                defaultCalendlyUrl
            }
            en: datoCmsCompanyContact(locale: "en", fallbackLocales: ["de"]) {
                defaultCalendlyUrl
            }
        }
    `);

    const { language } = useLanguage();

    const {
        defaultCalendlyUrl,
    } = contacts[language];

    const { openCalendlyModal } = useLayoutActions();

    return () => {
        openCalendlyModal(url || defaultCalendlyUrl);
    };
};

export default useCalendlyModal;
