import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import ProcedureTeaserBottom from '../../images/visuals/procedure-teaser-bottom.svg';
import TechstackVisual from '../../images/visuals/techstack.svg';
import WorkCultureVisual from '../../images/visuals/work-culture.svg';
import PropTypes from '../../util/PropTypes';
import Button from '../Button';
import Col from '../Col';
import Container from '../Container';
import GatsbyImage from '../GatsbyImage';
import Row from '../Row';
import Typography from '../Typography';

const ProcedureTeaser = ({ content }) => {
    const data = useStaticQuery(graphql`
        query {
            topVisual: file(relativePath: {eq: "visuals/procedure-teaser-top.png"}) {
                childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: NONE
                    )
                }
            }
        }
    `);

    const {
        headline, headline2, text, text2, buttonText, buttonText2, link1, link2,
    } = content;

    return (
        <Container className="relative pt-20 md:pt-44 lg:pt-52 pb-20 md:pb-24">
            <div role="presentation" className="absolute top-0 left-6 right-6">
                <GatsbyImage
                    image={{
                        ...data.topVisual.childImageSharp.gatsbyImageData,
                        aspectRatio: 2 / 1,
                    }}
                    alt="Teaser Visual"
                    className="w-full max-w-[400px] md:max-w-[850px] mx-auto"
                />
            </div>
            <div role="presentation" className="absolute bottom-0 left-6 right-6 lg:left-[20%] lg:right-[15%]">
                <img src={ProcedureTeaserBottom} className="w-full max-w-[400px] md:max-w-none mx-auto" alt="Teaser Visual" />
            </div>
            <div role="presentation" className="lg:hidden absolute top-1/2 -translate-y-1/2 md:-translate-y-1/3 left-6 right-6">
                <img src={ProcedureTeaserBottom} className="w-full max-w-[400px] md:max-w-none mx-auto" alt="Teaser Visual" />
            </div>
            <Row
                cols={{ sm: 1, md: 1, lg: 2 }}
                className="gap-y-20"
            >
                <Col>
                    <div className="bg-white pb-16 pt-20 relative">
                        <div role="presentation" className="absolute left-0 right-0 -top-12">
                            <img
                                src={WorkCultureVisual}
                                alt={headline}
                                className="h-24 w-24 mx-auto"
                            />
                        </div>
                        <div className="lg:px-20">
                            <Typography variant="heading3" as="h2" paragraph>
                                {headline}
                            </Typography>
                            <Typography variant="intro">
                                {text}
                            </Typography>
                            {link1 && (
                                <Button to={link1.permalink} variant="secondary" className="mt-8">
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="bg-white pb-16 pt-20 relative">
                        <div role="presentation" className="absolute left-0 right-0 -top-12">
                            <img
                                src={TechstackVisual}
                                alt={headline2}
                                className="h-24 w-24 mx-auto"
                            />
                        </div>
                        <div className="lg:px-20">
                            <Typography variant="heading3" as="h2" paragraph>
                                {headline2}
                            </Typography>
                            <Typography variant="intro">
                                {text2}
                            </Typography>
                            {link2 && (
                                <Button to={link2.permalink} variant="secondary" className="mt-8">
                                    {buttonText2}
                                </Button>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

ProcedureTeaser.propTypes = {
    content: PropTypes.shape({
        headline: PropTypes.string,
        headline2: PropTypes.string,
        text: PropTypes.string,
        text2: PropTypes.string,
        buttonText: PropTypes.string,
        buttonText2: PropTypes.string,
        link1: PropTypes.shape({
            permalink: PropTypes.string,
        }),
        link2: PropTypes.shape({
            permalink: PropTypes.string,
        }),
    }),
};

ProcedureTeaser.defaultProps = {
    content: {
        headline: '',
        headline2: '',
        text: '',
        text2: '',
        buttonText: '',
        buttonText2: '',
        link1: null,
        link2: null,
    },
};

export const query = graphql`
    fragment ProcedureTeaserProps on DatoCmsProcedureTeaser {
        content {
            headline
            headline2
            text
            text2
            buttonText
            buttonText2
            link1 {
                permalink
            }
            link2 {
                permalink
            }
        }
    }
`;

export default ProcedureTeaser;
