import { motion } from 'framer-motion';
import React from 'react';
import PropTypes from '../util/PropTypes';
import Link from './Link';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    fadeInOut: PropTypes.bool,
    index: PropTypes.number.isRequired,
};

const defaultProps = {
    children: null,
    className: null,
    onClick: null,
    to: '',
    fadeInOut: false,
};

const variants = {
    enter: direction => ({ opacity: 0, x: direction === 'in' ? '100%' : '-100%' }),
    idle: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
};

const fadeInOutVariants = {
    enter: { opacity: 0 },
    idle: { opacity: 1 },
    exit: { opacity: 0 },
};

const NavigationTab = ({
    children, className, onClick, to, fadeInOut, index,
}) => {
    let element = (
        <button
            type="button"
            className="py-6 px-8 sm:px-12 lg:px-5 xl:px-12 bg-none flex items-center w-full text-left whitespace-nowrap outline-none focus:outline-none lg:hover:bg-lightBlue-400 focus:bg-lightBlue-400 active:bg-lightBlue-400 lg:hover:bg-opacity-10 focus:bg-opacity-10 active:bg-opacity-10"
            onClick={onClick}
        >
            {children}
        </button>
    );

    if (to) {
        element = (
            <Link
                className="py-6 px-8 sm:px-12 lg:px-5 xl:px-12 no-underline flex items-center w-full text-left whitespace-nowrap outline-none focus:outline-none lg:hover:bg-lightBlue-400 focus:bg-lightBlue-400 active:bg-lightBlue-400 lg:hover:bg-opacity-10 focus:bg-opacity-10 active:bg-opacity-10"
                to={to}
                onClick={onClick}
            >
                {children}
            </Link>
        );
    }

    return (
        <motion.li
            className={className}
            variants={fadeInOut ? fadeInOutVariants : variants}
        >
            {index !== 0 && <hr className="mx-8 sm:mx-12 lg:mx-5 xl:mx-12 my-0" />}
            {element}
        </motion.li>
    );
};

NavigationTab.propTypes = propTypes;
NavigationTab.defaultProps = defaultProps;

export default NavigationTab;
