import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import DatoImage from '../DatoImage';
import Row from '../Row';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

const StepModule = ({ title, richText, items }) => (
    <div>
        <div className="bg-gray-50 pt-12 lg:pt-32 lg:pb-16">
            <Container>
                <Row cols={12}>
                    <Col xs={12} lg={{ start: 2, span: 10 }} xl={{ start: 2, span: 8 }}>
                        <Typography variant="heading2" className="mb-9">
                            {title}
                        </Typography>
                        <Typography variant="intro" className="mt-4">
                            <CustomStructuredTextRenderer data={richText} />
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </div>
        {items.map((item, index) => (
            <div className={classNames({
                'bg-gray-50': index % 2 === 0,
            }, 'py-8 lg:py-16')}
            >
                <Container>
                    <Row cols={12}>
                        <Col xs={12} lg={{ start: 2, span: 4 }} xl={{ start: 3, span: 3 }} className="mb-4 lg:mb-0">
                            <DatoImage className="w-full max-w-[12rem] lg:max-w-[16rem] mx-auto" image={item.image} />
                        </Col>
                        <Col xs={12} lg={1}>
                            <div className={classNames({
                                'bg-white': index % 2 === 0,
                                'bg-gray-50': index % 2 === 1,
                            }, 'rounded-full w-12 h-12 xl:w-20 xl:h-20')}
                            >
                                <div className="text-[40px] translate-x-1 -translate-y-4 xl:text-[80px] xl:translate-x-2.5 xl:-translate-y-10">
                                    {index + 1}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={5}>
                            <Typography variant="heading4" as="h3" className="my-4">
                                {item.title}
                            </Typography>
                            <Typography variant="body" className="my-4">
                                <CustomStructuredTextRenderer data={item.richText} />
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </div>
        ))}
    </div>
);

StepModule.getBlockClassnames = () => 'remove-bottom remove-top';

StepModule.propTypes = {
    title: PropTypes.string.isRequired,
    richText: PropTypes.shape({}).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.datoImage,
    })),
};

StepModule.defaultProps = {
    items: [],
};

export const query = graphql`
    fragment StepModuleProps on DatoCmsStepModule {
        originalId
        title
        richText: text {
            blocks
            links
            value
        }
        items {
            title
            richText: text {
                blocks
                links
                value
            }
            image {
                gatsbyImageData(imgixParams: {
                    auto: "compress",
                    q: 50,
                    w: "2160",
                    fpX: 0.5,
                    fpY: 0.5
                })
                alt
                url
                smartTags
            }
        }
    }
`;

export default StepModule;
