import classnames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    close: PropTypes.bool,
    onClick: PropTypes.func,
};

const defaultProps = {
    className: null,
    close: false,
    onClick: () => {},
};

const MenuButton = ({ className, close, onClick }) => (
    <button
        onClick={onClick}
        className={classnames(
            className,
            'p-0 color-inherit border-0 outline-none bg-transparent focus:color-inherit focus:border-0 focus:outline-none focus:bg-transparent',
        )}
        type="button"
        aria-label="Menü öffnen/schließen"
    >
        <svg viewBox="0 0 32 32" width="32" height="32">
            <rect
                className={classnames(
                    'transition-transform duration-200 ease-out origin-[32px_8.5px]',
                    {
                        'scale-x-[0.83]': !close,
                        'translate-x-[-3px] translate-y-[-2px] -rotate-45': close,
                    },
                )}
                fill="currentColor"
                x="0"
                y="5"
                width="32"
                height="2"
                rx="1.5"
            />
            <rect
                className={classnames(
                    'transition-transform duration-200 ease-out origin-[50%_50%]',
                    {
                        'scale-x-0': close,
                    },
                )}
                fill="currentColor"
                x="0"
                y="15"
                width="32"
                height="2"
                rx="1.5"
            />
            <rect
                className={classnames(
                    'transition-transform duration-200 ease-out origin-[32px_34.5px]',
                    {
                        'scale-x-[0.6]': !close,
                        'translate-x-[-10px] translate-y-[-1px] rotate-45': close,
                    },
                )}
                fill="currentColor"
                x="0"
                y="25"
                width="32"
                height="2"
                rx="1.5"
            />
        </svg>
    </button>
);

MenuButton.propTypes = propTypes;
MenuButton.defaultProps = defaultProps;

export default MenuButton;
