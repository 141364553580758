/* global UC_UI */

import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Col from '../components/Col';
import Container from '../components/Container';
import DatoImage from '../components/DatoImage';
import EmailLink from '../components/EmailLink';
import LinkedInIcon from '../components/icons/LinkedInIcon';
import XingIcon from '../components/icons/XingIcon';
import { useLanguage } from '../components/language/LanguageProvider.tsx';
import Link from '../components/Link';
import Panel from '../components/Panel';
import Row from '../components/Row';
import CustomStructuredTextRenderer from '../components/structured-text/CustomStructuredTextRenderer';
import TelephoneLink from '../components/TelephoneLink';
import Typography from '../components/Typography';
import BrainbitsLogo from '../components/visuals/BrainbitsLogo';
import Iso27001Logo from '../images/iso27001-logo.svg';
import Iso9001Logo from '../images/iso9001-logo.svg';
import TimeToActLogo from '../images/logos/time-to-act.svg';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            datoCmsCompanyContact {
                salesPhone
                salesMail
            }
            de: datoCmsFooter(locale: "de") {
                image {
                    gatsbyImageData
                    alt
                    url
                }
                ctaTitle
                ctaText
                companiesLabel
                servicesLabel
                careersLabel
                button1 {
                    ...LinkWithTitleProps
                }
                button2 {
                    ...LinkWithTitleProps
                }
                dynamicServicesLinks {
                    ...LinkWithTitleProps
                }
                dynamicSmallLinks {
                    ...LinkWithTitleProps
                }
                dynamicCompaniesLinks {
                    ...LinkWithTitleProps
                }
                compliance {
                    links
                    blocks
                    value
                }
                consentLabel
            }
            en: datoCmsFooter(locale: "en", fallbackLocales: ["en"]) {
                image {
                    gatsbyImageData
                    alt
                    url
                }
                ctaTitle
                ctaText
                companiesLabel
                servicesLabel
                careersLabel
                compliance {
                    links
                    blocks
                    value
                }
                button1 {
                    ...LinkWithTitleProps
                }
                button2 {
                    ...LinkWithTitleProps
                }
                dynamicServicesLinks {
                    ...LinkWithTitleProps
                }
                dynamicSmallLinks {
                    ...LinkWithTitleProps
                }
                dynamicCompaniesLinks {
                    ...LinkWithTitleProps
                }
                consentLabel
            }
        }
    `);

    const { datoCmsCompanyContact: { salesPhone, salesMail } } = data;

    const { language } = useLanguage();
    const {
        companiesLabel,
        servicesLabel,
        careersLabel,
        consentLabel,
        dynamicServicesLinks,
        dynamicSmallLinks,
        dynamicCompaniesLinks,
        compliance,
        ctaTitle,
        ctaText,
        button1,
        button2,
        image,
    } = data[language];

    const imageAltString = {
        de: 'Logo der TIMETOACT GROUP, zu der brainbits gehört',
        en: 'Logo of the TIMETOACT GROUP, to which brainbits belongs',
    }[language];

    const splitPoint = Math.floor(dynamicCompaniesLinks.length / 2);
    const companiesLinksSet1 = dynamicCompaniesLinks.slice(0, splitPoint);
    const companiesLinksSet2 = dynamicCompaniesLinks.slice(splitPoint);

    return (
        <>
            <Panel className="py-8 lg:pt-14 xl:pt-24 lg:pb-14 mt-4 md:mt-6">
                <Container>
                    <Row cols={{ sm: 1, md: 4, lg: 12 }}>
                        <Col md={2} lg={5} className="flex flex-col justify-end">
                            <Typography variant="heading5" as="span">
                                {ctaTitle}
                            </Typography>
                            <Typography variant="heading2" className="md:max-w-[350px] mt-2 xl:mb-4">
                                {ctaText}
                            </Typography>
                            <div className="mt-6 md:mt-10 md:gap-x-3 flex flex-col lg:flex-row lg:flex-wrap md:max-w-[350px] lg:max-w-full">
                                <Button {...button1} variant="pill-dark" className="mb-2 lg:min-w-[220px] xl:min-w-0" />
                                <Button {...button2} variant="pill" className="mb-2 lg:min-w-[220px] xl:min-w-0" />
                            </div>
                        </Col>
                        <Col md={2} className="hidden md:block lg:hidden">
                            <DatoImage image={image} className="w-full" />
                        </Col>
                        <Col md={2} lg={3} className="h-card vcard pt-8 md:pt-8 lg:pt-0 flex flex-col justify-end">
                            <div className="flex flex-col justify-between">
                                <div>
                                    <Link to="/" className="no-underline md:hidden mb-6 block">
                                        <BrainbitsLogo variant="black" className="h-6" />
                                    </Link>
                                    <img className="hidden u-logo u-photo photo" src="/logo.png" alt="brainbits Logo" />
                                    <Typography variant="small" className="h-adr p-adr adr">
                                        <span className="p-name p-org fn org">
                                            brainbits GmbH
                                        </span>
                                        <br />
                                        <span className="p-street-address street-address">
                                            Alpenerstr. 16
                                        </span>
                                        <br />
                                        <span className="p-postal-code postal-code">
                                            50825
                                        </span>
                                        {' '}
                                        <span className="p-locality locality">
                                            Köln
                                        </span>
                                    </Typography>
                                    <Typography className="flex mt-6 lg:mt-8 lg:mb-2 font-semibold" variant="small">
                                        <TelephoneLink className="p-tel tel" hideIcon>
                                            {salesPhone}
                                        </TelephoneLink>
                                    </Typography>
                                    <Typography className="flex font-semibold" variant="small">
                                        <EmailLink className="u-email email" hideIcon>
                                            {salesMail}
                                        </EmailLink>
                                    </Typography>
                                </div>
                                <div className="hidden lg:flex items-center pt-6">
                                    <h2 className="sr-only">brainbits auf Social-Media</h2>
                                    <ul className="flex space-x-6 mb-3">
                                        <li>
                                            <Link
                                                to="https://www.linkedin.com/company/brainbits-gmbh/"
                                                className="no-underline"
                                            >
                                                <LinkedInIcon size="15" />
                                                <span className="sr-only">brainbits LinkedIn Seite</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="https://www.xing.com/pages/brainbits"
                                                className="no-underline"
                                            >
                                                <XingIcon size="15" />
                                                <span className="sr-only">brainbits Xing Seite</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={2} lg={4} className="hidden lg:block xl:-mt-8">
                            <DatoImage image={image} className="w-full" />
                        </Col>
                    </Row>
                </Container>
            </Panel>
            <Panel className="pt-8 lg:pt-14 mt-[1px]">
                <Container>
                    <Row cols={{ sm: 1, md: 4, lg: 12 }}>
                        <Col md={2} lg={4} xl={3} className="mb-8">
                            <Typography variant="heading7" as="h2" paragraph>{servicesLabel}</Typography>
                            <ul className="space-y-2">
                                {dynamicServicesLinks.map(link => (
                                    <li>
                                        <Typography variant="small">
                                            <Link className="no-underline uppercase font-display tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col md={2} lg={3} xl={2} className="mb-8">
                            <Typography variant="heading7" as="h2" paragraph>{companiesLabel}</Typography>
                            <ul className="space-y-2">
                                <li>
                                    <div className="flex items-center">
                                        <Typography variant="small">
                                            <Link to="/karriere/" className="no-underline uppercase font-display tracking-widest">
                                                {careersLabel}
                                            </Link>
                                        </Typography>
                                    </div>
                                </li>
                                {companiesLinksSet1.map(link => (
                                    <li>
                                        <Typography variant="small">
                                            <Link className="no-underline uppercase font-display tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                                {companiesLinksSet2.map(link => (
                                    <li className="xl:hidden">
                                        <Typography variant="small">
                                            <Link className="no-underline uppercase font-display tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col md={2} lg={3} className="mb-8 hidden xl:block">
                            <ul className="space-y-2 lg:mt-[2.4rem]">
                                {companiesLinksSet2.map(link => (
                                    <li>
                                        <Typography variant="small">
                                            <Link className="no-underline uppercase font-display tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col md={4} lg={5} xl={4} className="flex flex-col mb-8">
                            <Typography variant="small" className="lg:mt-8">
                                <CustomStructuredTextRenderer
                                    data={compliance}
                                />
                            </Typography>
                        </Col>
                    </Row>
                    <Row cols={{ sm: 1, md: 4, lg: 12 }} className="md:mt-6">
                        <Col md={2} lg={2} xl={3} className="mb-11 hidden md:flex justify-between items-end">
                            <Link to="/" className="no-underline">
                                <BrainbitsLogo variant="black" className="h-6" />
                            </Link>
                        </Col>
                        <Col md={2} lg={5} className="mb-10 hidden xl:flex">
                            <ul className="space-x-4 flex flex-row items-end">
                                {dynamicSmallLinks.map(link => (
                                    <li>
                                        <Typography variant="xsmall">
                                            <Link className="no-underline tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                                <li>
                                    <Typography variant="xsmall">
                                        <button
                                            type="button"
                                            className="tracking-widest"
                                            onClick={() => UC_UI.showSecondLayer()}
                                        >
                                            {consentLabel}
                                        </button>
                                    </Typography>
                                </li>
                            </ul>
                        </Col>
                        <Col md={2} lg={10} xl={4} className="md:mb-10 flex justify-between items-end">
                            <ul className="space-x-4 hidden lg:flex flex-row xl:hidden">
                                {dynamicSmallLinks.map(link => (
                                    <li>
                                        <Typography variant="xsmall">
                                            <Link className="no-underline tracking-widest" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                                <li>
                                    <Typography variant="xsmall">
                                        <button
                                            type="button"
                                            className="tracking-widest"
                                            onClick={() => UC_UI.showSecondLayer()}
                                        >
                                            {consentLabel}
                                        </button>
                                    </Typography>
                                </li>
                            </ul>
                            <div className="flex justify-between items-end w-full max-w-[300px]">
                                <Link to="/zertifizierte-qualitaet/" className="no-underline">
                                    <img
                                        src={Iso9001Logo}
                                        alt="ISO-9001"
                                        className="w-20"
                                    />
                                    <span className="sr-only">
                                        brainbits ist nach DIN ISO EN 9001:2015 zertifiziert.
                                    </span>
                                </Link>
                                <Link to="/zertifizierte-qualitaet/" className="no-underline mx-2">
                                    <img
                                        src={Iso27001Logo}
                                        alt="ISO-27001"
                                        className="w-20"
                                    />
                                    <span className="sr-only">
                                        brainbits ist nach DIN ISO EN 27001 zertifiziert.
                                    </span>
                                </Link>
                                <Link to="https://timetoact-group.com/" className="no-underline">
                                    <img
                                        src={TimeToActLogo}
                                        alt={imageAltString}
                                        className="h-[18px]"
                                    />
                                    <span className="sr-only">
                                        TIMETOACT GROUP Logo
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <div className="flex flex-col-reverse md:flex-row justify-between lg:hidden">
                            <ul className="flex flex-col space-y-3 my-6 md:space-y-0 md:flex-row md:space-x-4 md:my-8">
                                {dynamicSmallLinks.map(link => (
                                    <li>
                                        <Typography variant="xsmall">
                                            <Link className="no-underline" {...link} />
                                        </Typography>
                                    </li>
                                ))}
                                <li>
                                    <Typography variant="xsmall">
                                        <button
                                            type="button"
                                            className="tracking-wider"
                                            onClick={() => UC_UI.showSecondLayer()}
                                        >
                                            {consentLabel}
                                        </button>
                                    </Typography>
                                </li>
                            </ul>
                            <div className="mt-8 md:mt-14 h-6 flex items-center mr-6">
                                <h2 className="sr-only">brainbits auf Social-Media</h2>
                                <ul className="flex space-x-6">
                                    <li>
                                        <Link
                                            to="https://www.linkedin.com/company/brainbits-gmbh/"
                                            className="no-underline"
                                        >
                                            <LinkedInIcon size="15" />
                                            <span className="sr-only">brainbits LinkedIn Seite</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="https://www.xing.com/pages/brainbits"
                                            className="no-underline"
                                        >
                                            <XingIcon size="15" />
                                            <span className="sr-only">brainbits Xing Seite</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </Panel>
        </>
    );
};

export default Footer;
