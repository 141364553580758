import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    size: '24',
    color: '#3A3A3A',
};

const XingIcon = ({ color, size, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 14"
        height={size}
        width={size}
    >
        <path
            fill={color}
            fillRule="nonzero"
            d="M10.63 14.003c.271 0 .387-.219.265-.44L8.032 8.355v-.01l4.5-7.905c.12-.232-.015-.441-.265-.441h-2.053c-.222 0-.403.128-.547.381L5.142 8.356l2.887 5.266c.138.256.325.381.563.381h2.037zm-7.713-4.19c.215 0 .4-.132.553-.391 1.403-2.472 2.134-3.756 2.19-3.86L4.265 3.141c-.147-.253-.335-.382-.563-.382H1.667c-.256 0-.4.197-.266.441l1.372 2.362c.006.007.006.01 0 .01L.617 9.362c-.113.216.025.45.265.45h2.035z"
        />
    </svg>
);

XingIcon.propTypes = propTypes;
XingIcon.defaultProps = defaultProps;

export default React.memo(XingIcon);
