import React from 'react';
import PropTypes from '../util/PropTypes';

function numberToLabel(n) {
    return `${n}`.padStart(2, '0');
}

const propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
};

const defaultProps = {
    current: 0,
    total: 100,
};

const ProgressBar = ({ current, total }) => {
    const progressPercentage = (current < total) ? `${(current / total) * 100}%` : '100%';
    const currentLabel = numberToLabel(current);
    const totalLabel = numberToLabel(total);

    return (
        <div className="flex items-center w-full">
            <span className="font-bold text-base tracking-widest">
                {(current < total) ? currentLabel : totalLabel}
            </span>
            <span className="bg-gray-200 block flex-1 mx-2">
                <span
                    className="bg-lightBlue-500 block h-1 transition-[width] ease-linear duration-200"
                    style={{ width: progressPercentage }}
                />
            </span>
            <span className="font-bold text-base tracking-widest">
                {totalLabel}
            </span>
        </div>
    );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
