/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import classNames from 'classnames';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import BlockMargin from '../BlockMargin';
import DebugPill from '../debug/DebugPill.tsx';
import { useDebug } from '../debug/DebugProvider.tsx';
import TestimonialSlider from '../TestimonialSlider';
import TrustBox from '../TrustBox';
import BadgeSet from './BadgeSet';
import CaseStudyFeature from './CaseStudyFeature';
import CaseStudyShowcase from './CaseStudyShowcase';
import ColorCta from './ColorCta';
import ColumnSet from './ColumnSet';
import ComparisonTable from './ComparisonTable';
import ContactPersonCard from './ContactPersonCard';
import CtaWithMultipleImages from './CtaWithMultipleImages';
import { CustomListBlock } from './CustomList';
import DownloadBox from './DownloadBox';
import FeatureCardSet from './FeatureCardSet';
import FeatureImageCenter from './FeatureImageCenter';
import GroupedContent from './GroupedContent';
import ImageCollage from './ImageCollage';
import ImageRow from './ImageRow';
import InlineExpansionPanels from './InlineExpansionPanels';
import InlineForm from './InlineForm';
import InlineImage from './InlineImage';
import InlinePersonCard from './InlinePersonCard';
import InlineStorySlider from './InlineStorySlider';
import InlineTable from './InlineTable';
import IntroText from './IntroText';
import ProcedureTeaser from './ProcedureTeaser';
import ProductPartnerSet from './ProductPartnerSet';
import SingleCaseStudy from './SingleCaseStudy';
import StandaloneLink from './StandaloneLink';
import StepModule from './StepModule';
import TeaserItemSet from './TeaserItemSet';
import TrainingSet from './TrainingSet';

const propTypes = {
    data: PropTypes.shape({}),
    className: PropTypes.string,
    blockParent: PropTypes.string,
    isPage: PropTypes.bool,
};

const defaultProps = {
    data: null,
    className: '',
    blockParent: '',
    isPage: false,
};

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

const getBlock = (typename) => {
    switch (typename.replace('DatoCms', '').replace('Record', '')) {
        case 'ImageCollage':
            return ImageCollage;
        case 'ImageRow':
            return ImageRow;
        case 'ColorCta':
            return ColorCta;
        case 'TestimonialClient':
            return TestimonialSlider;
        case 'Form':
            return InlineForm;
        case 'GroupedContent':
            return GroupedContent;
        case 'TickBulletPoint':
            return CustomListBlock;
        case 'Button':
            return StandaloneLink;
        case 'ExpansionPanelGroup':
            return InlineExpansionPanels;
        case 'Table':
            return InlineTable;
        case 'Image':
            return InlineImage;
        case 'IntroText':
            return IntroText;
        case 'TrainingSet':
            return TrainingSet;
        case 'PersonCard':
            return InlinePersonCard;
        case 'DownloadBox':
            return DownloadBox;
        case 'MultipleImageCta':
            return CtaWithMultipleImages;
        case 'Trustbox':
            return TrustBox;
        case 'ColumnSet':
            return ColumnSet;
        case 'ContactPersonCard':
            return ContactPersonCard;
        case 'ProductPartnerSet':
            return ProductPartnerSet;
        case 'BadgeSet':
            return BadgeSet;
        case 'FeatureCardSet':
            return FeatureCardSet;
        case 'StorySlider':
            return InlineStorySlider;
        case 'FeatureImageCenter':
            return FeatureImageCenter;
        case 'ComparisonTable':
            return ComparisonTable;
        case 'CaseStudyShowcase':
            return CaseStudyShowcase;
        case 'TeaserItemSet':
            return TeaserItemSet;
        case 'StepModule':
            return StepModule;
        case 'SingleCaseStudy':
            return SingleCaseStudy;
        case 'CaseStudyFeature':
            return CaseStudyFeature;
        case 'ProcedureTeaser':
            return ProcedureTeaser;
        default:
            return null;
    }
};

export const RenderBlock = ({ __typename, ...props }) => {
    const BlockToRender = getBlock(__typename);
    if (!BlockToRender) {
        return (
            <div className="bg-red-500 text-white p-4">
                Missing Block:
                {__typename}
            </div>
        );
    }
    switch (__typename.replace('DatoCms', '').replace('Record', '')) {
        case 'TickBulletPoint':
            return <BlockToRender blockParent="TickBulletPoint" {...props} />;
        case 'Trustbox':
            return <BlockToRender data={{ trustbox: [props] }} />;
        default:
            return <BlockToRender {...props} />;
    }
};

const BlockRenderer = ({ data, className, blockParent, isPage }) => {
    const { showBlockNames } = useDebug();
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data.map((block) => {
                const { __typename: blockType } = block;

                const relevantBlock = getBlock(blockType);

                if (!relevantBlock) {
                    return (
                        <div className="bg-red-600 text-white p-8">
                            Block Missing:
                            {' '}
                            {blockType}
                        </div>
                    );
                }

                const potentialBlockClassNames = (() => {
                    if (relevantBlock.getBlockClassnames) {
                        return relevantBlock.getBlockClassnames(block);
                    }
                    return null;
                })();

                const shouldRenderHalfMargin = (() => {
                    if (relevantBlock.shouldRenderHalfMargin) {
                        return relevantBlock.shouldRenderHalfMargin(existingRenderContextDepth + 1, block);
                    }
                    return false;
                })();

                return (
                    <>
                        {showBlockNames && (
                            <DebugPill className="absolute">
                                {blockType}
                            </DebugPill>
                        )}
                        <BlockMargin
                            isPage={isPage}
                            existingDepth={existingRenderContextDepth + 1}
                            shouldRenderHalfMargin={shouldRenderHalfMargin}
                            className={classNames(potentialBlockClassNames)}
                        >
                            <RenderBlock
                                key={block.id}
                                className={className}
                                isPage={isPage}
                                blockParent={blockParent}
                                {...block}
                            />
                        </BlockMargin>
                    </>
                );
            })}
        </BlockRendererContext.Provider>
    );
};

BlockRenderer.propTypes = propTypes;
BlockRenderer.defaultProps = defaultProps;

export default BlockRenderer;
