import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
import StorySlider from '../StorySlider';
import Typography from '../Typography';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

// TODO: This should be merged with existing Story Slider component once Agentur page is not hard-coded.

const InlineStorySlider = ({ title, sliderItems, backgroundColor }) => (
    <Container>
        <Row cols={1} className="p-0">
            {title && (
                <Col>
                    <Typography
                        className="text-center"
                        variant="heading2"
                        paragraph
                    >
                        {title}
                    </Typography>
                </Col>
            )}
            <Col>
                <StorySlider backgroundColor={backgroundColor}>
                    {sliderItems && sliderItems.map(item => (
                        <StorySlider.Slide
                            image={item.image}
                            title={item.title}
                        >
                            <CustomStructuredTextRenderer data={item.text} />
                        </StorySlider.Slide>
                    ))}
                </StorySlider>
            </Col>
        </Row>
    </Container>
);

InlineStorySlider.propTypes = {
    title: PropTypes.string,
    sliderItems: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.datoImage,
        title: PropTypes.string,
        text: PropTypes.shape({}),
    })),
    backgroundColor: PropTypes.shape({
        hex: PropTypes.string,
    }),
};

InlineStorySlider.defaultProps = {
    title: null,
    sliderItems: [],
    backgroundColor: null,
};

export const query = graphql`
    fragment InlineStorySliderProps on DatoCmsStorySlider {
        originalId
        title
        backgroundColor {
            hex
        }
        sliderItems {
            image {
                gatsbyImageData
                alt
                url
                smartTags
            }
            title
            text {
                blocks
                links
                value
            }
        }
    }
`;

export default InlineStorySlider;
