import React from 'react';
import PropTypes from '../../util/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const TelephoneIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 24"
    >
        <path
            d="M16 6.9A13.9 13.9 0 0 1 2.1 20.8a1.4 1.4 0 0 1-1.4-1.1l-.7-3a1.4 1.4 0 0 1 .8-1.6L4 13.7a1.4 1.4 0 0 1 1.7.4l1 1.3a9.8 9.8 0 0 0 3.8-3.9l-1.2-1A1.4 1.4 0 0 1 9 8.8l1.4-3.2a1.4 1.4 0 0 1 1.6-.8l3 .7a1.4 1.4 0 0 1 1 1.4Zm-1.5 0-2.8-.7-1.3 3.1 2 1.8a11.3 11.3 0 0 1-6 6.1l-1.8-2-3 1.2.6 2.9A12.4 12.4 0 0 0 14.5 6.9Z"
            fill="currentColor"
        />
    </svg>
);

TelephoneIcon.propTypes = propTypes;
TelephoneIcon.defaultProps = defaultProps;

export default React.memo(TelephoneIcon);
