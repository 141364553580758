import React, {
    createContext, useContext, useEffect, useReducer,
} from 'react';
import classNames from 'classnames';
import MenuButton from '../MenuButton';

interface DebugContext {
    showBlockNames: boolean
    showTemplateNames: boolean
    showCurrentPublishState: boolean
    showGridLines: boolean
    showCenter: boolean
}

const DebugContext = createContext<DebugContext>({} as never);

interface DebugProvider {
    children: React.ReactNode
}

const DEBUG_LOCAL_KEY = 'bb-debug';

const DEBUG_INITIAL_STATE = {
    showBlockNames: false,
    showTemplateNames: false,
    showCurrentPublishState: false,
    showGridLines: false,
    showCenter: false,
};

type DebugShowReducer = (state: DebugContext, action: Partial<DebugContext>) => DebugContext;

const debugShowReducer: DebugShowReducer = (state, action) => {
    const update = Object.fromEntries(Object.entries({
        ...state,
        ...action,
        // Debug context might have old options we should remove
    }).filter(([key]) => (DEBUG_INITIAL_STATE as { [key: string]: boolean })[key] !== undefined));

    try {
        localStorage.setItem(DEBUG_LOCAL_KEY, JSON.stringify(update));
        // eslint-disable-next-line no-empty
    } catch (e) {
        // ignore non-working localstorage
    }
    // This could probably be typed better
    return update as unknown as DebugContext;
};

const DebugProvider = ({ children }: DebugProvider): JSX.Element => {
    const shouldRenderWidget = (process.env.NODE_ENV === 'development' || process.env.GATSBY_DATO_ENV === 'drafts');

    const [visible, setVisible] = React.useState(false);
    const [show, setShow] = useReducer<DebugShowReducer, Partial<DebugContext>>(
        debugShowReducer,
        {},
        () => DEBUG_INITIAL_STATE,
    );

    const update = (key: keyof DebugContext): void => {
        setShow({ [key]: !show[key] });
    };

    useEffect(() => {
        /*
        * Update from localStorage once window is defined
        * */
        let localDebug;
        try {
            if (shouldRenderWidget) {
                localDebug = localStorage.getItem(DEBUG_LOCAL_KEY);
                if (localDebug) {
                    const parsed = JSON.parse(localDebug);
                    if (parsed && typeof parsed === 'object' && Object.keys(parsed).length > 0) {
                        setShow(parsed);
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
            // ignore non-working localstorage
        }
    }, [shouldRenderWidget]);

    return (
        <DebugContext.Provider value={show}>
            {shouldRenderWidget && (
                <div className="fixed left-4 bottom-4 z-20 bg-white shadow-2xl p-4 text-xs rounded">
                    {visible ? (
                        <div className="space-y-1">
                            <MenuButton
                                className="scale-50 right-0 absolute top-0"
                                close
                                onClick={() => setVisible(false)}
                            />
                            {Object.entries(show).map(([key, value]) => (
                                <div className="space-x-1">
                                    <span>
                                        {key}
                                        :
                                    </span>
                                    <button
                                        type="button"
                                        className={classNames('rounded px-2 transition duration-200', {
                                            'bg-gray-100': !value,
                                            'bg-gray-400': value,
                                        })}
                                        onClick={() => {
                                            update(key as keyof DebugContext);
                                        }}
                                    >
                                        {value.toString()}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <button
                            type="button"
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            Debug
                        </button>
                    )}
                </div>
            )}
            {children}
        </DebugContext.Provider>
    );
};

export default DebugProvider;

export const useDebug = (): DebugContext => {
    const ctx = useContext(DebugContext);
    if (!ctx) {
        throw new Error('useDebug is missing parent DebugProvider.');
    }
    return ctx;
};
