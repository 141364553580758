import classnames from 'classnames';
import React from 'react';
import formatEmailLink from '../util/formatEmailLink';
import prepareEmailAddressWithBreaks from '../util/prepareEmailAddressWithBreaks';
import PropTypes from '../util/PropTypes';
import MailIcon from './icons/MailIcon';
import Link from './Link';

const EmailLink = ({ children, className, hideIcon }) => (
    <Link
        to={formatEmailLink(children)}
        className={classnames(className, 'no-underline')}
    >
        {!hideIcon && <MailIcon className="h-6 w-6 mr-1 inline-block" />}
        {prepareEmailAddressWithBreaks(children)}
    </Link>
);

EmailLink.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    hideIcon: PropTypes.bool,
};

EmailLink.defaultProps = {
    className: '',
    hideIcon: false,
};

export default EmailLink;
