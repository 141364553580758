/* eslint-disable import/no-cycle */
import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from '../../util/PropTypes';
import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
import CustomStructuredTextRenderer from './CustomStructuredTextRenderer';

const GroupedContent = ({ content, className, textAlign, blockParent }) => {
    if (blockParent) {
        return (
            <CustomStructuredTextRenderer
                isPage
                className={classNames(
                    className,
                    {
                        'text-left': textAlign === 'left',
                        'text-center': textAlign === 'center',
                        'text-right': textAlign === 'right',
                    },
                )}
                data={content}
                blockParent="GroupedContent"
            />
        );
    }
    return (
        <Container>
            <Row
                cols={{ sm: 1, md: 1, lg: 6 }}
            >
                <Col lg={{ span: 4, start: 2 }}>
                    <CustomStructuredTextRenderer
                        isPage
                        className={classNames(
                            className,
                            {
                                'text-left': textAlign === 'left',
                                'text-center': textAlign === 'center',
                                'text-right': textAlign === 'right',
                            },
                        )}
                        data={content}
                        blockParent="GroupedContent"
                    />
                </Col>
            </Row>
        </Container>
    );
};

GroupedContent.shouldRenderHalfMargin = () => true;

GroupedContent.propTypes = {
    content: PropTypes.shape({}),
    className: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    blockParent: PropTypes.string,
};

GroupedContent.defaultProps = {
    content: null,
    className: '',
    textAlign: 'left',
    blockParent: null,
};

export const query = graphql`
    fragment GroupedContentProps on DatoCmsGroupedContent {
        originalId
        textAlign
        content {
            blocks {
                __typename
                ... on DatoCmsColumnSet {
                    originalId
                    backgroundColor {
                        hex
                    }
                    columns {
                        width
                        start
                        align
                        content {
                            __typename
                            ...ImageCollageProps
                            ...InlineFormProps
                            ...TickBulletPointProps
                            ...ContactPersonCardProps
                            ...ButtonProps
                            ...IntroTextProps
                            ...ImageBlockProps
                            ...InlineTableProps
                            ...ExpansionPanelGroupProps
                            ...MultipleImageCtaProps
                            ...ImageBlockProps
                        }
                    }
                }
                ...ColorCtaProps
                ...ButtonProps
                ...IntroTextProps
            }
            links
            value
        }
    }
`;

export default GroupedContent;
