import PropTypes from 'prop-types';

const headingTag = PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

const datoImage = PropTypes.shape({
    responsiveImage: PropTypes.shape({
        alt: PropTypes.string,
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        sizes: PropTypes.string,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        webpSrcSet: PropTypes.string,
    }),
    focalPoint: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
    url: PropTypes.string,
    alt: PropTypes.string,
});

const gatsbyImageObject = PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
        layout: PropTypes.string.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        images: PropTypes.shape({
            fallback: PropTypes.shape({
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
            }),
            sources: PropTypes.arrayOf(PropTypes.shape({
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
            })),
        }),
    }),
});

const personCardData = PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    jobPosition: PropTypes.string,
    photo: datoImage,
    linkedinUrl: PropTypes.string,
    xingUrl: PropTypes.string,
});

const location = PropTypes.shape({
    state: PropTypes.shape({
        key: PropTypes.string,
    }),
});

const menuItem = PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
    id: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    childMenu: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        text: PropTypes.string,
        id: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    })),
});

const datoDownload = PropTypes.shape({
    url: PropTypes.string.isRequired,
});

export default {
    ...PropTypes,
    datoImage,
    headingTag,
    gatsbyImageObject,
    location,
    menuItem,
    personCardData,
    datoDownload,
};
