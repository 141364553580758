import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import TimeToActLogo from '../images/logos/time-to-act.svg';
import Container from './Container';
import { useLanguage } from './language/LanguageProvider.tsx';
import LanguageSwitcher from './language/LanguageSwitcher.tsx';
import Link from './Link';
import CustomStructuredTextRenderer from './structured-text/CustomStructuredTextRenderer';

const ArrowsLeft = () => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 39" width="86" height="39">
        <path d="m53 39 10.9-19.5L53 0h21.93l10.91 19.5L74.94 39H53Z" fill="#F0F0F0" />
        <path d="m0 39 10.9-19.5L0 0h21.93l10.91 19.5L21.94 39H0Z" fill="#DEDEDE" />
        <path d="m25 39 10.9-19.5L25 0h21.93l10.91 19.5L46.94 39H25Z" fill="#DEDEDE" />
        <path d="m50 39 10.9-19.5L50 0h21.93l10.91 19.5L71.94 39H50Z" fill="#DEDEDE" />
    </svg>
);

const ArrowsRight = () => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 39" width="88" height="39" className="bg-[#F0F0F0]">
        <path d="m5 39 10.9-19.5L5 0h21.93l10.91 19.5L26.94 39H5Z" fill="#DEDEDE" />
        <path d="m30 39 10.9-19.5L30 0h21.93l10.91 19.5L51.94 39H30Z" fill="#DEDEDE" />
        <path d="m55 39 10.9-19.5L55 0h21.93l10.91 19.5L76.94 39H55ZM0 39V0h1.93l10.91 19.5L1.94 39H0Z" fill="#DEDEDE" />
    </svg>
);

const TimeToActBar = () => {
    const { language } = useLanguage();

    const data = useStaticQuery(graphql`
        {
            de: datoCmsTimeToActBar(locale: "de") {
                dynamicLink {
                    ...LinkWithTitleProps
                }
                headline {
                    value
                }
            }
            en: datoCmsTimeToActBar(locale: "en") {
                dynamicLink {
                    ...LinkWithTitleProps
                }
                headline {
                    value
                }
            }
        }
    `);

    const timeToActBar = data[language];

    const imageAltString = {
        de: 'Logo der TIMETOACT GROUP, zu der brainbits gehört',
        en: 'Logo of the TIMETOACT GROUP, to which brainbits belongs',
    }[language];

    if (!timeToActBar) {
        return null;
    }

    return (
        <div className="bg-[#f0f0f0] text-[#5f5f5f] sm:h-[39px] relative text-center z-promotion flex flex-col sm:flex-row items-center justify-center">
            <Container outerClassName="absolute" className="hidden items-center justify-end py-2 lg:flex">
                <LanguageSwitcher />
            </Container>
            <div className="relative bg-[#dedede] h-full py-2 sm:py-0 px-5 w-full md:w-auto">
                <div className="absolute left-[-70px] hidden md:block top-0">
                    <ArrowsLeft />
                </div>
                <Link className="inline-flex h-full items-center flex-wrap justify-center no-underline px-4 relative" {...timeToActBar.dynamicLink}>
                    <CustomStructuredTextRenderer className="first:mb-0 !text-xs" data={timeToActBar.headline} />
                    {' '}
                    <img
                        src={TimeToActLogo}
                        alt={imageAltString}
                        className="h-[15px] ml-[5px]"
                    />
                </Link>
                <div className="absolute right-[-72px] hidden md:block top-0">
                    <ArrowsRight />
                </div>
            </div>
        </div>
    );
};

export default TimeToActBar;
