import classNames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

const Table = ({ children, className }) => (
    <div className="block w-full overflow-x-auto">
        <table className={classNames('bg-gray-50 text-gray-900 text-base border-collapse tracking-wider w-auto lg:w-full', className)}>
            {children}
        </table>
    </div>
);

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
