import { useScroll } from 'framer-motion';
import React from 'react';
import CalendlyModal from '../components/CalendlyModal';
import CenterLineDebug from '../components/debug/CenterLineDebug.tsx';
import DebugProvider from '../components/debug/DebugProvider.tsx';
import GridLinesDebug from '../components/debug/GridLinesDebug.tsx';
import PageDebug from '../components/debug/PageDebug.tsx';
import FadeTransition from '../components/FadeTransition';
import JsonLd from '../components/JsonLd';
import LanguageProvider from '../components/language/LanguageProvider.tsx';
import TimeToActBar from '../components/TimeToActBar';
import PropTypes from '../util/PropTypes';
import { LayoutProvider, LayoutConsumer } from './Context';
import Footer from './Footer';
import NavigationHeader from './NavigationHeader';
import NavigationSidebar from './NavigationSidebar';

const propTypes = {
    children: PropTypes.node,
    location: PropTypes.location.isRequired,
    pageResources: PropTypes.shape({}).isRequired,
    pageContext: PropTypes.shape({}).isRequired,
};

const defaultProps = {
    children: null,
};

const Layout = ({ children, location, pageResources, pageContext }) => {
    const { scrollYProgress } = useScroll();

    return (
        <DebugProvider>
            <PageDebug pageResources={pageResources} />
            <GridLinesDebug />
            <CenterLineDebug />
            <JsonLd>
                {{
                    '@context': 'https://schema.org',
                    '@type': 'Corporation',
                    name: 'brainbits GmbH',
                    alternateName: 'brainbits',
                    url: 'https://www.brainbits.net/',
                    logo: 'https://www.datocms-assets.com/48551/1624278103-bblogorgb.png',
                    contactPoint: {

                        '@type': 'ContactPoint',
                        telephone: '+4922198653099',
                        contactType: 'customer service',
                        areaServed: ['DE', 'AT', 'CH'],
                        availableLanguage: ['en', 'German'],
                    },
                    sameAs: 'https://www.linkedin.com/company/brainbits-gmbh/',
                }}
            </JsonLd>
            <JsonLd>
                {{
                    '@context': 'https://schema.org/',
                    '@type': 'WebSite',
                    name: 'brainbits',
                    url: 'https://www.brainbits.net/',
                }}
            </JsonLd>
            <LanguageProvider location={location} pageContext={pageContext}>
                <LayoutProvider scrollValue={scrollYProgress}>
                    <LayoutConsumer>
                        {({
                            isMediumScreen,
                        }) => (
                            <>
                                <TimeToActBar />
                                <header className="z-header sticky top-0 bg-white">
                                    <NavigationHeader />
                                    <div className="page-container py-0">
                                        <hr aria-hidden className="my-0 border-t-gray-100 border-t-[1px]" />
                                    </div>
                                </header>
                                {isMediumScreen && (
                                    <NavigationSidebar />
                                )}
                                <div className="page-container">
                                    <div className="bg-white overflow-hidden relative">
                                        <FadeTransition shouldChange={location.pathname}>
                                            <div>
                                                <main>
                                                    {children}
                                                </main>
                                                <footer>
                                                    <Footer />
                                                </footer>
                                            </div>
                                        </FadeTransition>
                                    </div>
                                </div>
                            </>
                        )}
                    </LayoutConsumer>
                    <CalendlyModal />
                </LayoutProvider>
            </LanguageProvider>
        </DebugProvider>
    );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
