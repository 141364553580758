import classNames from 'classnames';
import React from 'react';
import PropTypes from '../util/PropTypes';

const BlockMargin = ({
    children,
    existingDepth,
    shouldRenderHalfMargin,
    className,
    isPage,
}) => (
    <div
        className={classNames(
            '',
            {
                'my-8 lg:my-10': existingDepth > 0 && isPage && !shouldRenderHalfMargin,
                'my-16 md:my-28 xl:my-36': existingDepth <= 0 && isPage && !shouldRenderHalfMargin,

                'my-4 lg:my-6': existingDepth > 0 && isPage && shouldRenderHalfMargin,
                'my-4 lg:my-10 first:mt-20 first:lg:mt-36 last:mb-20 last:lg:mb-36': existingDepth <= 0 && isPage && shouldRenderHalfMargin,

                'my-8 lg:my-12': !isPage,
                'first:mt-0 last:mb-0': existingDepth > 0,
            },
            className,
        )}
    >
        {children}
    </div>
);

BlockMargin.propTypes = {
    children: PropTypes.node,
    existingDepth: PropTypes.number,
    className: PropTypes.string,
    isPage: PropTypes.bool,
    shouldRenderHalfMargin: PropTypes.bool,
};

BlockMargin.defaultProps = {
    children: null,
    existingDepth: 0,
    className: null,
    isPage: false,
    shouldRenderHalfMargin: false,
};

export default BlockMargin;
