import classnames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import IsoLogo from '../images/iso27001-logo.svg';
import PropTypes from '../util/PropTypes';
import Col from './Col';
import Container from './Container';
import DatoImage from './DatoImage';
import Row from './Row';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from './structured-text/CustomStructuredTextRenderer';
import Typography from './Typography';

const TrustBox = ({ data, className }) => {
    if (!data?.trustbox || data.trustbox.length === 0) {
        return null;
    }

    const { benefits, headline, text, content, image } = data.trustbox[0];
    // TODO: This is because of a GraphQL type conflict - need to refactor this to change to only 'content'
    const textContent = text || content;

    return (
        <Container outerClassName="bg-lightBlue-100" className={classnames('text-gray-900 mt-4 md:mt-6 lg:mt-20', className)}>
            <Row className="flex flex-col lg:flex-row items-center">
                <Col sm={12} lg={6} className="my-10 lg:mt-20">
                    <Typography variant="heading2" paragraph>
                        {headline}
                    </Typography>
                    <CustomStructuredTextRenderer data={textContent} />
                    <div className="flex lg:mt-14 lg:mb-16 my-4">
                        <img
                            src={IsoLogo}
                            alt="ISO-27001"
                            className="h-20 w-20"
                        />
                    </div>
                </Col>
                <Col sm={12} lg={{ start: 7, span: 6 }} xl={{ start: 8, span: 5 }} className="mb-8 lg:mb-0 bg-white h-full lg:px-6 xl:px-12">
                    <div className="lg:-mt-16 p-8 lg:pb-20 items-center justify-center">
                        <DatoImage
                            image={image}
                            className="max-w-[346px] m-auto"
                        />
                        <CustomStructuredTextRenderer
                            data={benefits}
                            listStyle="check"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

TrustBox.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        trustbox: PropTypes.arrayOf(PropTypes.shape({
            image: PropTypes.datoImage,
            headline: PropTypes.string,
            text: PropTypes.shape({}),
            content: PropTypes.shape({}),
            benefits: PropTypes.shape({}),
        })),
    }),
};

TrustBox.defaultProps = {
    className: '',
    data: { trustbox: [] },
};

export const query = graphql`
    fragment TrustboxProps on DatoCmsTrustbox {
        originalId
        headline
        benefits {
            blocks
            links
            value
        }
        content: text {
            blocks {
                __typename
                ...ButtonProps
                ...IntroTextProps
            }
            links
            value
        }
        image {
            gatsbyImageData
            alt
            url
        }
    }
`;

export default TrustBox;
